import configData from './config.json';

export const substituteText = "Note: Looks like you have substituted the following symbols for the textbook symbols :"
export const demoUserId = 393
export const demoUserMail = "demo@aiplato.ai"
export const fullPTTimeLapseCounterInMiliSeconds = 9000000 /// (150 Min = 150*60*1000)
export const halfPTTimeLapseCounterInMiliSeconds = 9000000 ///5400000 /// (90 Mins = 90*60*1000) /// 1 min = 60000 
export const fullPTTimeInString = "2:30:00"
export const halfPTTimeInString = "2:30:00" ///"1:30:00"
export const timeLapseCounter = 1000 /// 1 Second
export const halfTestMarksLimit = 45
export const interactiveContentExceedLimit = 10 // Final Value =10  //this is for demo (try it out) user
export const interactiveProblemExceedLimit = 10// Final Value =10  //this is for demo (try it out) user

export const subscribeWithoutTeacherAccessIP = 30 ///final value = 30  //this is for "Subscribe with Email" user (thirty days trial) // PRoblems
export const subscribeWithoutTeacherAccessIC = 30 ///final value = 30  //this is for "Subscribe with Email" user (thirty days trial) // Contents
export const subscribeWithoutTeacherAccessWop = 30 ///final value = 30  //this is for "Subscribe with Email" user (thirty days trial) // wop

export const MenuItems = {
  Pen: 'pen',
  Wiris: 'wiris',
  Undo: 'undo',
  Redo: 'redo',
  Delete: 'delete',
  New: 'New',
  Hint: 'hint',
  AskTutor: 'askTutor',
  AskAITutor: 'askAITutor',
  Upload: 'upload',
  CheckWork: 'checkWork',
  Submit: 'submit',
  NextStep: 'nextStep',
  ShowSolution: 'showSolution',
  KnowledgeGraph: 'knowledgeGraph',
  CheckStudentWork: 'checkStudentWork',
  SaveToTranscript: 'SaveToTranscript',
  Synopsis: 'Synopsis',
  EnterAnswer: 'EnterAnswer',
  MoreButtons: 'moreButtons',
  HintMainButtons: 'hintMainButtons',
  FullScreen: 'fullscreen',
  ShowEquation: "showequation",
  Hand: 'hand',
  CalculateGrades: 'CalculateGrades',
  Erase: 'Erase',
  SaveMyWork: 'SaveMyWork',
  clearHandwriting: "clearHandwriting"
}

export const Institute = {
  AiPlato: '1',
  Rice: '2',
  NYU: '7',
  FrancisMarionUniversity: "10"
}

export const AnyProblem_Challenge_Type = {
  AnyProblem: 'wop'
}

export const LimitedAccesscontentExceedMessage = () => {
  return "You've reached the limit for interactive video available in your trial." +
    'However, you can still visit ' +
    '<a alt="Go to Interactive Problems" href="/curriculum-problems"> Interactive Problems </a> ' +
    'to explore them. ' +
    `<a href="mailto:info@aiplato.ai" target='blank' className='btnMain'>Contact Us</a> to upgrade and unlock full access.` +
    '<br/>Thank you!'
}

export const LimitedAccessproblemsExceedMessage = () => {
  return "You've reached the limit for interactive problem-solving available in your trial. " +
    ' However, you can still visit ' +
    '<a alt="Go to Interactive Content" href="/curriculum-content#Video"> Interactive Content </a>' +
    'to explore them. ' +
    `<a href="mailto:info@aiplato.ai" target='blank' className='btnMain'>Contact Us</a> to upgrade and unlock full access.` +
    '<br/>Thank you!'
}

export const LimitedAccessbothExceedMessage = () => {
  return "You've reached the limit for interactive problem-solving and interactive videos available in your trial. " +
    `<a href="mailto:info@aiplato.ai" target='blank' className='btnMain'>Contact Us</a> to upgrade and unlock full access.` +
    '<br/>Thank You!'
}


export const contentExceedMessage = (useruniquecode) => {
  return "You've reached the limit of interactive lecture videos during your trial experience." +
    'However, you can still visit ' +
    '<a alt="Go to Interactive Problems" href="/curriculum-problems"> Interactive Problems </a> ' +
    'to explore them. Alternatively, you have the option to ' +
    `<a alt="Sign Up for AiPlato" href="${configData.WEBSITE_URL}signUpPlans/${useruniquecode}"> sign up </a> to unlock the full features of the application.` +

    'Thank you!'
}

export const problemsExceedMessage = (useruniquecode) => {
  return " You've surpassed the limit for interactive problem-solving, step-by-step in your trial experience. " +
    'You still have the option to visit ' +
    '<a alt="Go to AI-Interactive Content" href="/curriculum-content#Video"> AI-Interactive Content </a> ' +
    'and explore it. Alternatively, ' +
    `you can <a alt='Sign Up for AiPlato' href='${configData.WEBSITE_URL}signUpPlans/${useruniquecode}'> sign up </a> to access the application's full features. ` +

    'Thank you!'
}

export const bothExceedMessage = (useruniquecode) => {
  return 'You have exceeded the limit for exploring interactive lecture videos and interacting with problem-solving in the trial session.' +
    ' To fully engage with interesting content and challenges, ' +
    `please <a alt="Sign Up for AiPlato" href="${configData.WEBSITE_URL}signUpPlans/${useruniquecode}">sign up </a> now.` +

    'Thank You!'
}

export const testInstructionText = `
   <div className="divInstruction">
                                    <div className="instructionTitle">Essential Quiz Instructions - Please Read Carefully!</div>
                                    <div className="divLis">
                                        <div className="divLi instructiondiv">
                                            <span className='spanLiTitle'>Before You Begin:<br /></span>
                                            <span className='spanLi '>
                                                <ul>
                                                    <li> <b>Timing</b> : You will have 150 minutes to complete this quiz. The timer starts as soon as you access or start the assignment.</li>
                                                    <li> <b>Auto-Submission</b> : The quiz will be automatically submitted after 150 minutes if you haven't submitted it yourself.</li>
                                                </ul>
                                            </span>
                                        </div>
                                        <div className="divLi instructiondiv">
                                            <span className='spanLiTitle'>During the Exam:<br /></span>
                                            <span className='spanLi'>
                                                <ul>
                                                    <li> <b>Individual Submissions</b> : You have a maximum of 5 independent submissions for each part of each problem, including multiple-choice questions. Individual problem submissions are made using the "Check Score" button.</li>
                                                    <li> <b>Point Deductions</b> : Each incorrect submission will result in a 20% deduction of the points for that part. For example, if you get question 4 part b correct on the third attempt, you will receive 60% of the points for that part.</li>
                                                    <li> <b>Answer Reveals</b> : The answer for a specific part will be revealed after 5 incorrect submissions for that problem or part of a problem</li>
                                                    <li> <b>Check Score</b> : Use the "Check Score" button to submit your answers and see your points for each part as you go along.</li>
                                                </ul>
                                            </span>
                                        </div>
                                        <div className="divLi instructiondiv">
                                            <span className='spanLiTitle'>After you Finish:<br /></span>
                                            <span className='spanLi'>
                                                <ul>
                                                    <li> <b>Submission</b> : Once you have completed the quiz, please submit it using the "Submit Quiz" button.</li>
                                                    <li> <b>Assessment</b> : Your assessment will be available immediately after you submit the quiz.</li>
                                                </ul>
                                            </span>
                                            <span className='spanLiTitle'>Good luck, and remember this is a practice tool—use it to prepare and improve!</span>
                                        </div>
                                    </div>
                                </div>`

export const PrProgressMaxValue = "10"
export const PrProgressPerMaxvalue = "100"