import React, { useEffect, useState } from "react";

import { withNamespaces } from 'react-i18next';
import "../../../components/Answerboard/Answerboard.scss"
import { studentHomeworkList_Rice, homeworkList_Rice, imgURL, resetstudentresubmission, problemparticipationReport } from '../../../common/API'
import { getCurrentUserId, formatDate, formatDateUs, getInstituteId, Problem_source, formatNumber, getMenuSelectedItem } from '../../../common/Functions'
import { Institute } from '../../../common/Constants'
import { isNil } from 'lodash';
import {
    Avatar,
    makeStyles
} from '@material-ui/core';
import Button from '@mui/material/Button'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { Link, withRouter } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { Row, Col } from 'react-bootstrap';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import TestReport from "../../../components/Reports/TestReport";
import DownloadIcon from '../../../assets/images/download-icon.svg';
import { CSVLink, CSVDownload } from "react-csv"

function descendingComparator(a, b, orderBy) {
    if (orderBy === 'submitteddate') {
        return (new Date(b[orderBy]).valueOf() - new Date(a[orderBy]).valueOf());
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}



function EnhancedTableHead(props) {


    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell
                    key="title"
                    align={'left'}
                    padding={'normal'}
                    sortDirection={orderBy === "title" ? order : false}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableSortLabel
                        active={orderBy === "title"}
                        direction={orderBy === "title" ? order : 'asc'}
                        onClick={createSortHandler("title")}
                    >
                        Assignments
                        {orderBy === "title" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Attempted Users</TableCell>
                <TableCell>Auto Graded</TableCell>
                {/* <TableCell>Grade Reviewed</TableCell> */}
                <TableCell>ReGrades</TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    // onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    // rowCount: PropTypes.number.isRequired,
};






function EnhancedTableHeadStudentList(props) {


    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, problemsource } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell
                    key="name"
                    align={'left'}
                    padding={'normal'}
                    sortDirection={orderBy === "name" ? order : false}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableSortLabel
                        active={orderBy === "name"}
                        direction={orderBy === "name" ? order : 'asc'}
                        onClick={createSortHandler("name")}
                    >
                        Name
                        {orderBy === "name" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    key="email"
                    align={'left'}
                    padding={'normal'}
                    sortDirection={orderBy === "email" ? order : false}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableSortLabel
                        active={orderBy === "email"}
                        direction={orderBy === "email" ? order : 'asc'}
                        onClick={createSortHandler("email")}
                    >
                        Email
                        {orderBy === "email" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    key="totalhwscore"
                    align={'left'}
                    padding={'normal'}
                    sortDirection={orderBy === "totalhwscore" ? order : false}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableSortLabel
                        active={orderBy === "totalhwscore"}
                        direction={orderBy === "totalhwscore" ? order : 'asc'}
                        onClick={createSortHandler("totalhwscore")}
                    >
                        Grade
                        {orderBy === "totalhwscore" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    key="attemptedcountforsorting"
                    align={'left'}
                    padding={'normal'}
                    sortDirection={orderBy === "attemptedcountforsorting" ? order : false}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableSortLabel
                        active={orderBy === "attemptedcountforsorting"}
                        direction={orderBy === "attemptedcountforsorting" ? order : 'asc'}
                        onClick={createSortHandler("attemptedcountforsorting")}
                    >
                        Attempted Questions
                        {orderBy === "attemptedcountforsorting" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>


                <TableCell
                    key="submitteddate"
                    align={'left'}
                    padding={'normal'}
                    sortDirection={orderBy === "submitteddate" ? order : false}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableSortLabel
                        active={orderBy === "submitteddate"}
                        direction={orderBy === "submitteddate" ? order : 'asc'}
                        onClick={createSortHandler("submitteddate")}
                    >
                        Submission Date
                        {orderBy === "submitteddate" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell>Reset Submission</TableCell>
                <TableCell>Assessment Summary</TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHeadStudentList.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    // onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    // rowCount: PropTypes.number.isRequired,
};


const useStyles = makeStyles((theme) => ({
    root: {},
    avatar: {
        height: 35,
        width: 35,
        // marginRight: theme.spacing(1),
        margin: "7px"
    },
    avatarlarge: {
        height: 100,
        width: 100,
    },
    linkbutton: {
        fontSize: "10px"
    },
    tableRow: {
        "&:hover": {
            backgroundColor: "#1976d214 !important"
        }
    },
    tableRowhw: {
        "&:hover": {
            backgroundColor: "#e0e0e0 !important"
        }
    },
    rootAbsolute: {
        "& .MuiButton-startIcon": {
            marginRight: "1px"
        }
    }

}));





const RiceHomeworkDetail = (props) => {
    const classes = useStyles();
    const [loading, setloading] = useState(false)
    const [homeworkList, setHomeworkList] = useState([])
    const [studentHomeworkList, setstudentHomeworkList] = useState([])
    const [problemList, setProblemList] = useState([])
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('title');
    const [selected, setSelected] = React.useState([]);
    const [orderStudent, setorderStudent] = React.useState('desc');
    const [orderByStudent, setorderByStudent] = React.useState('attemptedcountforsorting');
    const [selecteds, setSelecteds] = React.useState([]);
    const [showHomworkList, setShowHomworkList] = useState(true)
    const [displayMessage, setDisplayMessage] = useState("")
    const [displayStudentMessage, setdisplayStudentMessage] = useState("")
    const [showResetSubmissionMessage, setShowResetSubmissionMessage] = useState(false)

    const [studentId, setStudentId] = useState("")
    const [assignment_id, setassignment_id] = useState("")
    const [previousassignment_id, setpreviousassignment_id] = useState("")
    const [problemSource, setProblemSource] = useState(Problem_source.apptesthomework)
    const [showStudentSummarySection, setshowStudentSummarySection] = useState(true)
    const [ptDueDate, setPtDueDate] = useState("")
    const [quizTitle, setQuizTitle] = useState("")

    const [studentSummaryButton, setstudentSummaryButton] = useState("contained")
    const [problemSummaryButton, setproblemSummaryButton] = useState("outlined")

    const [csvExportDataStudent1, setcsvExportDataStudent1] = useState([])
    const [csvProblemSummaryExportData, setcsvProblemSummaryExportData] = useState([])
    const [isGraded, setIsGraded] = useState(false)
    const [problemparticipationReportList, setproblemparticipationReportList] = useState([])

    const [displayStudentMessagepr, setdisplayStudentMessagepr] = useState("")

    const [csvExportDataparticipationsummary, setcsvExportDataparticipationsummary] = useState([])
    const [hideCSVLink, sethideCSVLink] = useState(false)

    useEffect(() => {
        setDisplayMessage("Fetching Data...")
        // setloading(true)
        setShowHomworkList(true)
        const reqData = {
            params: {
                user_id: getCurrentUserId(),
                course_id: getMenuSelectedItem()
            }
        }

        homeworkList_Rice(reqData).then(res => {
            if (!isNil(res.data) && res.data.length > 0) {
                setHomeworkList(res.data)
                // setloading(false)
            }
            else {
                setDisplayMessage("No Assignment Found!")
                // setloading(false)
            }
        }).catch(err => {
            console.error(err.message);
            // setloading(false)
        })
    }, [])

    useEffect(() => {

        if (props.isStudentView) {
            if (props.assignment_id !== undefined) {
                setassignment_id(props.assignment_id)
                setProblemSource(props.problemSource)
                studentListdata(props.assignment_id, props.problemSource)
                setQuizTitle(props.assignmentName)
            }
        }
        else {
            setShowHomworkList(true)
        }

    }, [props.isStudentView])

    const handleRequestSort = (event, property) => {

        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleRequestSortStudent = (event, property) => {

        const isAsc = orderByStudent === property && orderStudent === 'asc';
        setorderStudent(isAsc ? 'desc' : 'asc');
        setorderByStudent(property);
    };

    const problemparticipationReportData = (assignment_id) => {
        setdisplayStudentMessagepr("Fetching Data...")
        const reqData = {
            params: {
                assignment_id: parseInt(assignment_id),
                institute_id: getInstituteId(),
                course_id: getMenuSelectedItem()
            }
        }

        problemparticipationReport(reqData).then(res => {
            //debugger;
            if (res.data.data !== undefined && res.data.data !== null && res.data.data.length > 0) {
                setproblemparticipationReportList(res.data.data)
                let csvParticipationReportProblem = [["Name", "#Total steps", "#Correct steps", "#In-correct steps", "#Evaluation used", "#Show full solution used", "#Show Next step used", "#Hints used", "#AskAITutor used", "%Progress"]]
                res.data.data.map((student) => {
                    csvParticipationReportProblem.push([student.user_name, student.total_count, student.correct_eq_count, student.incorrect_eq_count, student.checkequation_count, student.showsolution_count, student.shownextstep_count, student.hints_count, student.askaitutor_count, student.percprogress + "%"])
                })
                setcsvExportDataparticipationsummary(csvParticipationReportProblem)
                setdisplayStudentMessagepr("")
            }
            else {
                setdisplayStudentMessagepr("No Data Found.")
            }

        }).catch(err => {
            console.error(err.message);
            setdisplayStudentMessagepr("No Data Found.")
        })

    }
    const studentListdata = (assignment_id, problem_source) => {
        setShowHomworkList(false)
        setloading(true)
        setassignment_id(assignment_id)
        setdisplayStudentMessage("Fetching Student Data...")

        let csvExportDataStudent = [["Student Name", "Email", "Grade", "Attempted Questions %"]]
        let csvExportDataProblem = [["Assignments", "Percent Attempted%", "Average Score", "Min Score", "Max Score", "Students with Max Score"]]
        const reqData = {
            params: {
                assignment_id: parseInt(assignment_id),
                user_id: getCurrentUserId(),
                problemsource: problem_source,
                course_id: getMenuSelectedItem()
            }
        }

        studentHomeworkList_Rice(reqData).then(res => {
            if ((!isNil(res.data.sorted_students) && res.data.sorted_students.length > 0) || (!isNil(res.data.problem_summary) && res.data.problem_summary.length > 0)) {
                if (!isNil(res.data.sorted_students)) {
                    setstudentHomeworkList(res.data.sorted_students)
                    res.data.sorted_students.map((student) => {
                        let grade = student.totalhwscore
                        if (grade !== undefined && grade !== null) {
                            grade = formatNumber(grade)
                        }
                        let totalattemptedperc = student.totalattemptedperc

                        if (totalattemptedperc !== undefined && totalattemptedperc !== null) {
                            totalattemptedperc = formatNumber(totalattemptedperc)
                        }
                        csvExportDataStudent.push([student.name, student.email, grade, totalattemptedperc + "%"])
                    })
                    setcsvExportDataStudent1(csvExportDataStudent)

                }

                if (!isNil(res.data.problem_summary) && res.data.problem_summary.length > 0) {
                    setProblemList(res.data.problem_summary)


                    res.data.problem_summary.map((student) => {
                        let percentage_attempted = student.percentage_attempted
                        if (percentage_attempted !== undefined && percentage_attempted !== null) {
                            percentage_attempted = formatNumber(percentage_attempted)
                        }

                        let avg_score = student.avg_score
                        if (avg_score !== undefined && avg_score !== null) {
                            avg_score = formatNumber(avg_score)
                        }
                        let min_score = student.min_score
                        if (min_score !== undefined && min_score !== null) {
                            min_score = formatNumber(min_score)
                        }
                        let max_score = student.max_score
                        if (max_score !== undefined && max_score !== null) {
                            max_score = formatNumber(max_score)
                        }
                        let students_max_score = student.students_max_score
                        if (students_max_score !== undefined && students_max_score !== null) {
                            students_max_score = formatNumber(students_max_score)
                        }

                        csvExportDataProblem.push([student.challenge_name, percentage_attempted + "%", avg_score, min_score, max_score, students_max_score])
                    })
                    setcsvProblemSummaryExportData(csvExportDataProblem)
                }
                setloading(false)
                setpreviousassignment_id(assignment_id)

            }
            else {
                setdisplayStudentMessage("No Students Found!!")
                setloading(false)
            }
        }).catch(err => {
            console.error(err.message);
            setloading(false)
        })


    }
    const openstudenthomeworkdetail = (e, assignment_id, enddate, userId, problemsource, quiztitle, isgraded) => {
        setassignment_id(assignment_id)
        setPtDueDate(enddate)
        setStudentId(userId)
        setProblemSource(problemsource)
        setQuizTitle(quiztitle)
        setIsGraded(isgraded)
        if (parseInt(previousassignment_id) !== parseInt(assignment_id)) {
            setstudentHomeworkList([])
            setdisplayStudentMessage("Fetching Student Data...")
            studentListdata(assignment_id, problemsource)
            // problemparticipationReportData(assignment_id)
        }
        else {
            setShowHomworkList(false)
        }
    }
    const handleback = () => {
        setShowHomworkList(true)
    }


    const visibleRowsstudentHomeworkList = React.useMemo(
        () =>
            stableSort(studentHomeworkList, getComparator(orderStudent, orderByStudent)),
        [studentHomeworkList, orderStudent, orderByStudent],
    );

    const visibleRowshomeworkList = React.useMemo(
        () =>
            stableSort(homeworkList, getComparator(order, orderBy)),
        [homeworkList, order, orderBy],
    );

    const resetstudentsubmission = (e, userid) => {
        setShowResetSubmissionMessage(true)
        setStudentId(userid)
    }

    const closestudentsubmission = () => {
        setShowResetSubmissionMessage(false)
    }
    const resetsubmissionhandler = () => {

        const data = {
            "user_id": studentId,
            "assignment_id": assignment_id
        }

        resetstudentresubmission(data).then(res => {
            if (res.data !== undefined && res.data !== null) {
                toast("Reset Submission Successfully !", {
                    type: toast.TYPE.SUCCESS,
                    position: toast.POSITION.BOTTOM_RIGHT
                })
                setShowResetSubmissionMessage(false)
            }

        }).catch(err => {
            console.log("API Error: ", err);
        })

    }
    const showStudentSummary = () => {

        setshowStudentSummarySection(true)
        setstudentSummaryButton("contained")
        setproblemSummaryButton("outlined")
    }
    const showproblemsummary = () => {
        setshowStudentSummarySection(false)
        setstudentSummaryButton("outlined")
        setproblemSummaryButton("contained")
    }

    const HideCSVLinkHandler = (show) => {
        sethideCSVLink(show)
    }

    return (
        <>
            <Dialog
                hideBackdrop={false}
                open={showResetSubmissionMessage}
                onClose={closestudentsubmission}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div style={{ marginTop: "10px", color: "#164b99" }}>
                            Are you sure, you want to reset the submission for this selected student?
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button className="dragLabelcancel leftmargin" onClick={resetsubmissionhandler}>
                        Yes
                    </button>

                    <button className="dragLabelcancel leftmargin" onClick={closestudentsubmission}>
                        No
                    </button>
                </DialogActions>
            </Dialog>
            <div className='py-3'>
                {
                    !showHomworkList ? (
                        <>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <div>
                                    <Button href="#" onClick={handleback}
                                        className={classes.rootAbsolute}
                                        startIcon={<ArrowBackIcon sx={{ marginRight: "1px !important" }} />}
                                        sx={{ minWidth: "0 !important", padding: "0 !important", textDecoration: "underline", textTransform: "capitalize" }}>Back to Assignments</Button>
                                </div>
                                <div>
                                    <strong>Assignment: {quizTitle}</strong>
                                </div>
                                <div>
                                    <Button variant={studentSummaryButton}
                                        onClick={showStudentSummary}
                                        className={classes.rootAbsolute}
                                        sx={{ minWidth: "0 !important", padding: "7px !important", textDecoration: `${studentSummaryButton === "text" ? "underline" : "none"}`, textTransform: "capitalize" }}>Assessment & Feedback</Button>
                                    <Button variant={problemSummaryButton}
                                        onClick={showproblemsummary}
                                        className={classes.rootAbsolute}
                                        sx={{ minWidth: "0 !important", padding: "7px !important", textDecoration: `${problemSummaryButton === "text" ? "underline" : "none"}`, textTransform: "capitalize", marginLeft: "10px", marginRight: "30px" }}>Problem Participation</Button>
                                </div>

                            </div>

                            {hideCSVLink ? null :
                                showStudentSummarySection ? (<div className="d-flex justify-content-end align-items-center mb-3" title="Export Student Summary Data">

                                    <div style={{ marginRight: "1%" }}>
                                        <CSVLink data={csvExportDataStudent1} className='exportCSVbtn exportRepresentative'
                                            filename={quizTitle + "_student_summary_ " + formatDateUs(Date.now()) + ".csv"}> Export To CSV
                                            <img src={DownloadIcon}></img></CSVLink>
                                    </div>

                                </div>) : (<div style={{ left: '12%', width: '15%', float: 'let', position: 'absolute' }} className="d-flex justify-content-end align-items-center mb-3" title="Export Problem Level Summary Data">

                                    <div style={{ marginTop: '5px', position: 'relative', marginRight: "1%" }} >
                                        <CSVLink data={csvProblemSummaryExportData} className='exportCSVbtn exportRepresentative'
                                            filename={quizTitle + "_problemlevel_summary_ " + formatDateUs(Date.now()) + ".csv"}> Export To CSV
                                            <img src={DownloadIcon}></img></CSVLink>
                                    </div>

                                </div>)
                            }



                        </>


                    ) : null
                }


                {
                    showHomworkList ?
                        (getInstituteId() !== Institute.Rice ? (<p className="text-secondary">Track the assignment details of all students in your class</p>)
                            : (<p className="text-secondary">Track the assignment Summary of all students in your class</p>)


                        ) : null}

                <Box sx={{ width: '100%' }}>
                    <Paper sx={{ width: '100%', mb: 2, overflow: 'hidden' }}>

                        {
                            showHomworkList ? (
                                <TableContainer sx={{ maxHeight: 500 }}>
                                    <Table
                                        sx={{ minWidth: 750 }}
                                        stickyHeader
                                        aria-label="sticky table a dense table"
                                        size={'small'}
                                    >
                                        <EnhancedTableHead
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}
                                            problemsource={problemSource}

                                        />
                                        <TableBody>
                                            {
                                                visibleRowshomeworkList !== undefined && visibleRowshomeworkList !== null && visibleRowshomeworkList.length > 0 ?

                                                    (visibleRowshomeworkList.map((row, index) => {

                                                        let stardate = formatDate(row.start_date)
                                                        let enddate = formatDate(row.end_date)

                                                        let reviewdper = row.totalreviewedpercentage

                                                        return (
                                                            <TableRow className={classes.tableRowhw}
                                                                tabIndex={-1}
                                                                key={row.id}
                                                                sx={{ cursor: 'pointer' }}>
                                                                <TableCell align="left">

                                                                    <Button variant="text" onClick={(e) => openstudenthomeworkdetail(e, row.assignment_id, row.end_date, row.user_id, row.problemsource, row.title, row.isgraded)}
                                                                        style={{ textTransform: "none" }}>{row.title}</Button>
                                                                </TableCell>
                                                                <TableCell align="left" style={{ fontWeight: "bolder" }}>
                                                                    <Row className='no-gutters'>
                                                                        {/* <Col sm="3">
                                                                    </Col> */}
                                                                        <Col><span style={{ visibility: "hidden" }}>Last Due Date:</span> {stardate}</Col>
                                                                    </Row>
                                                                    <Row className='no-gutters'>
                                                                        {/* <Col sm="3">
                                                                        Last Due Date:
                                                                    </Col > */}
                                                                        <Col><span>Last Due Date:</span> {enddate}</Col>
                                                                    </Row>
                                                                </TableCell>
                                                                <TableCell align="left">{row.totaluserattempted}</TableCell>
                                                                <TableCell align="left">{row.isgraded}</TableCell>
                                                                {/* <TableCell align="left">

                                                                <span style={{ paddingTop: "5px" }}> <progress style={{ paddingTop: "3px" }} id={index} value={reviewdper} max="100"> {reviewdper}% </progress></span>
                                                                <span style={{ marginLeft: "5px" }}>{reviewdper}% </span>

                                                            </TableCell> */}
                                                                <TableCell align="left">OFF</TableCell>
                                                            </TableRow>
                                                        );
                                                    })) :
                                                    (
                                                        // <div style={{ padding: "20px" }}>{displayMessage}</div>
                                                        <TableRow>
                                                            <TableCell colSpan={5} style={{ padding: "20px", textAlign: "center", fontSize: "17px" }}>{displayMessage}</TableCell>
                                                        </TableRow>
                                                    )
                                            }

                                        </TableBody>
                                    </Table>
                                </TableContainer>) :
                                (
                                    <>
                                        {
                                            showStudentSummarySection ? (<TableContainer sx={{ maxHeight: 500 }}>
                                                <Table
                                                    sx={{ minWidth: 750 }}
                                                    stickyHeader
                                                    aria-label="sticky table a dense table"
                                                    size={'small'}
                                                >
                                                    <EnhancedTableHeadStudentList
                                                        numSelected={selecteds.length}
                                                        order={orderStudent}
                                                        orderBy={orderByStudent}
                                                        onRequestSort={handleRequestSortStudent}
                                                        problemsource={problemSource}

                                                    />
                                                    <TableBody>
                                                        {
                                                            visibleRowsstudentHomeworkList !== undefined && visibleRowsstudentHomeworkList !== null && visibleRowsstudentHomeworkList.length > 0 ?

                                                                (visibleRowsstudentHomeworkList.map((row, index) => {
                                                                    let submitteddate = formatDate(row.submitteddate)

                                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                                    return (
                                                                        <TableRow hover
                                                                            className={classes.tableRow}
                                                                            tabIndex={-1}
                                                                            key={row.id}
                                                                            sx={{ cursor: 'pointer' }}>
                                                                            <TableCell
                                                                                component="th"
                                                                                id={labelId}
                                                                                scope="row"
                                                                                padding="none"
                                                                            >
                                                                                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                                                                    <Avatar
                                                                                        className={classes.avatar}
                                                                                        src={imgURL + row.profile_pic}>
                                                                                    </Avatar>
                                                                                    <div className='mt-2'>{row.name}</div>
                                                                                </div>
                                                                            </TableCell>
                                                                            <TableCell align="left">{row.email}</TableCell>
                                                                            <TableCell align="left">

                                                                                {
                                                                                    row.graded === "True" || row.graded === true || row.graded === "true" ? (<>
                                                                                        {row.totalhwscore !== null && row.totalhwscore !== undefined ? formatNumber(row.totalhwscore) : 0.00}
                                                                                    </>) : (<>
                                                                                        Non Graded
                                                                                    </>)
                                                                                }

                                                                            </TableCell>
                                                                            <TableCell align="left">
                                                                                {/* <Tooltip title={`${row.attemptedcount}`} placement='top-start' arrow > */}
                                                                                <progress id={`attempted${index}`} value={row.totalattemptedperc} max="100">
                                                                                    {`${row.totalattemptedperc !== null && row.totalattemptedperc !== undefined ? formatNumber(row.totalattemptedperc) : 0.00}%`}
                                                                                </progress><span style={{ marginLeft: "5px" }}>
                                                                                    {`${row.totalattemptedperc !== null && row.totalattemptedperc !== undefined ? formatNumber(row.totalattemptedperc) : 0.00}%`
                                                                                    }
                                                                                </span>
                                                                                {/* </Tooltip> */}
                                                                            </TableCell>
                                                                            <TableCell>{submitteddate}</TableCell>

                                                                            <TableCell align="left">
                                                                                {
                                                                                    parseInt(row.studentResetSubmissionDisabled) === 1 ? (
                                                                                        <Button variant="text"
                                                                                            disabled={true}
                                                                                            sx={{ textDecoration: "underline", backgroundColor: "transparent !important", textTransform: "none" }}
                                                                                        >Time Over</Button>) :
                                                                                        (<Button
                                                                                            variant="text"
                                                                                            onClick={(e) => resetstudentsubmission(e, row.user_id)}
                                                                                            sx={{ textDecoration: "underline", textTransform: "none" }}>Reset Submission</Button>)
                                                                                }
                                                                            </TableCell>


                                                                            <TableCell align="left">

                                                                                <Link
                                                                                    style={{ fontSize: "12px", textTransform: "none", paddingRight: "20px", textDecoration: "underline" }}
                                                                                    to={{
                                                                                        pathname: "/assignmentdetails/" + assignment_id + "/" + quizTitle + "/" + row.user_id,
                                                                                        state: {
                                                                                            prevPath: ['/'], prevNode: ['Student List'], studentname: [`${row.name}`], problemsource: [`${Problem_source.assignment}`]
                                                                                            , assignmentName: [`${quizTitle}`]
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    View report
                                                                                </Link>

                                                                            </TableCell>
                                                                            {/* <TableCell align="left" >
                                                                    <Tooltip title={`${row.reviewedcount}`} placement='top-start' arrow >
                                                                        <progress id={`reviewed${index}`} value={row.totalreviewedpercentage} max="100"> {row.totalreviewedpercentage}%
                                                                        </progress><span style={{ marginLeft: "5px" }}>{`${row.totalreviewedpercentage}%`}</span>
                                                                    </Tooltip>
                                                                </TableCell> */}
                                                                        </TableRow>
                                                                    );
                                                                })) : (
                                                                    // <div style={{ padding: "20px" }}>No Students Found!</div>
                                                                    <TableRow>
                                                                        <TableCell colSpan={5} style={{ padding: "20px", textAlign: "center", fontSize: "17px" }}>{displayStudentMessage}</TableCell>
                                                                    </TableRow>
                                                                )
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>) : (
                                                <div >
                                                    <TestReport problemList={problemList}
                                                        isGraded={isGraded}
                                                        problemparticipationReportList={problemparticipationReportList}
                                                        assignment_id={assignment_id}
                                                        onChartHideHandler={HideCSVLinkHandler}
                                                        csvExportDataparticipationsummary={csvExportDataparticipationsummary}></TestReport>
                                                </div>

                                            )
                                        }
                                    </>
                                )
                        }
                    </Paper>

                </Box>

            </div>
            {
                loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: "#164b99",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                            zIndex: "99"
                        }}
                    />
                )
            }


            <ToastContainer />
        </>
    );
};
export default withNamespaces()(RiceHomeworkDetail);