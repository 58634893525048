import React, { useEffect, useState } from 'react';
import {
    CardMedia
} from "@material-ui/core";
import { getCurrentUserId, Problem_source, QuestionType, assignment_Type } from '../../common/Functions';
import {
    imgURL, checkChallengeAnswer
} from '../../common/API';
import { makeStyles } from "@material-ui/core/styles";
import parse from 'html-react-parser';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material'
import HintIcon from '../../assets/images/icon-hint.svg';
import ShowFullSolutionIcon from '../../assets/images/icon-show-full-solution.svg';
import SaveToTranscriptIcon from '../../assets/images/icon-save-to-transcript.svg';
import checkWorkIcon from '../../assets/images/icon-check.svg';
import ShowFeedbackIcon from '../../assets/images/icon-show-next-step.svg';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
import Aux from "../../hoc/Wrapper";


const newStyles = makeStyles((theme) => ({
    root: {},
    cardDetails: {
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
        display: "flex"
    },
    librarPylaceholder: {
        height: "240px",
        alignItems: 'stretch',
        position: 'relative',
        width: '100%'
    },
    cardMedia: {
        width: "390px",
        borderRadius: "8px",
        overflow: "hidden",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "flex-start",
        display: "flex",
        marginBottom: "0px",
        marginTop: "24px",
        marginLeft: "24px",
        marginRight: "24px"
    },
    photoOutlined: {
        width: "100%",
        height: "210px",
        padding: "5px",
        objectFit: "contain",
        borderRadius: "12px",
    },
    radioGrp: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        marginLeft: "25px"
    }
}));


let mcqchallengepoints = 0.00
let totalnonfrqpoints = 0.00

const MSQAssignmentDetails = (props) => {

    const classes = newStyles();
    const [loading, setloading] = useState(false)
    const [openSubmittedPTMessgageModalMSQ, setopenSubmittedPTMessgageModalMSQ] = useState(false);
    const [isOpenAlertDialog, setisOpenAlertDialog] = useState(false)

    const [assignmentId, setassignmentId] = useState("");
    const [sptboardId, setboardId] = useState("");
    const [sptquestionId, setquestionId] = useState("");
    const [spttype, setspttype] = useState("");
    const [sptindex, setindex] = useState("");
    const [sptisHintUsed, setisHintUsed] = useState("");
    const [sptansForCHKWork, setansForCHKWork] = useState("");
    const [sptisCheckMyWork, setisCheckMyWork] = useState("");
    const [userGivenAnswer, setuserGivenAnswer] = useState("");
    const [sptusermsqEvent, setusermsqEvent] = useState();
    const [sptmsqSelectionAnswer, setsptmsqSelectionAnswer] = useState("");
    const [sptchallengeHref, setsptchallengeHref] = useState("");
    const [sptchallenge_id, setsptchallenge_id] = useState("");
    const [sptqueIndex, setsptqueIndex] = useState("");


    const closeAlertDialogHandler = () => {
        setisOpenAlertDialog(false)
    }

    const msqChangeHandler = (ev, assignmentId, boardId, questionId, type, index, isHintUsed, selectionans, isCheckMyWork) => {
        let userAnswer = ev.target.value.trim()
        setassignmentId(assignmentId)
        setboardId(boardId)
        setquestionId(questionId)
        setspttype(type)
        setindex(index)
        setisHintUsed(isHintUsed)
        setsptmsqSelectionAnswer(selectionans)
        setisCheckMyWork(isCheckMyWork)
        setuserGivenAnswer(ev.target.value.trim())
        setusermsqEvent(ev.target.checked)

        if (parseInt(props.isPtSubmitted) === 1) {
            setopenSubmittedPTMessgageModalMSQ(true)
        }
        else {
            msqcommoncode(ev.target.checked, assignmentId, boardId, questionId, index, isHintUsed, selectionans, isCheckMyWork, userAnswer)
        }
    }

    const msqcommoncode = (isChecked, assignmentId, boardId, questionId, index, isHintUsed, selectionans, isCheckMyWork, usergivenanser) => {
        let userAnswer
        if (isCheckMyWork && selectionans === "") {
            setisOpenAlertDialog(true)
        }
        else {
            if (!isCheckMyWork) {
                userAnswer = usergivenanser;
            }
            else {
                userAnswer = selectionans
            }

            checkChallengeAnswerCommonForMSQ(isChecked, assignmentId, userAnswer, boardId, questionId, index, isHintUsed, selectionans, isCheckMyWork)
        }
    }

    const checkChallengeAnswerCommonForMSQ = (isChecked, assignmentId, userEnteredAns, boardId, questionId, index, isHintUsed, selectionans, isCheckMyWork) => {
        if (userEnteredAns) {

            let userAnswer = ""

            if (isChecked) {
                if (selectionans !== "" && selectionans !== null && selectionans !== undefined) {
                    userAnswer = selectionans + "###" + userEnteredAns
                }
                else {
                    userAnswer = userEnteredAns
                }
            }
            else {
                if (selectionans.includes(userEnteredAns)) {

                    let finaldata = ""
                    if (selectionans.includes("###")) {

                        let arr = selectionans.split("###")
                        arr.forEach((rem) => {
                            if (userEnteredAns !== rem) {
                                // finalarray.push(rem)
                                if (finaldata !== "" && finaldata !== null && finaldata !== undefined) {
                                    finaldata = finaldata + "###" + rem
                                }
                                else {
                                    finaldata = rem
                                }
                            }
                        })
                    }
                    userAnswer = finaldata
                }
            }

            let reqData = {
                userAnswer: userAnswer,
                questionId: questionId,
                user_id: getCurrentUserId(),
                assignment_id: assignmentId,
                hints_used: isHintUsed,
                problem_source: Problem_source.assignment,
                boardId: boardId,
                final_submission: 0,
                optionselected: 1,
                fromTestPaper: 1,
                qtype: "MSQ"
            }
            checkChallengeAnswer(reqData).then(res => {
                let isExist = false
                if (props.attemptedQuestionList.length > 0) {
                    props.attemptedQuestionList.map(cdata => {
                        return (
                            cdata.challengeId === questionId ? isExist = true : null
                        )
                    })
                }

                if (!isExist) {
                    props.attemptedQuestionList.push({
                        "challengeId": questionId,
                        "index": index
                    })
                }

                let updateToList = [...props.msqQuestionList]
                if (updateToList[index] !== undefined && updateToList[index] !== null) {
                    let tempArr = updateToList[index]
                    if (tempArr.is_attempted === 0) {
                        props.attemptedQuestionCount(props.attemptedQueCountAsg + 1)
                    }

                    tempArr.selectedAnswer = userAnswer
                    if (userAnswer === "") {
                        tempArr.is_attempted = 0
                    }
                    else {
                        tempArr.is_attempted = 1
                    }

                    updateToList[index] = tempArr
                    if (updateToList[index].selectedAnswer === "" || tempArr.is_attempted === 0) {
                        props.attemptedQuestionCount(props.attemptedQueCountAsg - 1)
                    }

                    props.setMSQUpdatedQueList(updateToList)
                    setloading(false)
                }
            }).catch(err => {
                setloading(false)
                console.error(err.message);
                alert('Error occurred while submitting the answer.');
            })
        }
    }

    const checkChallengeAnswerCommonForMSQcheckmywork = (event, assignmentId, boardId, questionId, index, isHintUsed, selectionans, isCheckMyWork) => {
        let userEnteredAns = selectionans
        if (isCheckMyWork && selectionans === "") {
            setisOpenAlertDialog(true)
        }
        else {
            if (userEnteredAns) {
                let userAnswer = ""
                if (event !== undefined) {
                    if (event.target.checked) {
                        if (selectionans !== "" && selectionans !== null && selectionans !== undefined) {
                            userAnswer = selectionans + "###" + userEnteredAns
                        }
                        else {
                            userAnswer = userEnteredAns
                        }
                    }
                    else {
                        if (selectionans.includes(userEnteredAns)) {
                            let finaldata = ""
                            if (selectionans.includes("###")) {
                                let arr = selectionans.split("###")
                                arr.forEach((rem) => {
                                    if (userEnteredAns !== rem) {
                                        if (finaldata !== "" && finaldata !== null && finaldata !== undefined) {
                                            finaldata = finaldata + "###" + rem
                                        }
                                        else {
                                            finaldata = rem
                                        }
                                    }
                                })
                            }
                            userAnswer = finaldata
                        }
                    }
                }
                else {
                    if (selectionans.includes(userEnteredAns)) {
                        let finaldata = ""
                        if (selectionans.includes("###")) {
                            let arr = selectionans.split("###")
                            arr.forEach((rem) => {
                                if (userEnteredAns !== rem) {
                                    if (finaldata !== "" && finaldata !== null && finaldata !== undefined) {
                                        finaldata = finaldata + "###" + rem
                                    }
                                    else {
                                        finaldata = rem
                                    }
                                }
                            })

                            userAnswer = finaldata
                        }
                        else {
                            userAnswer = selectionans.toString()
                        }
                    }
                }

                let reqData = {
                    userAnswer: userAnswer,
                    questionId: questionId,
                    user_id: getCurrentUserId(),
                    assignment_id: assignmentId,
                    hints_used: isHintUsed,
                    problem_source: Problem_source.assignment,
                    boardId: boardId,
                    final_submission: 0,
                    optionselected: 1,
                    fromTestPaper: 1,
                    qtype: "MSQ"
                }
                checkChallengeAnswer(reqData).then(res => {
                    let isExist = false
                    if (props.attemptedQuestionList.length > 0) {
                        props.attemptedQuestionList.map(cdata => {
                            return (
                                cdata.challengeId === questionId ? isExist = true : null
                            )
                        })
                    }

                    if (!isExist) {
                        props.attemptedQuestionList.push({
                            "challengeId": questionId,
                            "index": index
                        })
                    }

                    let updateToList = [...props.msqQuestionList]
                    if (updateToList[index] !== undefined && updateToList[index] !== null) {
                        let tempArr = updateToList[index]
                        if (tempArr.is_attempted === 0) {
                            props.attemptedQuestionCount(props.attemptedQueCountAsg + 1)
                        }

                        tempArr.selectedAnswer = userAnswer
                        if (userAnswer === "") {
                            tempArr.is_attempted = 0
                        }
                        else {
                            tempArr.is_attempted = 1
                        }

                        updateToList[index] = tempArr

                        if (updateToList[index].selectedAnswer === "" || tempArr.is_attempted === 0) {
                            props.attemptedQuestionCount(props.attemptedQueCountAsg - 1)
                        }

                        props.setMSQUpdatedQueList(updateToList)
                        setloading(false)
                    }

                    if (isCheckMyWork) {
                        setloading(true)
                        let modalContent = null;
                        let finaldata = []
                        let chkWorkModalTitle = null
                        if (res.data.status === 'correct') {
                            modalContent = (
                                <div className='answer-model-correct d-flex justify-content-center'>
                                    <span className='correct-answer mr-2'></span>
                                    <h5>Correct Answer</h5>
                                </div>

                            )
                        } else {
                            chkWorkModalTitle = (
                                <div className='d-flex align-items-center'>
                                    <span className='incorrect-answer mr-2'></span>
                                    <h4 className='m-0'>"This isn’t the correct answer."</h4>
                                </div>)

                            {

                                res.data.data !== undefined && res.data.data.length > 0 && res.data.data.map(item => {
                                    let contentdata = (
                                        <Aux>
                                            {
                                                item.remedial_text !== "" && item.remedial_text !== '' && item.remedial_text !== null ? (
                                                    <>
                                                        <h5>Explanation:</h5>
                                                        <span className='d-block'>
                                                            <p dangerouslySetInnerHTML={{ __html: item.remedial_text }}></p>
                                                        </span>
                                                    </>) : null
                                            }
                                            {
                                                item.remedial_source_url !== "" && item.remedial_source_url !== '' && item.remedial_source_url !== null ?
                                                    (<>
                                                        <h5>Recommended Resources:</h5>
                                                        <p className='mb-4'>
                                                            <a href={item.remedial_source_url} target='_blank' rel="noopener noreferrer">
                                                                <p dangerouslySetInnerHTML={{ __html: item.remedial_source_title ? item.remedial_source_title : item.remedial_source_url }}></p></a></p>
                                                    </>) : null
                                            }
                                        </Aux>
                                    )
                                    finaldata.push(contentdata)
                                })
                            }
                        }

                        if (res.data.status !== 'correct') {
                            modalContent = (
                                <div className='answer-modal-incorrect'>
                                    {finaldata}
                                </div>
                            )
                        }
                        setloading(false)
                        props.openAsgModal(event, chkWorkModalTitle, modalContent, null, true, true);
                    }
                }).catch(err => {
                    setloading(false)
                    console.error(err.message);
                    alert('Error occurred while submitting the answer.');
                })
            }
        }
    }

    const closepointmessagehanslerMSQ = (ev) => {
        setopenSubmittedPTMessgageModalMSQ(false)
        msqcommoncode(ev, assignmentId, sptboardId, sptquestionId, sptindex, sptisHintUsed, sptansForCHKWork, sptisCheckMyWork, userGivenAnswer)
    }

    console.log("test111111")
    return (
        <>

            <Dialog
                hideBackdrop={false}
                open={isOpenAlertDialog}
                onClose={closeAlertDialogHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div style={{ marginTop: "10px", color: "#164b99" }}>
                            Please select an answer first.
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button className="dragLabelcancel leftmargin" onClick={closeAlertDialogHandler}>
                        OK
                    </button>
                </DialogActions>
            </Dialog>

            <Dialog
                hideBackdrop={false}
                open={openSubmittedPTMessgageModalMSQ}
                onClose={(e) => closepointmessagehanslerMSQ(e)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div style={{ marginTop: "10px", color: "#164b99" }}>
                            Important: Your grades reflect your submitted solutions only. Any modifications afterward won't alter your grades.
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button className="dragLabelcancel leftmargin" onClick={(e) => closepointmessagehanslerMSQ(e)}>
                        OK
                    </button>
                </DialogActions>
            </Dialog>

            <div key={"keyQ_" + props.msqQuestion.assignment_challenge_id + "_" + props.msqQuestion.challenge_id} id={props.msqQuestion.assignment_challenge_id + "_" + props.msqQuestion.challenge_id}
                className="questionDiv">
                <div className="lineBreak_question"></div>
                <div className="questionDetails">
                    {props.isReview ? <div className="questionHeaderChapterText">({props.msqQuestion.chapter})</div> : null}
                    <div className={classes.cardDetails}>
                        <div className={classes.cardMedia}>
                            <div className={classes.librarPylaceholder}>
                                <CardMedia
                                    component="img"
                                    image={imgURL + props.msqQuestion.thumbnail}
                                    alt="dummy Image"
                                    className={classes.photoOutlined}
                                />
                            </div>
                        </div>

                        {
                            parseInt(props.msqQuestion.is_multipart) === 1 ? (
                                <span className="questionText">
                                    Statement: {parse(props.msqQuestion.problemstement)}
                                </span>)
                                : null
                        }
                        <span className="questionText">
                            {parse(props.msqQuestion.question)}
                        </span>
                    </div>
                    {
                        props.msqQuestion.options.map((opt, index) => {
                            let selectionans = props.msqQuestion.selectedAnswer
                            if (props.msqQuestion.selectedAnswer.includes("###")) {
                                let str = selectionans.split("###")
                                str.forEach((rem) => {
                                    if (parseInt(opt.id) === parseInt(rem)) {
                                        selectionans = rem.trim()
                                    }
                                })
                            }

                            return (
                                <div className={`no-gutters ${classes.radioGrp}`} key={props.msqQuestion.challenge_id + "_" + index} style={{ display: "flex" }}>
                                    <div><input
                                        type='checkbox' disabled={props.isTutorReview}
                                        name={"cOption_" + props.msqQuestion.challenge_id + "_" + index}
                                        id={"chk_" + props.msqQuestion.challenge_id + "_" + index}
                                        key={'chkKey_' + props.msqQuestion.challenge_id + "_" + index}
                                        value={opt.id}
                                        className='mr-2'
                                        checked={parseInt(selectionans) === parseInt(opt.id)}
                                        onChange={(event) => msqChangeHandler(event, props.msqQuestion.assignment_id, props.msqQuestion.boardId, props.msqQuestion.challenge_id, props.msqQuestion.type, props.queIndex, props.msqQuestion.hint_used, props.msqQuestion.selectedAnswer, false)}
                                    /></div>

                                    <div>
                                        <label htmlFor={"chk_" + props.msqQuestion.challenge_id + "_" + index} key={'l_' + props.msqQuestion.challenge_id + "_" + index}
                                            style={{ cursor: 'pointer' }}>
                                            <div dangerouslySetInnerHTML={{ __html: opt.text }} /> </label>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <p className={props.msqQuestion.isCorrect ? "divMsgCorrectBtns" : "divMsgInCorrectBtns"}>
                        {parseInt(props.msqQuestion.totalGradeCalculationTrial) === 0 ?
                            <>
                                {props.msqQuestion.is_submitted === 1 ?
                                    props.msqQuestion.isCorrect ? "Correct Answer." : "In-Correct Answer."
                                    : null}
                            </>
                            :
                            parseInt(props.msqQuestion.totalGradeCalculationTrial) > 0 && parseInt(props.msqQuestion.totalGradeCalculationTrial) < props.trials ?
                                props.msqQuestion.isCorrect && parseInt(props.msqQuestion.totalGradeCalculationTrial) <= props.trials ?
                                    "Correct Answer. Remaining attempt(s): " + props.msqQuestion.remainingTrial : "In-Correct Answer. Try again! Remaining attempt(s): " + props.msqQuestion.remainingTrial
                                :
                                parseInt(props.msqQuestion.totalGradeCalculationTrial) === props.trials && props.msqQuestion.isCorrect ? "Correct Answer. All trials(" + props.msqQuestion.totalGradeCalculationTrial + " /" + props.trials + ") are completed for this question." : "In-Correct Answer. All trials(" + props.msqQuestion.totalGradeCalculationTrial + "/" + props.trials + ") are completed for this question."
                        }
                    </p>

                    <div className="divGuideBtnsSubmit" style={{ marginTop: '-60px', position : 'relative', height: '70px', marginBottom: '30px', display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
                        {
                            <p className='divIndividualSubmitBtn'>
                                <Tooltip key={"sol_" + props.msqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Submit Answer" placement='right' arrow>
                                    <IconButton
                                        onClick={() =>
                                            props.mcqmsqProblemSubmit(props.msqQuestion.selectedAnswer, props.msqQuestion.challenge_id, props.msqQuestion.assignment_id, props.msqQuestion.hint_used, props.msqQuestion.boardId, props.msqQuestion.type, props.queIndex, props.isFromFRQ)
                                        }>
                                        <div className='boardBtns'>
                                            <img alt='Submit selected answer' src={SaveToTranscriptIcon} />
                                        </div>
                                        <p className='toolLabel'>Submit Answer</p>
                                    </IconButton>
                                </Tooltip>
                            </p>
                        }
                    </div>
                    <div className="divGuideBtns" style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
                        {
                            props.isGuided ?
                                <>
                                    <Tooltip key={"hint_" + props.msqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Hint" placement='right' arrow >
                                        <IconButton disabled={props.msqQuestion.totalGradeCalculationTrial <= props.trials ? false : false} onClick={() => props.getHint(this, props.msqQuestion.user_challenge_map_id, props.msqQuestion.boardId, props.queIndex, props.msqQuestion.type, props.msqQuestion.challenge_id, props.msqQuestion.assignment_id)}>
                                            <div className='boardBtns' >
                                                <img alt='Hint Icon' src={HintIcon} />
                                            </div>
                                            <p className='toolLabel'>Hint</p>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip key={"ChkMCQW_" + props.msqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Check My Work" placement='right' arrow>
                                        <IconButton disabled={props.msqQuestion.totalGradeCalculationTrial <= props.trials ? false : false} onClick={() => checkChallengeAnswerCommonForMSQcheckmywork(this, props.msqQuestion.assignment_id, props.msqQuestion.boardId, props.msqQuestion.challenge_id, props.queIndex, props.msqQuestion.hint_used, props.msqQuestion.selectedAnswer, true)}>
                                            <div className='boardBtns'>
                                                <img alt='Check My Work' src={checkWorkIcon} />
                                            </div>
                                            <p className='toolLabel'>Check My Work</p>
                                        </IconButton>
                                    </Tooltip>
                                    {/* <Tooltip key={"sol_" + props.msqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Show Full Solution" placement='right' arrow>
                                        <IconButton onClick={() => props.showSolution(this, props.msqQuestion.user_challenge_map_id, props.msqQuestion.boardId)}>
                                            <div className='boardBtns'>
                                                <img alt='Show Full Solution' src={ShowFullSolutionIcon} />
                                            </div>
                                            <p className='toolLabel'>Show Full Solution</p>
                                        </IconButton>
                                    </Tooltip> */}
                                </>
                                :
                                null
                        }
                        {
                            props.isGraded && props.isGuided ? null : null
                            // !props.isReview && props.isGraded && props.trials > 1 && props.msqQuestion.is_submitted === 0 ? (
                            //     <Tooltip key={"ChkMCQScore_" + props.msqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Each incorrect answer(score check) will result in a 20% deduction for this question." placement='right' arrow >
                            //         <IconButton disabled={parseInt(props.msqQuestion.totalGradeCalculationTrial) === props.trials ? true : false}
                            //             onClick={() => props.getMyScore(this, props.msqQuestion.assignment_id, props.msqQuestion.selectedAnswer, props.msqQuestion.boardId, props.msqQuestion.challenge_id, props.msqQuestion.type, props.queIndex, props.msqQuestion.hint_used, false, props.msqQuestion.totalGradeCalculationTrial, props.isFromFRQ)}>
                            //             <div className='boardBtns' >
                            //                 <img alt='Score Icon' src={ShowFeedbackIcon} />
                            //             </div>
                            //             <p className={parseInt(props.msqQuestion.totalGradeCalculationTrial) === props.trials ? "toolLabelGrey" : 'toolLabel'}>
                            //                 {parseInt(props.msqQuestion.totalGradeCalculationTrial) > 0 && parseInt(props.msqQuestion.totalGradeCalculationTrial) <= props.trials ?
                            //                     parseInt(props.msqQuestion.totalGradeCalculationTrial) === props.trials ?
                            //                         "All trials(" + props.msqQuestion.totalGradeCalculationTrial + "/" + props.trials + ") are completed for this question" : "Check Score (" + parseInt(props.msqQuestion.totalGradeCalculationTrial) + "/" + props.trials + ")"
                            //                     :
                            //                     "Check Score"
                            //                 }
                            //             </p>
                            //         </IconButton>
                            //     </Tooltip>
                            // ) : null
                        }
                        {
                            props.msqQuestion.is_submitted === 1 ? (<Tooltip key={"sum_" + props.msqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Show Feedback"
                                placement='right' arrow>
                                <IconButton onClick={() => props.showfeedback(this, props.queIndex, props.msqQuestion.isgraded, props.msqQuestion.hintdata, props.msqQuestion.boardId, QuestionType.msq, props.msqQuestion.challenge_id, props.msqQuestion.assignment_id, props.msqQuestion.totalGradeCalculationTrial, props.msqQuestion.totalnonfrqpoints)}>
                                    <div className='boardBtns'>
                                        <img alt='Show Feedback' src={ShowFeedbackIcon} />
                                    </div>
                                    <p className='toolLabel'>Show Feedback</p>
                                </IconButton>
                            </Tooltip>) : null
                        }

                        {
                            parseInt(props.msqQuestion.totalGradeCalculationTrial) >= props.trials || props.msqQuestion.is_submitted === 1 || props.isReview ?
                                <Tooltip key={"sol_" + props.msqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Show Full Solution" placement='right' arrow>
                                    <IconButton onClick={() => props.showSolution(this, props.msqQuestion.user_challenge_map_id, props.msqQuestion.boardId, props.msqQuestion.assignment_id)}>
                                        <div className='boardBtns'>
                                            <img alt='Show Full Solution' src={ShowFullSolutionIcon} />
                                        </div>
                                        <p className='toolLabel'>Show Full Solution</p>
                                    </IconButton>
                                </Tooltip>
                                :
                                null
                        }
                    </div>
                </div>
                <div className="questionHeader">
                    <div className="questionHeaderText">{
                        props.assignmentType === assignment_Type.homework ? (<>{props.msqQuestion.challenge_name}</>) : <>{props.msqQuestion.challenge_display}</>
                    }</div>
                    <div className='questionRightDiv'>
                        {props.msqQuestion.totalGradeCalculationTrial > 0 ?
                            <div className="gradeCalTrial">
                                # of Attempts : {props.msqQuestion.totalGradeCalculationTrial}/{props.trials}
                            </div>
                            : null}
                        {props.isGraded ?
                            <div className="points5">
                                {props.msqQuestion.is_attempted === 1 ?
                                    (
                                        props.isReview ? "Points: " + (props.msqQuestion.challenge_points !== undefined && props.msqQuestion.challenge_points !== null ? parseFloat(props.msqQuestion.challenge_points).toFixed(2) : 0) + "/" +
                                            (props.msqQuestion.totalnonfrqpoints !== undefined && props.msqQuestion.totalnonfrqpoints !== null ? parseFloat(props.msqQuestion.totalnonfrqpoints).toFixed(2) : 0)
                                            :
                                            props.msqQuestion.is_submitted === 1 ?
                                                (
                                                    "Points: " + (props.msqQuestion.challenge_points !== undefined && props.msqQuestion.challenge_points !== null ? parseFloat(props.msqQuestion.challenge_points).toFixed(2) : 0)
                                                    + "/" + (props.msqQuestion.totalnonfrqpoints !== undefined && props.msqQuestion.totalnonfrqpoints !== null ? parseFloat(props.msqQuestion.totalnonfrqpoints).toFixed(2) : 0)
                                                )
                                                :
                                                (
                                                    "Points: -/" + (props.msqQuestion.totalnonfrqpoints !== undefined && props.msqQuestion.totalnonfrqpoints !== null ? parseFloat(props.msqQuestion.totalnonfrqpoints).toFixed(2) : 0)
                                                )
                                    )
                                    :
                                    (
                                        "Points: -/" + (props.msqQuestion.totalnonfrqpoints !== undefined && props.msqQuestion.totalnonfrqpoints !== null ? parseFloat(props.msqQuestion.totalnonfrqpoints).toFixed(2) : 0)
                                    )
                                }
                            </div>
                            :
                            null
                        }
                        <div className="questionAvatar">
                            <div className="op">{props.msqQuestion.type}</div>
                            <div className="minWidth" />
                        </div>
                    </div>
                </div>
            </div>

            {
                loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: "#164b99",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )
            }

        </>
    )
}

export default MSQAssignmentDetails
