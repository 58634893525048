import React, { useEffect, useState } from 'react';
import {
    CardMedia
} from "@material-ui/core";
import { getCurrentUserId, Problem_source, QuestionType, assignment_Type } from '../../common/Functions';
import {
    imgURL, checkChallengeAnswer
} from '../../common/API';
import { makeStyles } from "@material-ui/core/styles";
import parse from 'html-react-parser';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material'
import HintIcon from '../../assets/images/icon-hint.svg';
import ShowFullSolutionIcon from '../../assets/images/icon-show-full-solution.svg';
import SaveToTranscriptIcon from '../../assets/images/icon-save-to-transcript.svg';
import checkWorkIcon from '../../assets/images/icon-check.svg';
import ShowFeedbackIcon from '../../assets/images/icon-show-next-step.svg';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';

const newStyles = makeStyles((theme) => ({
    root: {},
    cardDetails: {
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
        display: "flex"
    },
    librarPylaceholder: {
        height: "240px",
        alignItems: 'stretch',
        position: 'relative',
        width: '100%'
    },
    cardMedia: {
        width: "390px",
        borderRadius: "8px",
        overflow: "hidden",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "flex-start",
        display: "flex",
        marginBottom: "0px",
        marginTop: "24px",
        marginLeft: "24px",
        marginRight: "24px"
    },
    photoOutlined: {
        width: "100%",
        height: "210px",
        padding: "5px",
        objectFit: "contain",
        borderRadius: "12px",
    },
    radioGrp: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        marginLeft: "25px"
    }
}));


let mcqchallengepoints = 0.00
let totalnonfrqpoints = 0.00


const MCQAssignmentDetails = (props) => {

    const classes = newStyles();
    const [loading, setloading] = useState(false)
    const [openSubmittedPTMessgageModal, setopenSubmittedPTMessgageModal] = useState(false);
    const [isOpenAlertDialog, setisOpenAlertDialog] = useState(false)

    const [assignmentId, setassignmentId] = useState("");
    const [sptboardId, setboardId] = useState("");
    const [sptquestionId, setquestionId] = useState("");
    const [spttype, setspttype] = useState("");
    const [sptindex, setindex] = useState("");
    const [sptisHintUsed, setisHintUsed] = useState("");
    const [sptansForCHKWork, setansForCHKWork] = useState("");
    const [sptisCheckMyWork, setisCheckMyWork] = useState("");
    const [userGivenAnswer, setuserGivenAnswer] = useState("");
    const [sptusermsqEvent, setusermsqEvent] = useState();
    const [sptmsqSelectionAnswer, setsptmsqSelectionAnswer] = useState("");
    const [sptchallengeHref, setsptchallengeHref] = useState("");
    const [sptchallenge_id, setsptchallenge_id] = useState("");
    const [sptqueIndex, setsptqueIndex] = useState("");


    const closeAlertDialogHandler = () => {
        setisOpenAlertDialog(false)
    }

    const mcqChangeHandler = (ev, assignmentId, boardId, questionId, index, isHintUsed, ansForCHKWork, isCheckMyWork, isFromFRQ) => {
        setassignmentId(assignmentId)
        setboardId(boardId)
        setquestionId(questionId)
        setindex(index)
        setisHintUsed(isHintUsed)
        setansForCHKWork(ansForCHKWork)
        setisCheckMyWork(isCheckMyWork)
        setuserGivenAnswer(ev.target.value.trim())
        if (parseInt(props.isPtSubmitted) === 1) {
            setopenSubmittedPTMessgageModal(true)
        }
        else {
            mcqcommoncode(ev, assignmentId, boardId, questionId, index, isHintUsed, ansForCHKWork, isCheckMyWork, ev.target.value.trim(), isFromFRQ)
        }
    }

    const mcqcommoncode = (ev, assignmentId, boardId, questionId, index, isHintUsed, ansForCHKWork, isCheckMyWork, usergivenanser, isFromFRQ) => {
        let userAnswer

        if (isCheckMyWork && ansForCHKWork === "") {
            setisOpenAlertDialog(true)
        }
        else {
            if (ansForCHKWork === "") {
                userAnswer = usergivenanser === "" ? ev.target.value.trim() : usergivenanser;
            }
            else {
                userAnswer = ansForCHKWork
            }

            checkChallengeAnswerCommonForMCQ(ev, assignmentId, userAnswer, boardId, questionId, index, isHintUsed, isCheckMyWork, isFromFRQ)
        }
    }

    const checkChallengeAnswerCommonForMCQ = (event, assignmentId, userEnteredAns, boardId, questionId, index, isHintUsed, isCheckMyWork, isFromFRQ) => {
        if (userEnteredAns) {
            let reqData = {
                userAnswer: userEnteredAns,
                questionId: questionId,
                user_id: getCurrentUserId(),
                assignment_id: assignmentId,
                hints_used: isHintUsed,
                problem_source: Problem_source.assignment,
                boardId: boardId,
                final_submission: 0,
                optionselected: 1,
                fromTestPaper: 1,
                qtype: "MCQ"
            }

            checkChallengeAnswer(reqData).then(res => {
                let isExist = false
                if (props.attemptedQuestionList.length > 0) {
                    props.attemptedQuestionList.map(cdata => {
                        return (
                            cdata.challengeId === questionId ? isExist = true : null
                        )
                    })
                }

                if (!isExist) {
                    props.attemptedQuestionList.push({
                        "challengeId": questionId,
                        "index": index
                    })
                }

                if (isFromFRQ) {
                    let updateToList = [...props.frqQuestionList]
                    if (updateToList[index] !== undefined && updateToList[index] !== null) {
                        let tempArr = updateToList[index]

                        tempArr.selectedAnswer = userEnteredAns;
                        tempArr.is_attempted = 1

                        updateToList[index] = tempArr
                        props.setFRQUpdatedQueList(updateToList)
                        setloading(false)

                        let multigrouplist = updateToList.filter((e) => e.multipartgroupid === parseInt(tempArr.multipartgroupid))
                        let isattemptlist = multigrouplist.filter((e) => e.is_attempted === 1)
                        if (multigrouplist.length === isattemptlist.length) {
                            if (props.attemptedQueCount < props.totalQuestionCount)
                                props.attemptedQuestionCount(props.attemptedQueCount + 1)
                        }
                    }
                }
                else {
                    let updateToList = [...props.mcqQuestionList]
                    if (updateToList[index] !== undefined && updateToList[index] !== null) {
                        let tempArr = updateToList[index]
                        if (tempArr.is_attempted === 0) {
                            props.attemptedQuestionCount(props.attemptedQueCountAsg + 1)
                        }

                        tempArr.selectedAnswer = userEnteredAns;
                        tempArr.is_attempted = 1

                        updateToList[index] = tempArr
                        props.setMCQUpdatedQueList(updateToList)
                        setloading(false)
                    }
                }

                if (isCheckMyWork) {
                    setloading(true)
                    let modalContent = null;
                    let chkWorkModalTitle = null
                    if (res.data.status === 'correct') {
                        modalContent = (
                            <div className='answer-model-correct d-flex justify-content-center'>
                                <span className='correct-answer mr-2'></span>
                                <h5>Correct Answer</h5>
                            </div>
                        )
                    } else {
                        chkWorkModalTitle = (
                            <div className='d-flex align-items-center'>
                                <span className='incorrect-answer mr-2'></span>
                                <h4 className='m-0'>"This isn’t the correct answer."</h4>
                            </div>)

                        modalContent = (
                            <div className='answer-modal-incorrect'>
                                {
                                    res.data.data !== undefined && res.data.data.length > 0 ? (
                                        res.data.data.map(item => {
                                            return (
                                                <>
                                                    {
                                                        item.remedial_text !== "" && item.remedial_text !== '' && item.remedial_text !== null ? (
                                                            <>
                                                                <h5>Explanation:</h5>
                                                                <span className='d-block'>
                                                                    <p dangerouslySetInnerHTML={{ __html: item.remedial_text }}></p>
                                                                </span>
                                                            </>) : null
                                                    }
                                                    {
                                                        item.remedial_source_url !== "" && item.remedial_source_url !== '' && item.remedial_source_url !== null ?
                                                            (<>
                                                                <h5>Recommended Resources:</h5>
                                                                <p className='mb-4'>
                                                                    <a href={item.remedial_source_url} target='_blank' rel="noopener noreferrer">
                                                                        <p dangerouslySetInnerHTML={{ __html: item.remedial_source_title ? item.remedial_source_title : item.remedial_source_url }}></p></a></p>
                                                            </>) : null
                                                    }
                                                </>
                                            )
                                        })

                                    ) : null
                                }
                            </div>
                        )
                    }

                    setloading(false)
                    props.openAsgModal(event, chkWorkModalTitle, modalContent, null, true, true);
                }
            }).catch(err => {
                setloading(false)
                console.error(err.message);
                alert('Error occurred while submitting the answer.');
            })
        }
    }

    const closepointmessagehansler = (ev) => {
        setopenSubmittedPTMessgageModal(false)
        mcqcommoncode(ev, assignmentId, sptboardId, sptquestionId, sptindex, sptisHintUsed, sptansForCHKWork, sptisCheckMyWork, userGivenAnswer, props.isFromFRQ)
    }


    return (
        <>

            <Dialog
                hideBackdrop={false}
                open={isOpenAlertDialog}
                onClose={closeAlertDialogHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div style={{ marginTop: "10px", color: "#164b99" }}>
                            Please select an answer first.
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button className="dragLabelcancel leftmargin" onClick={closeAlertDialogHandler}>
                        OK
                    </button>
                </DialogActions>
            </Dialog>

            <Dialog
                hideBackdrop={false}
                open={openSubmittedPTMessgageModal}
                onClose={(e) => closepointmessagehansler(e)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div style={{ marginTop: "10px", color: "#164b99" }}>
                            Important: Your grades reflect your submitted solutions only. Any modifications afterward won't alter your grades.
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button className="dragLabelcancel leftmargin" onClick={(e) => closepointmessagehansler(e)}>
                        OK
                    </button>
                </DialogActions>
            </Dialog>

            <div key={"keyQ_" + props.mcqQuestion.assignment_challenge_id + "_" + props.mcqQuestion.challenge_id} id={props.mcqQuestion.assignment_challenge_id + "_" + props.mcqQuestion.challenge_id}
                className="questionDiv">
                <div className="lineBreak_question"></div>
                <div className="questionDetails">
                    {props.isReview ? <div className="questionHeaderChapterText">({props.mcqQuestion.chapter})</div> : null}
                    <div className={classes.cardDetails}>
                        <div className={classes.cardMedia}>
                            <div className={classes.librarPylaceholder}>
                                <CardMedia
                                    component="img"
                                    image={imgURL + props.mcqQuestion.thumbnail}
                                    alt="dummy Image"
                                    className={classes.photoOutlined}
                                />
                            </div>
                        </div>

                        {
                            parseInt(props.mcqQuestion.is_multipart) === 1 ? (
                                <span className="questionText">
                                    Statement: {parse(props.mcqQuestion.problemstement)}
                                </span>)
                                : null
                        }
                        <span className="questionText">
                            {parse(props.mcqQuestion.question)}
                        </span>
                    </div>
                    {
                        props.mcqQuestion.options.map((opt, index) => {
                            return (
                                <div className={`no-gutters ${classes.radioGrp}`}
                                    key={props.mcqQuestion.challenge_id + "_" + index}
                                    style={{ display: "flex" }}>
                                    <div>
                                        <input
                                            type='radio'
                                            disabled={props.isGraded && parseInt(props.mcqQuestion.totalGradeCalculationTrial) === props.trials ? true : props.isTutorReviewDone}
                                            name={"rOption_" + props.mcqQuestion.challenge_id + "_" + index}
                                            id={"rdo_" + props.mcqQuestion.challenge_id + "_" + index}
                                            key={'rdoKey_' + props.mcqQuestion.challenge_id + "_" + index}
                                            value={opt.id}
                                            className='mr-2'
                                            checked={parseInt(props.mcqQuestion.selectedAnswer) === parseInt(opt.id)}
                                            onChange={(event) => mcqChangeHandler(event, props.mcqQuestion.assignment_id, props.mcqQuestion.boardId, props.mcqQuestion.challenge_id, props.queIndex, props.mcqQuestion.hint_used, "", false, props.isFromFRQ)}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor={"rdo_" + props.mcqQuestion.challenge_id + "_" + index} key={'l_' + props.mcqQuestion.challenge_id + "_" + index}
                                            style={{ cursor: 'pointer' }}>
                                            <p className='mb-0'>
                                                <div dangerouslySetInnerHTML={{ __html: opt.text }} />
                                            </p>
                                        </label>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <p className={props.mcqQuestion.isCorrect ? "divMsgCorrectBtns" : "divMsgInCorrectBtns"}>
                        {parseInt(props.mcqQuestion.totalGradeCalculationTrial) === 0 ?
                            <>
                                {props.mcqQuestion.is_submitted === 1 ?
                                    props.mcqQuestion.isCorrect ? "Correct Answer." : "In-Correct Answer."
                                    : null}
                            </>
                            :
                            parseInt(props.mcqQuestion.totalGradeCalculationTrial) > 0 && parseInt(props.mcqQuestion.totalGradeCalculationTrial) < props.trials ?
                                props.mcqQuestion.isCorrect && parseInt(props.mcqQuestion.totalGradeCalculationTrial) <= props.trials ?
                                    "Correct Answer. Remaining attempt(s): " + props.mcqQuestion.remainingTrial : "In-Correct Answer. Try again! Remaining attempt(s): " + props.mcqQuestion.remainingTrial
                                :
                                parseInt(props.mcqQuestion.totalGradeCalculationTrial) === props.trials && props.mcqQuestion.isCorrect ? "Correct Answer. All trials(" + props.mcqQuestion.totalGradeCalculationTrial + "/" + props.trials + ") are completed for this question." : "In-Correct Answer. All trials(" + props.mcqQuestion.totalGradeCalculationTrial + "/" + props.trials + ") are completed for this question."
                        }
                    </p>

                    <div className="divGuideBtnsSubmit" style={{ marginTop: '-60px', position: 'relative', height: '70px', marginBottom: '30px', display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
                        {
                            <p className='divIndividualSubmitBtn'>
                                <Tooltip key={"sol_" + props.mcqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Submit Answer" placement='right' arrow>
                                    <IconButton

                                        onClick={() =>
                                            props.mcqmsqProblemSubmit(props.mcqQuestion.selectedAnswer, props.mcqQuestion.challenge_id, props.mcqQuestion.assignment_id, props.mcqQuestion.hint_used, props.mcqQuestion.boardId, props.mcqQuestion.type, props.queIndex, props.isFromFRQ)
                                        }>
                                        <div className='boardBtns'>
                                            <img alt='Submit selected answer' src={SaveToTranscriptIcon} />
                                        </div>
                                        <p className='toolLabel'>Submit Answer</p>
                                    </IconButton>
                                </Tooltip>
                            </p>
                        }
                    </div>
                    <div className="divGuideBtns" style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
                        {props.isGuided ?
                            <>
                                <Tooltip key={"hint_" + props.mcqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Hint" placement='right' arrow >
                                    <IconButton disabled={props.mcqQuestion.totalGradeCalculationTrial <= props.trials ? false : false} onClick={() => props.getHint(this, props.mcqQuestion.user_challenge_map_id, props.mcqQuestion.boardId, props.queIndex, props.mcqQuestion.type, props.mcqQuestion.challenge_id, props.mcqQuestion.assignment_id)}>
                                        <div className='boardBtns' >
                                            <img alt='Hint Icon' src={HintIcon} />
                                        </div>
                                        <p className='toolLabel'>Hint</p>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip key={"ChkMCQW_" + props.mcqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Check My Work" placement='right' arrow>
                                    <IconButton disabled={props.mcqQuestion.totalGradeCalculationTrial <= props.trials ? false : false} onClick={() => mcqcommoncode(this, props.mcqQuestion.assignment_id, props.mcqQuestion.boardId, props.mcqQuestion.challenge_id, props.queIndex, props.mcqQuestion.hint_used, props.mcqQuestion.selectedAnswer, true, "", props.isFromFRQ)}>
                                        <div className='boardBtns'>
                                            <img alt='Check My Work' src={checkWorkIcon} />
                                        </div>
                                        <p className='toolLabel'>Check My Work</p>
                                    </IconButton>
                                </Tooltip>
                                {/* <Tooltip key={"sol_" + props.mcqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Show Full Solution" placement='right' arrow>
                                    <IconButton onClick={() => props.showSolution(this, props.mcqQuestion.user_challenge_map_id, props.mcqQuestion.boardId)}>
                                        <div className='boardBtns'>
                                            <img alt='Show Full Solution' src={ShowFullSolutionIcon} />
                                        </div>
                                        <p className='toolLabel'>Show Full Solution</p>
                                    </IconButton>
                                </Tooltip> */}
                            </>
                            : null
                        }
                        {
                            props.isGraded && props.isGuided ? null : null
                            // !props.isReview && props.isGraded && props.trials > 1 && props.mcqQuestion.is_submitted === 0 ? (
                            //     <Tooltip key={"ChkMCQScore_" + props.mcqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Each incorrect answer(score check) will result in a 20% deduction for this question." placement='right' arrow >
                            //         <IconButton disabled={parseInt(props.mcqQuestion.totalGradeCalculationTrial) === props.trials ? true : false}
                            //             onClick={() => props.getMyScore(this, props.mcqQuestion.assignment_id, props.mcqQuestion.selectedAnswer, props.mcqQuestion.boardId, props.mcqQuestion.challenge_id, props.mcqQuestion.type, props.queIndex, props.mcqQuestion.hint_used, false, props.mcqQuestion.totalGradeCalculationTrial, props.isFromFRQ)}>
                            //             <div className='boardBtns' >
                            //                 <img alt='Score Icon' src={ShowFeedbackIcon} />
                            //             </div>
                            //             <p className={parseInt(props.mcqQuestion.totalGradeCalculationTrial) === props.trials ? "toolLabelGrey" : 'toolLabel'}>
                            //                 {parseInt(props.mcqQuestion.totalGradeCalculationTrial) > 0 && parseInt(props.mcqQuestion.totalGradeCalculationTrial) <= props.trials ?
                            //                     parseInt(props.mcqQuestion.totalGradeCalculationTrial) === props.trials ?
                            //                         "All trials(" + props.mcqQuestion.totalGradeCalculationTrial + "/" + props.trials + ") are completed for this question" : "Check Score (" + parseInt(props.mcqQuestion.totalGradeCalculationTrial) + "/" + props.trials + ")"
                            //                     :
                            //                     "Check Score"
                            //                 }
                            //             </p>
                            //         </IconButton>
                            //     </Tooltip>
                            // ) : null
                        }
                        {
                            props.mcqQuestion.is_submitted === 1 ? (<Tooltip key={"sum_" + props.mcqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Show Feedback"
                                placement='right' arrow>
                                <IconButton onClick={() => props.showfeedback(this, props.queIndex, props.mcqQuestion.hintdata, props.mcqQuestion.isgraded, props.mcqQuestion.boardId, QuestionType.mcq, props.mcqQuestion.challenge_id, props.mcqQuestion.assignment_id, props.mcqQuestion.totalGradeCalculationTrial, props.mcqQuestion.totalnonfrqpoints)}>
                                    <div className='boardBtns'>
                                        <img alt='Show Feedback' src={ShowFeedbackIcon} />
                                    </div>
                                    <p className='toolLabel'>Show Feedback</p>
                                </IconButton>
                            </Tooltip>) : null
                        }
                        {
                            parseInt(props.mcqQuestion.totalGradeCalculationTrial) >= props.trials || props.mcqQuestion.is_submitted === 1 || props.isReview ?
                                <Tooltip key={"sol_" + props.mcqQuestion.challenge_id} style={{ flexDirection: 'column' }} title="Show Full Solution" placement='right' arrow>
                                    <IconButton onClick={() => props.showSolution(this, props.mcqQuestion.user_challenge_map_id, props.mcqQuestion.boardId, props.mcqQuestion.assignment_id)}>
                                        <div className='boardBtns'>
                                            <img alt='Show Full Solution' src={ShowFullSolutionIcon} />
                                        </div>
                                        <p className='toolLabel'>Show Full Solution</p>
                                    </IconButton>
                                </Tooltip>
                                :
                                null
                        }
                    </div>
                </div>
                <div className="questionHeader">
                    <div className="questionHeaderText">{
                        props.assignmentType === assignment_Type.homework ? (<>{props.mcqQuestion.challenge_name}</>) : <>{props.mcqQuestion.challenge_display}</>
                    }</div>
                    <div className='questionRightDiv'>
                        {props.mcqQuestion.totalGradeCalculationTrial > 0 ?
                            <div className="gradeCalTrial">
                                # of Attempts : {props.mcqQuestion.totalGradeCalculationTrial}/{props.trials}
                            </div>
                            : null}
                        {props.isGraded ?
                            <div className="points5">
                                {props.mcqQuestion.is_attempted === 1 ?
                                    (
                                        props.isReview ? "Points: " + (props.mcqQuestion.challenge_points !== undefined && props.mcqQuestion.challenge_points !== null ? parseFloat(props.mcqQuestion.challenge_points).toFixed(2) : 0) + "/" +
                                            (props.mcqQuestion.totalnonfrqpoints !== undefined && props.mcqQuestion.totalnonfrqpoints !== null ? parseFloat(props.mcqQuestion.totalnonfrqpoints).toFixed(2) : 0)
                                            :
                                            props.mcqQuestion.is_submitted === 1 ?
                                                (
                                                    "Points: " + (props.mcqQuestion.challenge_points !== undefined && props.mcqQuestion.challenge_points !== null ? parseFloat(props.mcqQuestion.challenge_points).toFixed(2) : 0)
                                                    + "/" + (props.mcqQuestion.totalnonfrqpoints !== undefined && props.mcqQuestion.totalnonfrqpoints !== null ? parseFloat(props.mcqQuestion.totalnonfrqpoints).toFixed(2) : 0)
                                                )
                                                :
                                                (
                                                    "Points: -/" + (props.mcqQuestion.totalnonfrqpoints !== undefined && props.mcqQuestion.totalnonfrqpoints !== null ? parseFloat(props.mcqQuestion.totalnonfrqpoints).toFixed(2) : 0)
                                                )
                                    )
                                    :
                                    (
                                        "Points: -/" + (props.mcqQuestion.totalnonfrqpoints !== undefined && props.mcqQuestion.totalnonfrqpoints !== null ? parseFloat(props.mcqQuestion.totalnonfrqpoints).toFixed(2) : 0)
                                    )
                                }
                            </div>
                            :
                            null
                        }
                        <div className="questionAvatar">
                            <div className="op">{props.mcqQuestion.type}</div>
                            <div className="minWidth" />
                        </div>
                    </div>
                </div>
            </div>

            {
                loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: "#164b99",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )
            }

        </>
    )
}

export default MCQAssignmentDetails
