import React, { Component } from "react";
import Progress from "../../components/Common/Progress/Progress";
import { withRouter } from 'react-router-dom';
import Aux from "../../hoc/Wrapper";
import "./Homepage.scss";
import { Row, Col } from "react-bootstrap";
import Container from "../../hoc/Container";
import InterationText from "../../components/Common/InterationText/InterationText";
import {
  Accordion,
  AccordionItem,
} from "../../components/Common/Accordion/Accordion";
import Recognition from "../../components/Common/Recognition/Recognition";
import SearchInput from "../../components/Homepage/Search/Search";
// import { Link } from 'react-router-dom';
import FeatureIcon from "../../components/Homepage/BottomFeatureIcon/FeatureIcon";
import FeatureDetailBubble, {
  FeatureDetailImageBubble,
} from "../../components/Homepage/BottomFeatureIcon/FeatureDetailBubble/FeatureDetailBubble";
import Modal from "../../components/Common/ModalComponent/ModalComponent";
import ProgressUpdate from "../../components/Homepage/ProgressUpdate/ProgressUpdate";
import { Link } from "react-router-dom";
import {
  fetchSubSectionContent,
  ProgressUpdatescalculation,
  dashboardnodechallenges
} from "../../common/API";
import Box from "@mui/material/Box";
import icon_resume from "../../assets/images/icon_resume.svg";
import icon_progress_update from "../../assets/images/icon_progress-update.svg";
import icon_recognition from "../../assets/images/icon_recognition.svg";
import icon_upcoming_interaction from "../../assets/images/icon_upcoming-interaction.svg";
import img from "../../assets/images/conceptmaprecvised5apr2021v2.png";
import imgWithTryThisIcon from "../../assets/images/tryThisConceptmaprecvised22aug2020v1.png";
import icon_brainteaser_puzzles from "../../assets/images/icon_brainteaser_puzzles.svg";
import icon_interactive_gamesaser_puzzles from "../../assets/images/icon_interactive_games.svg";
import icon_intriguing_experiments from "../../assets/images/icon_intriguing_experiments.svg";
import icon_irresistible_videos from "../../assets/images/icon_irresistible_videos.svg";
import game_1 from "../../assets/images/left-vid-05.png";
import game_2 from "../../assets/images/left-vid-06.png";
import game_3 from "../../assets/images/left-vid-09.png";
import intriguing_experiment from "../../assets/images/left-vid-08.png";
import legendCircle from "../../assets/images/legend-circle.svg";
import puzzle from "../../assets/images/puzzul.svg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import SearchIcon from "@material-ui/icons/Search";
import {
  getCurrentUserId,
  getTryThisPinsEnabledFlag,
  hasAiPlatoUser,
  setDashboardTourCookies,
  convertDurationToSeonds,
  getMenuSelectedItem
} from "../../common/Functions";
import ContentOptionsPopup from "../../components/Common/ContentOptionsPopup/ContentOptionsPopup";
import HomeCurriculumContentCard from "../../components/CurriculumContent/CurriculumContentCard/HomeCurriculumContentCard";
import { Switch } from "@mui/material";
import GraphComponent from "./Graph/GraphComponent";
// import DialogTitle from '@mui/material/DialogTitle';
// import IconButton from '@mui/material/IconButton';
// import CancelIcon from '@material-ui/icons/Cancel';

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import './styles.css';
import Tour from '../Tour/Tour';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ChallengeCard from "./ChallengeCard";
//import BackHandIcon from '@mui/icons-material/BackHand';
import doubleTapImg from "../../assets/images/double-tap.jpg";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    //marginTop: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogTitle-root": {
    fontFamily: "inherit",
    fontSize: "1.15rem",
    paddingTop: "16px",
    paddingBottom: "5px",
    paddingRight: "16px",
    paddingLeft: "16px",
  },

  "& .MuiIconButton-root": {
    padding: "0px",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}


class Homepage extends Component {
  constructor(props) {
    super(props);

    this.updateCurriculumMapPosition =
      this.updateCurriculumMapPosition.bind(this);
    setTimeout(this.updateCurriculumMapPosition, 500);

    this.state = {
      showModal: false,
      showContentOptions: false,
      isContentOptionsLoading: false,
      zoomLevel: 1.0,
      progressproficiency: 0,
      progresscompletion: 0,
      sub_sectionId: null,
      contentdata: [],
      challengeData: [],
      othercontentdata: [],
      sidebarheightpix: "0px",
      sidebartop: 0,
      expanded: false,
      contentloader: false,
      challengeLoader: false,
      peerscompletion: 0,
      games: [
        {
          link: "https://www.walter-fendt.de/html5/phde/newtonlaw2_de.htm",
          image: game_1,
          title: "Newton's 2nd Law Experiment",
        },
        {
          link: "https://www.walter-fendt.de/html5/phde/inclinedplane_de.htm",
          image: game_2,
          title: "Forces on the inclined plane",
        },
        {
          link: "https://phet.colorado.edu/sims/html/forces-and-motion-basics/latest/forces-and-motion-basics_en.html",
          image: game_3,
          title: "Forces and Motion",
        },
      ],
      videos: null,
      upcomingInteraction: [
        {
          time: "Tue, 3 pm",
          group_tutor: 'Study Group: "Skywalker"',
          topic: "Topic: Newton's 2nd Law",
          featured: true,
        },
        {
          time: "Tue, 5 pm",
          group_tutor: "Tutor: John K.",
          topic: "Topic: Kinetic Energy",
          featured: true,
        },
        {
          time: "Wed, 6 pm",
          group_tutor: 'Study Group: "Skywalker"',
          topic: "Topic: Newton's 3rd Law",
        },
      ],
      isSearchBarCollapsed: true,
      contentOptionsPosition: {
        top: 0,
        left: 0,
      },
      hasBasicView: false,
      tabValue: "content",
      NodeNotesclass: 'nodeNoteAnimation'
    };
  }



  componentWillMount() {
    this.setState({
      videos: [
        {
          clicked: this.openModal,
          image:
            "https://i.ytimg.com/vi/4a0FbQdH3dY/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAYABAQ==&rs=AOn4CLBPhtVxTHC0X4oTWiXqMXcvQx2VYw",
          title: "For the Love of Physics",
          videoUrl: "https://www.youtube.com/embed/4a0FbQdH3dY",
        },
        {
          clicked: this.openModal,
          image:
            "https://i.ytimg.com/vi/xdIjYBtnvZU/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAYABAQ==&rs=AOn4CLBxq7GcboGxeGMNWJ1eTlHrvNfkjQ",
          title: "Feynman's Lost Lecture",
          videoUrl: "https://www.youtube.com/embed/xdIjYBtnvZU",
        },
        {
          clicked: this.openModal,
          image: "https://i3.ytimg.com/vi/3JH1wCKlR5s/maxresdefault.jpg",
          title: "Science Bob Pflugfelder's Science",
          videoUrl: "https://www.youtube.com/embed/3JH1wCKlR5s",
        },
        {
          clicked: this.openModal,
          image:
            "https://i.ytimg.com/vi/Zo62S0ulqhA/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAYABAQ==&rs=AOn4CLAMIfRZ2k3UmgiuwPBWULk9lgNzqQ",
          title: "What I learned from going blind in space?",
          videoUrl: "https://www.youtube.com/embed/Zo62S0ulqhA",
        },
        {
          clicked: this.openModal,
          image:
            "https://i.ytimg.com/vi/WaGc_Wkb1dk/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAYABAQ==&rs=AOn4CLC2SEjG0bH03PZ_8lbnUqhvcEIhfQ",
          title: "For the Love of Physics || Walter Lewin's Last",
          videoUrl: "https://www.youtube.com/embed/WaGc_Wkb1dk",
        },
      ],
    });
  }

  componentDidMount() {
    this.setState({ run: true })

    setTimeout(() => {
      const reqData = {
        params: {
          user_id: getCurrentUserId(),
          course_id: getMenuSelectedItem()
        },
      };
      ProgressUpdatescalculation(reqData).then((res) => {
        this.setState({
          progressproficiency: res.data.progressupdates.proficiency,
          progresscompletion: res.data.progressupdates.completion,
          peerscompletion: res.data.progressupdates.peerscompletion,
        });
      });

    }, 1000);


    window.addEventListener("load", this.updateCurriculumMapPosition);

    let clicking = false;
    let previousX;
    let previousY;

    window.$("#scroll").mousedown(function (e) {
      e.preventDefault();
      previousX = e.clientX;
      previousY = e.clientY;
      clicking = true;
    });

    window.$(document).mouseup(function () {
      clicking = false;
    });

    window.$("#scroll").mousemove(function (e) {
      if (clicking) {
        e.preventDefault();
        var directionX = previousX - e.clientX > 0 ? 1 : -1;
        var directionY = previousY - e.clientY > 0 ? 1 : -1;
        //$("#scroll").scrollLeft($("#scroll").scrollLeft() + 10 * directionX);
        //$("#scroll").scrollTop($("#scroll").scrollTop() + 10 * directionY);
        window
          .$("#scroll")
          .scrollLeft(
            window.$("#scroll").scrollLeft() + (previousX - e.clientX)
          );
        window
          .$("#scroll")
          .scrollTop(window.$("#scroll").scrollTop() + (previousY - e.clientY));
        previousX = e.clientX;
        previousY = e.clientY;
      }
    });

    window.$("#scroll").mouseleave(function (e) {
      clicking = false;
    });

    // To handle touch on curriculum graph
    window.$("#scroll").on("touchstart", function (e) {
      // e.preventDefault();
      previousX = e.clientX;
      previousY = e.clientY;
      clicking = true;
    });

    window.$(document).on("touchend", function () {
      clicking = false;
    });

    window.$("#scroll").on("touchmove", function (e) {
      if (clicking) {
        e.preventDefault();
        var touch =
          e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
        var directionX = previousX - touch.pageX > 0 ? 1 : -1;
        var directionY = previousY - touch.pageY > 0 ? 1 : -1;
        window
          .$("#scroll")
          .scrollLeft(window.$("#scroll").scrollLeft() + 10 * directionX);
        window
          .$("#scroll")
          .scrollTop(window.$("#scroll").scrollTop() + 10 * directionY);
        // window.$("#scroll").scrollLeft(window.$("#scroll").scrollLeft() + (previousX - touch.pageX));
        // window.$("#scroll").scrollTop(window.$("#scroll").scrollTop() + (previousY - touch.pageY));
        previousX = touch.pageX;
        previousY = touch.pageY;
      }
    });

    window.$("#scroll").on("touchend", function (e) {
      clicking = false;
    });

    setTimeout(() => {
      this.setState({ NodeNotesclass: 'nodeNote' })
    }, 8000);
  }

  componentWillUnmount() {
    window.removeEventListener("load", this.updateCurriculumMapPosition);
  }


  handleTabChange = (newValue) => {
    this.setState({ tabValue: newValue })
  }

  handletabchange = (newValue) => {
    this.setState({ tabValue: "content" })
  }

  updateCurriculumMapPosition() {
    window.$("#scroll").scrollLeft(730);
    window.$("#scroll").scrollTop(570);
  }

  modalBody = null;
  modalTitle = null;
  showButton = false;

  openModal = (event, modalTitle, modalBody, showButton = false) => {
    event.preventDefault();
    this.modalBody = modalBody;
    this.modalTitle = modalTitle;
    this.showButton = showButton;
    this.setState({ showModal: true });
  };

  openContentOptions = (
    event,
    modalBody,
    position,
    isContentOptionsLoading
  ) => {
    // event.preventDefault();
    this.modalBody = modalBody;
    this.setState({
      showContentOptions: true,
      isContentOptionsLoading: isContentOptionsLoading,
      contentOptionsPosition: position,
    });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  generateProgressBar = (ctx, network) => {
    for (let node of this.state.graph.nodes) {
      let nodeBound = network.getBoundingBox(node.id);
      let coordinates = network.getPosition(node.id);
      let posLeft = nodeBound.left > 0 ? nodeBound.left : nodeBound.left * -1;
      let posRight =
        nodeBound.right > 0 ? nodeBound.right : nodeBound.right * -1;
      // let radius = posRight - posLeft;
      let x = coordinates.x > 0 ? coordinates.x : coordinates.x * -1;
      let radius1 = x - posLeft;
      let radius2 = x - posRight;
      radius1 = radius1 > 0 ? radius1 : radius1 * -1;
      radius2 = radius2 > 0 ? radius2 : radius2 * -1;

      // outer progress
      let progress1 = (node.completion * 2) / 100;
      ctx.beginPath();
      ctx.lineWidth = 3;
      ctx.arc(
        coordinates.x,
        coordinates.y,
        radius1 > radius2 ? radius1 - 5 : radius2 - 5,
        (0 + 1.5) * Math.PI,
        (progress1 + 1.5) * Math.PI
      );
      ctx.strokeStyle = "#5e7df7";
      ctx.stroke();

      let progress2 = (node.mastery * 2) / 100;
      // inner progress
      ctx.beginPath();
      ctx.lineWidth = 3;
      ctx.arc(
        coordinates.x,
        coordinates.y,
        radius1 > radius2 ? radius1 - 11 : radius2 - 11,
        (0 + 1.5) * Math.PI,
        (progress2 + 1.5) * Math.PI
      );
      ctx.strokeStyle = "#f00";
      ctx.stroke();
    }
  };

  changeZoom = (zoomType) => {
    let newZoomLevel =
      zoomType === "zoomIn"
        ? this.state.zoomLevel + 0.1
        : this.state.zoomLevel - 0.1;
    newZoomLevel = Number(newZoomLevel.toFixed(1));
    if (!(newZoomLevel > 2 || newZoomLevel < 1)) {
      this.setState({ zoomLevel: newZoomLevel });
    }
  };

  getContentOptionsJSX = (data) => {
    return (
      <div className="content-options">
        <HomeCurriculumContentCard
          title="Recommended Content for Your Cognitive Profile"
          data={[data[0]]}
          linkStateObj={{ prevPath: ["/"], prevNode: ["Dashboard"] }}
        />
        {data.length > 1 ? (
          <HomeCurriculumContentCard
            title="Other Curated Content Options"
            data={data.splice(1)}
            linkStateObj={{ prevPath: ["/"], prevNode: ["Dashboard"] }}
          />
        ) : null}
      </div>
    );
  };

  closesidebar = () => {
    this.setState({ togglesidebar: false });
  };

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
    //setExpanded(!expanded);
  };

  openContentOptionsModal = (e, subSectionId, type = null, hasPosition = false) => {
    //alert(type)
    this.setState({
      contentloader: true,
      togglesidebar: true,
      othercontentdata: [],
      contentdata: [],
    });
    if (subSectionId === null) {
      this.setState({ togglesidebar: false });
    } else {
      let position = { top: 0, left: 0 };
      if (!hasPosition) {
        let isTouchEvent = false;
        if (e.nativeEvent instanceof TouchEvent) {
          isTouchEvent = true;
        }

        let topPosition = e.pageY;
        let leftPosition = e.pageX - 50;
        if (isTouchEvent) {
          var touch = e.changedTouches[0];
          topPosition = touch.pageY;
          leftPosition = touch.pageX - 50;
        }
        position = {
          top: topPosition,
          left: leftPosition,
        };
      } else {
        position = {
          top: e.x,
          left: e.y,
        };
      }
      const reqData = { params: { subSectionId: subSectionId, nodetype: type } };
      fetchSubSectionContent(reqData)
        .then((res) => {
          debugger;
          if (res.data.content.length > 0) {
            this.setState({
              sub_sectionId: subSectionId,
              contentdata: res.data.content,
              contentloader: false

            });
            if (res.data.content.length > 1) {
              this.setState({ othercontentdata: res.data.content.splice(1) });
            } else {
              this.setState({ othercontentdata: [] });
            }
          }
        })
        .catch((err) => {
          this.setState({ contentloader: false })
          console.error(err.message);
        });

      this.setState({ challengeLoader: true })
      const reqData1 = { params: { id: subSectionId, user_id: getCurrentUserId(), ch_type: type } };
      dashboardnodechallenges(reqData1)
        .then((res) => {
          // debugger;
          if (res.data !== undefined && res.data !== null && res.data.length > 0) {

            this.setState({
              sub_sectionId: subSectionId,
              challengeData: res.data,
              challengeLoader: false,
            });
          }
          else {
            this.setState({
              sub_sectionId: subSectionId,
              challengeData: [],
              challengeLoader: false,
            });
          }
        })
        .catch((err) => {
          this.setState({ challengeLoader: false })
          console.error(err.message);
        });
    }
  };
  searchBarCollapseToggle = () => {
    this.setState({ isSearchBarCollapsed: !this.state.isSearchBarCollapsed });
  };


  render() {
    const upcomingInteractionModelContent = (
      <div style={{ width: "600px" }}>
        <Row>
          <Col sm={12} className="my-2">
            {this.state.upcomingInteraction.map((interaction) => (
              <p className="font-16">
                {interaction["time"]} {interaction["group_tutor"]}{" "}
                {interaction["topic"]}
              </p>
            ))}
          </Col>
        </Row>
        <Row>
          <span className="sample-data-label text-right flex-grow-1">
            * Sample Data
          </span>
        </Row>
      </div>
    );

    const progressUpdateModelContent = (
      <div style={{ width: "600px" }}>
        <Row className="border-bottom">
          <Col sm={6} className="my-3">
            <ProgressUpdate
              percentage={`${this.state.progresscompletion}` + "%"}
              text="Completion Level"
            />
          </Col>
          <Col sm={6} className="my-3">
            <ProgressUpdate
              percentage={`${this.state.progressproficiency}` + "%"}
              text="Proficiency Level"
              danger
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} className="my-3">
            <ProgressUpdate
              percentage="54%"
              text="of Curriculum Topics Mastered"
              danger
            />
          </Col>
          <Col sm={6} className="my-3">
            <ProgressUpdate
              percentage="42%"
              text="of Curriculum Topics Partially Mastered"
              danger
            />
          </Col>
        </Row>
        <Row>
          <span className="sample-data-label text-right flex-grow-1">
            * Sample Data
          </span>
        </Row>
      </div>
    );
    return (
      <div className="demo-wrapper dashboard_tour">
        <Tour pagename="dashboard" />

        <Container className="dashboard-container">
          <Card style={{ borderRadius: "0px" }}>
            <div style={{ display: "flex" }}>
              <CardHeader
                style={{
                  width: "25%",
                  borderRight: "1px solid #ccc",
                  padding: "5px 16px",
                  cursor: "pointer",
                }}
                onClick={this.handleExpandClick}
                avatar={
                  <Avatar
                    aria-label="Resume"
                    src={icon_resume}
                    style={{ width: "40px", height: "fit-content" }}
                  ></Avatar>
                }
                title="Resume"
              />
              <CardHeader
                className="product_tour"
                style={{
                  width: "25%",
                  borderRight: "1px solid #ccc",
                  padding: "5px 16px",
                  cursor: "pointer",
                }}
                onClick={this.handleExpandClick}
                avatar={
                  <Avatar
                    aria-label="Progress Update"
                    src={icon_progress_update}
                    style={{ width: "40px", height: "fit-content" }}
                  ></Avatar>
                }
                title="Progress Update"
              />
              <CardHeader
                style={{
                  width: "25%",
                  borderRight: "1px solid #ccc",
                  padding: "5px 16px",
                  cursor: "pointer",
                }}
                onClick={this.handleExpandClick}
                avatar={
                  <Avatar
                    aria-label="Recognition"
                    src={icon_recognition}
                    style={{ width: "40px", height: "fit-content" }}
                  ></Avatar>
                }
                title="Recognition"
              />
              <CardHeader
                style={{ width: "25%", padding: "5px 16px", cursor: "pointer" }}
                onClick={this.handleExpandClick}
                avatar={
                  <Avatar
                    aria-label="Upcoming Interactions"
                    src={icon_upcoming_interaction}
                    style={{ width: "40px", height: "fit-content" }}
                  ></Avatar>
                }
                title="Upcoming Interactions"
              />
            </div>
            <CardActions disableSpacing style={{ padding: "0" }}>
              <div
                className="expandmore"
                style={this.state.expanded ? { top: "266px" } : { top: "42px" }}
              >
                <ExpandMore
                  expand={this.state.expanded}
                  onClick={this.handleExpandClick}
                  aria-expanded={this.state.expanded}
                  aria-label="show more"

                //style={{padding:"0px"}}
                >
                  <ExpandMoreIcon style={{ width: "1em", height: "1em" }} />
                </ExpandMore>
              </div>
            </CardActions>
            <Collapse
              in={this.state.expanded}
              timeout="auto"
              unmountOnExit
            //style={{position:"absolute", zIndex:"1300", top:"218px", width:"93.75%", background:"#fff"}}
            >
              <div className="expandablecardsdiv">
                <CardContent
                  style={{ width: "25%", borderRight: "1px solid #ccc" }}
                >
                  <Accordion
                    style={{ marginBottom: "10px", overflowY: "hidden" }}
                  >
                    <AccordionItem
                      className="font-14"
                      title="Ongoing Curiosity Exploration:"
                    >
                      <Link to="/curiosity-exploration">
                        <p className="font-14">How does a plane fly?</p>
                      </Link>
                      <p className="font-14">
                        How is Physics related to Soccer?
                      </p>
                    </AccordionItem>
                    <AccordionItem
                      className="font-14"
                      title="Ongoing Curriculum Topic:"
                    >
                      <Link className="font-14" to="/curriculum-content/16">
                        <p className="font-14">Newton's 2nd Law</p>
                      </Link>
                      <Link to="/curriculum-content/20">
                        <p className="font-14">Newton's 3rd Law</p>
                      </Link>
                    </AccordionItem>
                  </Accordion>
                </CardContent>
                <CardContent
                  style={{ width: "25%", borderRight: "1px solid #ccc" }}
                >
                  <div >
                    <Progress
                      clicked={(event) =>
                        this.openModal(
                          event,
                          "Progress Update",
                          progressUpdateModelContent,
                          true
                        )
                      }

                    />
                  </div>
                </CardContent>
                <CardContent
                  style={{ width: "25%", borderRight: "1px solid #ccc" }}
                >
                  <div style={{ display: "flex" }} className="recognition_tour">
                    <Recognition
                      count={17}
                      text="Curiosities Explored"
                      reviewer="Top 25%"
                    />
                    <Recognition
                      count={32}
                      text="Content Reviews Written"
                      reviewer="Top 10%"
                    />
                    <Recognition count={29} text="Peer Questions Answered" />
                  </div>
                </CardContent>
                <CardContent style={{ width: "25%" }}>
                  <div
                    className="upcomming-content"
                    onClick={(event) =>
                      this.openModal(
                        event,
                        "Upcoming Interactions",
                        upcomingInteractionModelContent,
                        true
                      )
                    }
                  >
                    {this.state.upcomingInteraction.map((interaction) => {
                      let interactiveTxt = null;
                      if (interaction["featured"]) {
                        interactiveTxt = (
                          <InterationText
                            day={interaction["time"]}
                            study={interaction["group_tutor"]}
                            topic={interaction["topic"]}
                          />
                        );
                      }
                      return interactiveTxt;
                    })}
                  </div>
                </CardContent>
              </div>
            </Collapse>
          </Card>
        </Container>
        <div className="middle-section">
          <div className="img-bg" id="scroll">
            {this.state.hasBasicView ? (
              <div style={{ width: "100%", height: "100%" }}>
                <img
                  onClick={(e) => this.openContentOptionsModal(e, null)}
                  src={getTryThisPinsEnabledFlag() ? imgWithTryThisIcon : img}
                  className="graph-image"
                  usemap="#image_map"
                  style={{ transform: `scale(${this.state.zoomLevel})` }}
                />
                <map name="image_map">
                  <area
                    //onMouseEnter={(e) => !this.state.isContentOptionsLoading ? this.openContentOptionsModal(e, 924) : null}
                    onClick={(e) => this.openContentOptionsModal(e, 924)}
                    alt="Newton's 1st Law"
                    title="Newton's 1st Law"
                    coords="928,760,49"
                    shape="circle"
                  />
                  <area
                    //onMouseEnter={(e) => !this.state.isContentOptionsoading ? this.openContentOptionsModal(e, 847) : null}
                    onClick={(e) => this.openContentOptionsModal(e, 847)}
                    alt="Newton's 2nd Law"
                    title="Newton's 2nd Law"
                    coords="1124,869,62"
                    shape="circle"
                  />
                  <area
                    //onMouseEnter={(e) => !this.state.isContentOptionsLoading ? this.openContentOptionsModal(e, 923) : null}
                    onClick={(e) => this.openContentOptionsModal(e, 923)}
                    alt="Newton's 3rd Law"
                    title="Newton's 3rd Law"
                    coords="868,955,57"
                    shape="circle"
                  />
                  <area
                    //onMouseEnter={(e) => !this.state.isContentOptionsLoading ? this.openContentOptionsModal(e, 882) : null}
                    onClick={(e) => this.openContentOptionsModal(e, 882)}
                    alt="Gravitation Near Earth"
                    title="Gravitation Near Earth"
                    coords="1685,1113,78"
                    shape="circle"
                  />
                  <area
                    //onMouseEnter={(e) => !this.state.isContentOptionsLoading ? this.openContentOptionsModal(e, 882) : null}
                    onClick={(e) => this.openContentOptionsModal(e, 882)}
                    alt="Newton's Law of Gravitation"
                    title="Newton's Law of Gravitation"
                    coords="1895,815,57"
                    shape="circle"
                  />
                </map>
              </div>
            ) : (
              <div
                className="graph_tour"
                style={{ width: "100%", height: "100%", position: "relative" }}
              >
                {/* <GraphApp openContentOptionsModal={this.openContentOptionsModal}></GraphApp> */}
                {/* <ReactFlowWrapper {...this.initialValues}></ReactFlowWrapper> */}
                <GraphComponent
                  componentId="homePage"
                  isauthordashboard={false}
                  openContentOptionsModal={this.openContentOptionsModal}
                  handletabchange={this.handletabchange}
                  isPT={false}
                ></GraphComponent>
              </div>
            )}
          </div>

          <div style={{ display: 'none' }}
            className={`search-content ${!this.state.isSearchBarCollapsed
              ? "show-every-search-content"
              : ""
              }`}
          >
            <div className="search-result visible-search-component d-flex align-items-center justify-content-around ">
              <span style={{ cursor: 'pointer' }} onClick={this.searchBarCollapseToggle}>
                Engage Your Curiosity: Recommended Questions/Explorations
              </span>
              <div className="d-flex justify-content-around flex-grow-1">
                {this.state.isSearchBarCollapsed ? (
                  <ExpandMoreIcon onClick={this.searchBarCollapseToggle} />
                ) : (
                  <ExpandLessIcon onClick={this.searchBarCollapseToggle} />
                )}
              </div>
            </div>
            <SearchInput mxAuto />
          </div>
          {/* <div className='zoom-buttons d-flex flex-column'>
                      <button className='zoom-in' onClick={() => this.changeZoom('zoomIn')}></button>
                      <button className='zoom-out' onClick={() => this.changeZoom('zoomOut')}></button>
                  </div> */}

          {!this.state.hasBasicView ? (
            <div className="dotted-line-legend">
              <div className="legendName">
                <span className="white-dotted line-width"></span>{" "}
                <span>Chapter - Sections</span>
              </div>
              <div className="legendName">
                <span className="black-dotted line-width"></span>{" "}
                <span>Prerequisite Links</span>
              </div>
              <div className="legendName">
                <span className="blue-dotted line-width"></span>{" "}
                <span>Path to Selected Node</span>
              </div>
            </div>
          ) : null}

          <div className="graph-legend">
            <img src={legendCircle} />
            <div>
              <div className="legend-completed">
                <span>% Completion</span>
              </div>
              <div className="legend-mastery">
                {" "}
                <span>% Proficiency</span>
              </div>
            </div>
          </div>
          {/* <div className="toggleGraphView">
              <span>
                {this.state.hasBasicView
                  ? "Switch to Knowledge Graph View"
                  : "Switch to Proficiency Graph View"}
              </span>
              <Switch
                defaultChecked
                size="small"
                color="default"
                onChange={(e, c) =>
                  this.setState({ hasBasicView: !this.state.hasBasicView })
                }
              />
            </div> */}
          <div className={this.state.NodeNotesclass}>
            <img height="21px" width="21px" alt="" src={doubleTapImg} />
            Double-click on any section to view its content.
          </div>
        </div>
        {/* Bottom hover cards */}
        <Container>
          <Row className="bottomIconContainer">
            <Col sx={4}>
              <FeatureIcon
                text="Interactive Simulations"
                title="Interactive Simulations"
                image={icon_interactive_gamesaser_puzzles}
              >
                <FeatureDetailBubble games data={this.state.games} />
              </FeatureIcon>
            </Col>
            <Col sx={4}>
              <FeatureIcon
                text="Intriguing Experiments"
                textClassName="disabled-link"
                title="Intriguing Experiments"
                image={icon_intriguing_experiments}
              >
                <FeatureDetailImageBubble
                  title="How can you aim at this falling can?"
                  image={intriguing_experiment}
                />
              </FeatureIcon>
            </Col>
            {/* <Col lg={3} md={6} sm={6}>
              <FeatureIcon
                text="Brainteaser Puzzles"
                textClassName="disabled-link"
                title="Brainteaser Puzzles"
                image={icon_brainteaser_puzzles}
              >
                <FeatureDetailImageBubble title="Puzzle" image={puzzle} />
              </FeatureIcon>
            </Col> */}
            <Col sx={4} >
              <FeatureIcon
                text="Fascinating Videos"
                title="Fascinating Videos"
                image={icon_irresistible_videos}
              >
                <FeatureDetailBubble videos data={this.state.videos} />
              </FeatureIcon>
            </Col>
          </Row>
        </Container>
        <Modal
          show={this.state.showModal}
          handleClose={this.closeModal}
          title={this.modalTitle}
          body={this.modalBody}
          showButton={this.showButton}
        />

        <ContentOptionsPopup
          showContentOptions={this.state.showContentOptions}
          handleClose={() => this.setState({ showContentOptions: false })}
          body={this.modalBody}
          contentOptionsPosition={this.state.contentOptionsPosition}
        />

        <BootstrapDialog
          className="rightPopup"
          onClose={this.closesidebar}
          aria-labelledby="customized-dialog-title"
          open={this.state.togglesidebar}
          TransitionComponent={Transition}
          hideBackdrop={true}
          disableEnforceFocus={true}
          style={{ position: "initial" }}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={this.closesidebar}
          >
            Recommended Content for Your Cognitive Profile
          </BootstrapDialogTitle>
          <DialogContent>
            <Box sx={{ width: 450 }}>
              <div className="sidebar-right-fix pb-0 position-relative">
                <div className="content-options">
                  {/* <Tabs activeKey={this.state.tabValue} onSelect={this.handleTabChange} className="tabs ">
                    <Tab eventKey="content" title="Interactive Content"> */}
                  {this.state.contentdata.length > 0 ? (
                    <HomeCurriculumContentCard
                      title={null}
                      data={[this.state.contentdata[0]]}
                      contentloader={this.state.contentloader}
                      linkStateObj={{
                        prevPath: ["/"],
                        prevNode: ["Dashboard"],
                      }}
                    />
                  ) : (
                    <HomeCurriculumContentCard
                      title={null}
                      data={this.state.contentdata}
                      contentloader={this.state.contentloader}
                      linkStateObj={{
                        prevPath: ["/"],
                        prevNode: ["Dashboard"],
                      }}
                    />
                  )}
                  {this.state.othercontentdata.length > 0 ? (
                    <div style={{ marginTop: "25px" }}>
                      <HomeCurriculumContentCard
                        title="Other Curated Content Options"
                        data={this.state.othercontentdata}
                        contentloader={false}
                        linkStateObj={{
                          prevPath: ["/"],
                          prevNode: ["Dashboard"],
                        }}
                      />
                    </div>
                  ) : null}

                  {/* </Tab>
                    <Tab eventKey="problems" title="Interactive Problems"> */}
                  <h4 className='title' style={{ marginTop: "30px" }}>Interactive Problems</h4>
                  {
                    this.state.challengeData !== undefined && this.state.challengeData !== null && this.state.challengeData.length > 0 ?
                      (
                        <>
                          <ChallengeCard
                            title="Interactive Problems"
                            data={this.state.challengeData}
                            type={'dashboard'}
                            challengeLoader={this.state.challengeLoader}>
                          </ChallengeCard>
                        </>
                      ) : (
                        <Card
                          style={{
                            position: 'relative', marginTop: "20px", padding: "50px"
                          }}>No interactive problems available for this currently</Card>
                      )

                  }

                  {/* </Tab>
                  </Tabs> */}


                </div>
              </div>
            </Box>
          </DialogContent>
        </BootstrapDialog>


      </div>
    );
  }
}

export default withRouter(Homepage);
