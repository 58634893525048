import React, { Component } from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { Container, Row, Col, Modal } from "react-bootstrap";
import "./CurriculumContent.scss";
import ChallengeStudent from "../../components/CurriculumContent/ChallengeStudents/ChallengeStudents";
import VideoThumbnail from "../../components/CurriculumContent/VideoThumbnail/VideoThumbnail";
import MainContent from "../../components/CurriculumContent/MainContent/MainContent";
import Carousel from "../../components/CurriculumContent/Carousel/Carousel";
import ProgressImage from "../../components/CurriculumContent/ProgressImage/ProgressImage";
import Modal1 from "../../components/Common/ModalComponent/ModalComponent";
import BookOutlinedIcon from "@material-ui/icons/BookOutlined";
import {
  saveNote,
  saveBookmark,
  fetchBookmark,
  saveHighlight,
  fetchHighlight,
  updateHighlight,
  deleteHighlight,
  fetchHint,
  fetchInstructionalContent,
  fetchContentEnhancement,
  fetchSubSectionContent,
  getuserroles,
  updatedemouserdetail,
  getdemouserdetail,
  savedemouserdetail,
  getsubscriptionlimiteduserdetail,
  savesubscriptionlimiteduserdetail
} from "../../common/API";
import { ToastContainer, toast } from "react-toastify";
import { uniqBy } from "lodash";
import "react-toastify/dist/ReactToastify.css";
import { FaBars } from "react-icons/fa";
import pageData from "../../data/CurriculumContent.json";
import queryString from "query-string";
import icNote from "../../assets/images/Combined_Shape.svg";
import icHighlightText from "../../assets/images/icon_highlight_text.svg";
import TakeNotes from "../../components/CurriculumContent/TakeNotes/TakeNotes";
import HighlightText from "../../components/CurriculumContent/HighlightText/HighlightText";
import Button from "../../components/Common/Button/Button";
import { withNamespaces } from "react-i18next";
import i18next from "i18next";
import TextbookHint from "../../components/CurriculumContent/TextbookHint/TextbookHint";
import {
  getRoutes,
  getCurrentUserId,
  Call_Type,
  User_Role,
  getCurrentUserRole, getUserFingerprint, isDemoUser,
  detectDevice,
  TABLET,
  getuserAccess
  , CType
  , user_plan
} from "../../common/Functions";
import Spinner from "react-bootstrap/Spinner";
import CurriculumContentCard from "../../components/CurriculumContent/CurriculumContentCard/CurriculumContentCard";
import VideoConferenceSideBar from "../../components/VideoConferenceSideBar/VideoConferenceSideBar";
import UpcomingInteractions from "../../components/CurriculumContent/MainContent/VideoContent/UpcomingInteractions/UpcomingInteractions";
import CardCarousel from "../../components/CurriculumContent/Carousel/CardCarousel";
import {
  subscribeWithoutTeacherAccessIC, subscribeWithoutTeacherAccessWop, subscribeWithoutTeacherAccessIP, bothExceedMessage
  , problemsExceedMessage, contentExceedMessage, interactiveProblemExceedLimit, interactiveContentExceedLimit,
  LimitedAccessbothExceedMessage,
  LimitedAccesscontentExceedMessage
} from '../../common/Constants';
import { isNil, isEmpty } from 'lodash';




class CurriculumContent extends Component {
  state = {
    showModal: false,
    // videos: pageData.videos,
    contentEnhancement: null,
    challenges: [],
    students: pageData.students[this.props.match.params.topicId]
      ? pageData.students[this.props.match.params.topicId]
      : pageData.students[1],
    mainTopic: null,
    topic: pageData.topic[this.props.match.params.topicId],
    recommendations: "",
    topicRecommendations: "",
    imageCarousel2: pageData.imageCarousel2,
    progressImage: pageData.progressImage,
    notesModalToggle: false,
    showHighlighter: false,
    showHint: false,
    highlightedText: "",
    editHighlight: {},
    highlightedObject: null,
    hintObject: null,
    highlightedObjectList: [],
    hintObjectList: [],
    highlighterPosition: {
      top: 0,
      left: 0,
    },
    hintPosition: {
      top: 0,
      left: 0,
    },
    textHighlighterEnabled: true,
    toggleMenu: true,
    toggled: false,
    toggleVideoConfShowModal: false,
    raisebuttondisabled: false,
    isTeacher: false,
    questionId: null,
    iseditablecontent: false,
    demoExceedModalOpen: false,
    problemsolvedCount: 0,
    isLimitExceed: false,
    useruniquecode: null,
    subscriptionlimitedaccessOpenModal: false,

    problemsolvedforsubscriptionlimitedaccess: 0,
    wopforsubscriptionlimitedaccess: 0
  };

  right_panel_classes = "col-lg-4 col-md-7 right-container open";
  right_panel_arrow = "arrow right";
  toggleContainerclass = "col-lg-4 col-md-4 whitebackground";
  togglecontainer = "toggleContainer";
  toggleContainer1 = "toggleContainer1";
  left_panel_classes = "col-lg-8 col-md-8 left-container";
  videoclass = "";

  componentDidMount() {
    let aplan = getuserAccess()
    debugger;
    if (aplan !== undefined && aplan !== null && aplan !== "") {
      if (aplan === user_plan.limitedaccess) {
        if (!isNil(this.props.location.state["prevPath"]) && !isEmpty(this.props.location.state["prevPath"])) {
          if (this.props.location.state["prevPath"].toString().split('#')[1] === "Video") {
            if (this.props.location.state["prevNode"][0] === "Learning Content") {
              // const client = new ClientJS();
              // const fingerprint = client.getFingerprint();

              const reqData = { params: { 'user_id': getCurrentUserId() } }
              getsubscriptionlimiteduserdetail(reqData).then(res => {
                debugger;
                if (res.status === 200) {
                  if (!isNil(res.data)) {
                    if (res.data.totalcontentcount >= subscribeWithoutTeacherAccessIC) {
                      this.maincontainerclass = this.maincontainerclass + " disabledDiv "
                      this.setState({
                        isLimitExceed: true
                        , subscriptionlimitedaccessOpenModal: true
                        , problemsolvedforsubscriptionlimitedaccess: res.data.totalproblemscount
                        , wopforsubscriptionlimitedaccess: res.data.totalwopcount
                      })
                    }
                    else {
                      const reqData = {
                        user_id: getCurrentUserId(),
                        ctype: CType.content,
                        challenge_content_id: this.props.match.params.topicId,
                        accesstype: user_plan.limitedaccess
                      }
                      savesubscriptionlimiteduserdetail(reqData)
                        .then(res => {
                        })
                        .catch(err => {
                          console.error(err.message);
                        })
                    }
                  }
                }
              })
            }
          }
        }
      }

    }

    if (isDemoUser() === "true") {

      if (!isNil(this.props.location.state["prevPath"]) && !isEmpty(this.props.location.state["prevPath"])) {
        if (this.props.location.state["prevPath"].toString().split('#')[1] === "Video") {
          if (this.props.location.state["prevNode"][0] === "Learning Content") {
            // const client = new ClientJS();
            // const fingerprint = client.getFingerprint();
            const fingerprint = getUserFingerprint()
            this.setState({ useruniquecode: fingerprint })
            const reqData = { params: { 'useruniquecode': fingerprint } }
            getdemouserdetail(reqData).then(res => {
              if (res.status === 200) {
                if (!isNil(res.data)) {
                  if (res.data.totalcontentcount >= interactiveContentExceedLimit) {
                    this.maincontainerclass = this.maincontainerclass + " disabledDiv "
                    this.setState({ isLimitExceed: true, demoExceedModalOpen: true, problemsolvedCount: res.data.totalproblemscount })
                  }
                  else {
                    const reqData = {
                      useruniquecode: fingerprint,
                      iscontent: 1,
                      isany: 0,
                      challenge_content_id: this.props.match.params.topicId
                    }
                    savedemouserdetail(reqData)
                      .then(res => {
                      })
                      .catch(err => {
                        console.error(err.message);
                      })
                  }
                }
              }
            })
          }
        }
      }
    }


    window.scrollBy(0, 200);
    this.requestContentInformation(this.props.match.params.topicId);
    let isTeacher =
      this.props.match.params.questionId === "teacher" ? true : false;
    const { hasCustomQuestion } = queryString.parse(window.location.search);
    this.setState({ isTeacher: isTeacher });
    if (hasCustomQuestion !== undefined && hasCustomQuestion !== null) {
      this.setState({ toggleVideoConfShowModal: true });
      this.toggleContainerclass = "col-lg-3 col-md-7 whitebackground";
      this.left_panel_classes = "col-lg-5 col-md-12 left-container";
      this.videoclass = "col-lg-4";
    }
    // if (getCurrentUserRole() === User_Role.Student) {
    //     const url = new URL(window.location.href);
    //     const param = url.searchParams.get('ques');
    //     if (param !== undefined && param !== null) {
    //         const url1 = `/curriculum-content/${this.props.match.params.topicId}`;
    //         this.props.history.push({ pathname: `${url1}` });
    //     }
    // }

  }

  componentWillReceiveProps(newProps) {
    // This will called when clicked on the link from same url but with different params (From curriculum graph of curriculum content)
    this.closeModal();
    this.requestContentInformation(newProps.match.params.topicId);
    this.setState({
      students: pageData.students[newProps.match.params.topicId]
        ? pageData.students[newProps.match.params.topicId]
        : pageData.students[1],
    });
  }

  handledemoExceedModal = () => {
    this.setState({ demoExceedModalOpen: false })
  }

  handleSubscriptionLimitedAccessxceedModal = () => {
    this.setState({ subscriptionlimitedaccess: false })
  }

  toggleVideoConfModal = (data) => {
    // this.toggleContainerclass = 'col-lg-3 col-md-7 whitebackground mt-1';
    // this.left_panel_classes = 'col-lg-5 col-md-12 left-container';
    // this.videoclass = 'col-lg-4'
    // let previousstate = null
    // this.setState((prevState) => {
    //     previousstate = prevState
    //     return {
    //         toggleVideoConfShowModal: !prevState.toggleVideoConfShowModal,
    //         questionId: data

    //     }
    // }, () => {
    //     debugger
    //     if (previousstate.toggleVideoConfShowModal === true) {
    //         this.toggleContainerclass = 'col-lg-4 col-md-7 whitebackground mt-1';
    //         this.left_panel_classes = 'col-lg-8 col-md-12 left-container';
    //         this.videoclass = ''
    //         // this.setState({ raisebuttondisabled: false })
    //         // let topicid = this.props.match.params.topicId
    //         // const url = `/curriculum-content/${topicid}`;
    //         // this.props.history.push({ pathname: `${url}` });
    //     }
    //     else {
    //         this.toggleContainerclass = 'col-lg-3 col-md-7 whitebackground mt-1';
    //         this.left_panel_classes = 'col-lg-5 col-md-12 left-container';
    //         this.videoclass = 'col-lg-4'
    //     }
    // })

    // let divclass = this.testref
    // //alert(divclass.current.className.includes("modal--visible"))
    // if (divclass.current.className.includes("modal--visible") === true) {
    // this.toggleContainerclass = 'col-lg-4 col-md-7 whitebackground mt-1';
    // this.left_panel_classes = 'col-lg-8 col-md-12 left-container';
    // this.videoclass = ''
    // }
    // else {

    //this.setState({ raisebuttondisabled: true })
    this.toggleContainerclass = "col-lg-3 col-md-7 whitebackground";
    this.left_panel_classes = "col-lg-5 col-md-12 left-container";
    this.videoclass = "col-lg-4";
    // this.setState((prevState) => {
    //     return {
    //         toggleVideoConfShowModal: !prevState.toggleVideoConfShowModal,

    //     }
    // })
    //debugger;
    if (this.state.toggleVideoConfShowModal === true) {
      this.toggleContainerclass = "col-lg-4 col-md-7 whitebackground";
      this.left_panel_classes = "col-lg-8 col-md-12 left-container";
      this.videoclass = "";
      this.setState({ raisebuttondisabled: false });
    } else {
      this.toggleContainerclass = "col-lg-3 col-md-7 whitebackground";
      this.left_panel_classes = "col-lg-5 col-md-12 left-container";
      this.videoclass = "col-lg-4";
      this.setState({ raisebuttondisabled: true });
    }
    this.setState({
      toggleVideoConfShowModal: !this.state.toggleVideoConfShowModal,
      questionId: data,
    });
    // }, 100);
  };

  requestContentInformation = (contentId) => {
    const reqData = {
      params: {
        content_id: contentId,
      },
    };
    fetchContentEnhancement(reqData)
      .then((res) => {
        this.setState({
          contentEnhancement: uniqBy(res.data.res_content_enhancement, "title"),
        });
      })
      .catch((err) => {
        console.error(err.message);
        alert("Some error occurred!");
        this.props.history.goBack();
      });
    fetchInstructionalContent(reqData)
      .then((res) => {
        let challenges = res.data.data.challenges.map((challenge) => {
          challenge.link = "/answerboard/c" + challenge.link;
          return challenge;
        });

        this.setState(
          {
            mainTopic: res.data.data,
            recommendations: res.data.recommendations,
            topicRecommendations: res.data.topic_recommendations,
            challenges: challenges,
          },
          () => {
            //       debugger;
            if (this.state.mainTopic.type === "html") {
              this.callFetchHintAPI();
              this.callFetchHighlightAPI();
            } else {
              // Disable Highlight Text functionality other than html content.
              this.setState({ textHighlighterEnabled: false });
            }
          }
        );
      })
      .catch((err) => {
        console.error(err.message);
        alert("Some error occurred!");
        this.props.history.goBack();
      });
  };

  modalBody = null;
  modalTitle = null;
  showButton = false;
  resizable = false;
  popUpSize = "small";

  openModal = (
    event,
    modalTitle,
    modalBody,
    popUpSize = false,
    showButton = false,
    resizable = false
  ) => {
    event.preventDefault();
    this.modalBody = modalBody;
    this.modalTitle = modalTitle;
    this.showButton = showButton;
    this.resizable = resizable;
    this.popUpSize = popUpSize ? popUpSize : "small";
    try {
      let player = this.props.location.state.videoPlayer;
      if (player) {
        if (player.getPlayerState() !== 2) {
          player.pauseVideo();
        }
      }
    } catch (e) { }
    this.setState({ showModal: true });
  };

  closeModal = () => {
    try {
      let player = this.props.location.state.videoPlayer;
      if (player) {
        if (player.getPlayerState() !== 1) {
          player.playVideo();
        }
      }
    } catch (e) { }
    this.setState({ showModal: false });
  };

  handleCarouselClick = (event, title, image, link, isExternalLink = true) => {
    if (link && !title) {
      if (isExternalLink) {
        window.open(link, "_blank");
      } else {
        this.props.history.push({
          pathname: link,
          state: {
            prevPath: getRoutes(
              this.props.location.state && this.props.location.state.prevPath
                ? this.props.location.state.prevPath
                : ["/curriculum-content"],
              window.location.pathname
            ),
            prevNode: getRoutes(
              this.props.location.state && this.props.location.state.prevNode
                ? this.props.location.state.prevNode
                : ["Learning Content"],
              this.state.mainTopic && this.state.mainTopic.title
                ? this.state.mainTopic.title
                : ""
            ),
          },
        });
      }
    } else {
      this.openModal(
        event,
        title,
        <img className="responsiv-img" src={image} alt={title} />
      );
    }
  };

  handleNotesClick = () => {
    this.setState({
      notesModalToggle: !this.state.notesModalToggle,
    });
  };

  saveNotes = (note) => {
    const reqData = {
      note: note.note,
      type: note.isReview ? "For Review" : "For Clarification",
      contentId: this.props.match.params.topicId,
      userId: getCurrentUserId(),
    };

    saveNote(reqData)
      .then((res) => {
        toast(res.data.message, {
          type: toast.TYPE.INFO,
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        this.handleNotesClick();
      })
      .catch((err) => {
        console.error(err.message);
        toast("Oops, something went wrong!", {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  onMouseUpHandler = (e) => {
    if (!this.state.iseditablecontent) {
      let isTouchEvent = false;
      if (e.nativeEvent instanceof TouchEvent) {
        isTouchEvent = true;
      }

      e.preventDefault();
      const selectionObj = window.getSelection && window.getSelection();
      const selection = selectionObj.toString();

      let getSelectionHtml = () => {
        var html = "";
        if (typeof window.getSelection != "undefined") {
          var sel = window.getSelection();
          if (sel.rangeCount) {
            var container = document.createElement("div");
            for (var i = 0, len = sel.rangeCount; i < len; ++i) {
              container.appendChild(sel.getRangeAt(i).cloneContents());
            }
            html = container.innerHTML;
          }
        } else if (typeof document.selection != "undefined") {
          if (document.selection.type === "Text") {
            html = document.selection.createRange().htmlText;
          }
        }
        return html;
      };

      if (selection !== "") {
        const htmlSelection = getSelectionHtml();
        console.log("htmlSelection: ", htmlSelection);
        console.log("selection: ", selection);

        if (selection !== htmlSelection) {
          toast(
            "Oops, the system doesn't support mixed content highlights as of now.",
            {
              type: toast.TYPE.WARNING,
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
          return;
        }

        const anchorNode = selectionObj.anchorNode;
        const focusNode = selectionObj.focusNode;
        const anchorOffset = selectionObj.anchorOffset;
        const focusOffset = selectionObj.focusOffset;
        const position = anchorNode.compareDocumentPosition(focusNode);
        let forward = false;

        if (position === anchorNode.DOCUMENT_POSITION_FOLLOWING) {
          forward = true;
        } else if (position === 0) {
          forward = focusOffset - anchorOffset > 0;
        }

        let selectionStart = forward ? anchorOffset : focusOffset;

        if (forward) {
          if (
            anchorNode.parentNode.getAttribute("data-order") &&
            anchorNode.parentNode.getAttribute("data-order") === "middle"
          ) {
            selectionStart += this.state.selectionStart;
          }
          if (
            anchorNode.parentNode.getAttribute("data-order") &&
            anchorNode.parentNode.getAttribute("data-order") === "last"
          ) {
            selectionStart += this.state.selectionEnd;
          }
        } else {
          if (
            focusNode.parentNode.getAttribute("data-order") &&
            focusNode.parentNode.getAttribute("data-order") === "middle"
          ) {
            selectionStart += this.state.selectionStart;
          }
          if (
            focusNode.parentNode.getAttribute("data-order") &&
            focusNode.parentNode.getAttribute("data-order") === "last"
          ) {
            selectionStart += this.state.selectionEnd;
          }
        }

        const selectionEnd = selectionStart + selection.length;

        if (e.target.hasAttribute("class")) {
          var cList = e.target.classList;
          cList = cList[0];
        } else {
          var cList = e.target.id;
        }

        if (cList == null || cList === "hint" || cList === "highlight") {
          // Ignore selection if it is along with the hint.
          return;
        }

        let topPosition = e.pageY + 15;
        let leftPosition = e.pageX;
        if (isTouchEvent) {
          var touch = e.changedTouches[0];
          topPosition = touch.pageY + 15;
          leftPosition = touch.pageX;
        }

        this.setState({
          showHighlighter: true,
          highlightedText: selection,
          highlighterPosition: {
            top: topPosition,
            left: leftPosition,
          },
          highlightedObject: {
            selectionStart,
            selectionEnd,
            anchorNode,
            focusNode,
            focusNodeText: anchorNode.nodeValue,
            highlightedText: selection,
            elementClass: cList,
          },
        });
      } else if (this.state.showHighlighter && !isTouchEvent) {
        this.setState({
          showHighlighter: false,
          highlightedText: "",
          highlightedObject: null,
          editHighlight: {},
        });
      }
    }
  };

  changeListObject = (
    arr,
    highlightedText,
    startPosition,
    newVal,
    removeValue = false
  ) => {
    var i = arr.length;
    while (i--) {
      if (
        arr[i] &&
        arr[i].hasOwnProperty("highlightedText") &&
        arr[i].hasOwnProperty("selectionStart") &&
        arr[i]["highlightedText"] === highlightedText &&
        arr[i]["selectionStart"] === startPosition
      ) {
        if (removeValue) {
          arr.splice(i, 1);
        } else {
          arr[i] = newVal;
        }
      }
    }
    return arr;
  };

  saveHighlight = (highlightObj, saveNote = false) => {
    if (highlightObj.highlighterColor) {
      if (
        this.state.highlightedObjectList.filter(
          (o) => o.focusNodeText === this.state.highlightedObject.focusNodeText
        ).length > 0
      ) {
        // When changing color of the same highlighting
        this.setState({
          highlightedObjectList: [
            ...this.changeListObject(
              this.state.highlightedObjectList,
              this.state.highlightedText,
              this.state.highlightedObject.selectionStart,
              {
                ...this.state.highlightedObject,
                highlighterColor: highlightObj.highlighterColor,
                note: highlightObj.note,
              }
            ),
          ],
          editHighlight:
            Object.keys(this.state.editHighlight).length !== 0
              ? {
                note: highlightObj.note,
                highlighterColor: highlightObj.highlighterColor,
              }
              : this.state.editHighlight,
        });
        this.callUpdateHighlightAPI(highlightObj);
      } else {
        // When highlighting first time
        this.setState({
          highlightedObjectList: [
            ...this.state.highlightedObjectList,
            {
              ...this.state.highlightedObject,
              highlighterColor: highlightObj.highlighterColor,
              note: highlightObj.note,
            },
          ],
        });
        this.callSaveHighlightAPI(highlightObj);
      }
    } else {
      // When highlighting removed
      this.setState({
        highlightedObjectList: [
          ...this.changeListObject(
            this.state.highlightedObjectList,
            this.state.highlightedText,
            this.state.highlightedObject.selectionStart,
            null,
            true
          ),
        ],
        editHighlight:
          Object.keys(this.state.editHighlight).length !== 0
            ? {
              note: highlightObj.note,
              highlighterColor: highlightObj.highlighterColor,
            }
            : this.state.editHighlight,
      });
      this.callDeleteHighlightAPI();
    }

    if (saveNote || !this.state.textHighlighterEnabled) {
      // Close highlighter when only inline note added.
      this.closeHighlighter();
    }
  };

  deleteHighlight = (noteObj) => {
    this.setState({
      highlightedObjectList: [
        ...this.changeListObject(
          this.state.highlightedObjectList,
          this.state.highlightedText,
          this.state.highlightedObject.selectionStart,
          null,
          true
        ),
      ],
      editHighlight: {},
    });
    this.closeHighlighter();
    this.callDeleteHighlightAPI();
  };

  closeHighlighter = () => {
    this.setState({
      showHighlighter: false,
      highlightedText: "",
      highlightedObject: null,
      highlighterPosition: {
        top: 0,
        left: 0,
      },
      editHighlight: {},
    });
  };

  handleHighlighterToggleClick = () => {
    if (this.state.mainTopic && this.state.mainTopic.type === "html") {
      this.setState(
        {
          textHighlighterEnabled: !this.state.textHighlighterEnabled,
        },
        () => {
          if (!this.state.textHighlighterEnabled) {
            this.closeHighlighter();
          }
        }
      );
    } else {
      toast(
        "Highlighter is only supported for textbook content, not for video content.",
        {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
    }
  };

  checkBookmarkStatus = () => {
    const reqData = {
      params: {
        contentId: this.props.match.params.topicId,
        userId: getCurrentUserId(),
      },
    };

    fetchBookmark(reqData)
      .then((res) => {
        this.setState({ bookmarked: res.data.bookmarked });
      })
      .catch((err) => {
        console.error(err.message);
        toast("Oops, something went wrong!", {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  handleBookmarkClick = () => {
    // Hit API to add/remove bookmark
    let reqData = {
      contentId: this.props.match.params.topicId,
      userId: getCurrentUserId(),
    };
    try {
      if (this.props.location.state.videoPlayer) {
        reqData["time"] = this.props.location.state.videoPlayer
          .getCurrentTime()
          .toFixed(0);

        saveBookmark(reqData)
          .then((res) => {
            toast(res.data.message, {
              type: toast.TYPE.INFO,
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          })
          .catch((err) => {
            console.log(err);
            toast("Oops, something went wrong!", {
              type: toast.TYPE.ERROR,
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          });
      } else {
        toast("Please bookmark once the video starts.", {
          type: toast.TYPE.WARNING,
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (e) {
      console.error("Video player object not found.");
      toast("Please bookmark once the video starts.", {
        type: toast.TYPE.WARNING,
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  showHighlight = (event, range) => {
    let topPosition = event.pageY + 15;
    let leftPosition = event.pageX;
    if (event.nativeEvent instanceof TouchEvent) {
      var touch = event.changedTouches[0];
      topPosition = touch.pageY + 15;
      leftPosition = touch.pageX;
    }

    this.state.highlightedObjectList.forEach((highlighObj) => {
      if (
        highlighObj.selectionStart === Number(range[0]) &&
        highlighObj.selectionEnd === Number(range[1])
      ) {
        this.setState({
          showHighlighter: true,
          highlightedText: highlighObj.highlightedText,
          highlighterPosition: {
            top: topPosition,
            left: leftPosition,
          },
          highlightedObject: highlighObj,
          editHighlight: {
            note: highlighObj.note,
            highlighterColor: highlighObj.highlighterColor,
          },
        });
      }
    });
  };

  showHint = (event, range, hintText) => {
    this.state.hintObjectList.forEach((hintObj) => {
      if (
        hintObj.hintText === hintText &&
        hintObj.selectionStart === Number(range[0]) &&
        hintObj.selectionEnd === Number(range[1])
      ) {
        var rect = event.target.getBoundingClientRect();

        this.setState({
          showHint: true,
          hintPosition: {
            top: rect.bottom + window.scrollY,
            left: rect.left,
          },
          hintObject: hintObj,
        });
      }
    });
  };

  hideHint = () => {
    this.setState({
      showHint: false,
      hintPosition: {
        top: 0,
        left: 0,
      },
      hintObject: null,
    });
  };

  callFetchHintAPI = () => {
    const reqData = {
      params: {
        contentId: this.props.match.params.topicId,
      },
    };

    fetchHint(reqData)
      .then((res) => {
        this.setState({
          hintObjectList: res.data.hints,
        });
      })
      .catch((err) => {
        console.error(err.message);
        toast("Oops, something went wrong!", {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  callFetchHighlightAPI = () => {
    const reqData = {
      params: {
        contentId: this.props.match.params.topicId,
        userId: getCurrentUserId(),
      },
    };

    fetchHighlight(reqData)
      .then((res) => {
        this.setState({
          highlightedObjectList: res.data.highlights,
        });
      })
      .catch((err) => {
        console.error(err.message);
        toast("Oops, something went wrong!", {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  callSaveHighlightAPI = (highlightObj) => {
    const reqData = {
      contentId: this.props.match.params.topicId,
      userId: getCurrentUserId(),
      highlight: {
        highlightedText: this.state.highlightedObject.highlightedText,
        focusNodeText: this.state.highlightedObject.focusNodeText,
        selectionEnd: this.state.highlightedObject.selectionEnd,
        selectionStart: this.state.highlightedObject.selectionStart,
        highlighterColor: highlightObj.highlighterColor,
        note: highlightObj.note,
        elementClass: this.state.highlightedObject.elementClass,
      },
    };
    saveHighlight(reqData)
      .then((res) => {
        toast(res.data.message, {
          type: toast.TYPE.INFO,
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .catch((err) => {
        console.error(err.message);
        toast("Oops, something went wrong!", {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  callUpdateHighlightAPI = (highlightObj) => {
    const reqData = {
      contentId: this.props.match.params.topicId,
      userId: getCurrentUserId(),
      highlight: {
        highlightedText: this.state.highlightedObject.highlightedText,
        focusNodeText: this.state.highlightedObject.focusNodeText,
        selectionEnd: this.state.highlightedObject.selectionEnd,
        selectionStart: this.state.highlightedObject.selectionStart,
        highlighterColor: highlightObj.highlighterColor,
        note: highlightObj.note,
        elementClass: this.state.highlightedObject.elementClass,
      },
    };
    updateHighlight(reqData)
      .then((res) => {
        toast(res.data.message, {
          type: toast.TYPE.INFO,
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .catch((err) => {
        console.error(err.message);
        toast("Oops, something went wrong!", {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  callDeleteHighlightAPI = () => {
    const reqData = {
      params: {
        contentId: this.props.match.params.topicId,
        highlightedText: this.state.highlightedObject.highlightedText,
        focusNodeText: this.state.highlightedObject.focusNodeText,
        selectionEnd: this.state.highlightedObject.selectionEnd,
        selectionStart: this.state.highlightedObject.selectionStart,
        elementClass: this.state.highlightedObject.elementClass,
        userId: getCurrentUserId(),
      },
    };
    deleteHighlight(reqData)
      .then((res) => {
        toast(res.data.message, {
          type: toast.TYPE.INFO,
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .catch((err) => {
        console.error(err.message);
        toast("Oops, something went wrong!", {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
  toggleMenu = () => {
    this.setState({ toggleMenu: !this.state.toggleMenu });
    if (this.state.toggleMenu) {
      this.right_panel_classes = "col-lg-4 col-md-7 right-container";
      this.left_panel_classes = "col-lg-8 col-md-12 left-container-open";
      this.right_panel_arrow = "arrow left";
    } else {
      this.right_panel_classes = "col-lg-4 col-md-7 right-container open";
      this.left_panel_classes = "col-lg-8 col-md-12 left-container";
      this.right_panel_arrow = "arrow right";
    }
  };

  getContentOptionsJSX = (data, stateObj) => {
    return (
      <div className="content-options">
        <CurriculumContentCard
          title="Recommended Content for Your Cognitive Profile"
          data={[data[0]]}
          linkStateObj={stateObj}
        />
        {data.length > 1 ? (
          <CurriculumContentCard
            title="Other Curated Content Options"
            data={data.splice(1)}
            linkStateObj={stateObj}
          />
        ) : null}
      </div>
    );
  };

  openContentOptionsModal = (e, subSectionId, stateObj) => {
    const reqData = { params: { subSectionId } };
    this.openModal(
      e,
      "Content Options",
      <div className="progress-bar-container">
        <Spinner animation="border" role="status" />
      </div>,
      false,
      false,
      false
    );
    fetchSubSectionContent(reqData)
      .then((res) => {
        res.data.content.length > 0
          ? this.openModal(
            e,
            "Content Options",
            this.getContentOptionsJSX(res.data.content, stateObj)
          )
          : this.openModal(
            e,
            "Content Options",
            <p>No Content Found.</p>,
            false,
            true,
            false
          );
      })
      .catch((err) => {
        console.error(err.message);
        if (err.response.status === 404) {
          this.openModal(
            e,
            "Content Options",
            <p>No Content Found.</p>,
            false,
            true,
            false
          );
        }
      });
  };

  handleToggleSidebar = (value) => {
    //debugger;
    this.setState({ toggled: value });
    if (value) {
      if (this.state.toggleVideoConfShowModal) {
        this.right_panel_classes = "col-lg-4 col-md-7 right-container";
        this.left_panel_classes =
          "col-lg-3 col-md-12 left-container-open-videocall";
        this.toggleContainerclass = "col-lg-1 transparentbackground openLefrContainerIcon";
        this.videoclass = "col-lg-4";
      } else {
        this.right_panel_classes = "col-lg-4 col-md-7 right-container";
        this.left_panel_classes = "col-lg-7 col-md-12 left-container-open";
        this.toggleContainerclass = "col-lg-1 transparentbackground openLefrContainerIcon";
      }
    } else {
      if (this.state.toggleVideoConfShowModal) {
        this.right_panel_classes = "col-lg-4 col-md-7 right-container open";
        this.toggleContainerclass = "col-lg-3 col-md-7 whitebackground ";
        this.left_panel_classes = "col-lg-5 col-md-12 left-container";
        this.videoclass = "col-lg-4";
      } else {
        this.right_panel_classes = "col-lg-4 col-md-7 right-container open";
        this.left_panel_classes = "col-lg-8 col-md-12 left-container";
        this.toggleContainerclass = "col-lg-4 col-md-7 whitebackground ";
      }
    }
  };

  handleaddinteraction = () => {
    this.setState({ iseditablecontent: true });
  };
  handleaddinteractioncancel = () => {
    this.setState({ iseditablecontent: false });
  };
  render() {

    return (
      <>
        <Breadcrumb
          className="flex-row breadCrumb curriculum-container"
          currentNode={
            this.state.mainTopic && this.state.mainTopic.title
              ? this.state.mainTopic.title
              : []
          }
          prevNode={this.props.location.state ? this.props.location.state : []}
        />
        {this.state.mainTopic ? (
          <TakeNotes
            title={this.state.mainTopic.title}
            showModal={this.state.notesModalToggle}
            toggleModal={this.handleNotesClick}
            onSave={this.saveNotes}
          />
        ) : null}
        <HighlightText
          highlighterPosition={this.state.highlighterPosition}
          showHighlighter={this.state.showHighlighter}
          saveHighlight={this.saveHighlight}
          closeHighlighter={this.closeHighlighter}
          deleteHighlight={this.deleteHighlight}
          editMode={this.state.editHighlight}
        />
        <TextbookHint
          hintPosition={this.state.hintPosition}
          showHint={this.state.showHint}
          hintObject={this.state.hintObject}
        />
        <ToastContainer />



        <Modal
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100vw',
            height: '100vh',
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justify: 'center'
          }}
          show={this.state.subscriptionlimitedaccessOpenModal}
          onHide={this.handleSubscriptionLimitedAccessxceedModal.bind(this)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4> Alert </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.problemsolvedforsubscriptionlimitedaccess < subscribeWithoutTeacherAccessIP ?
              <div dangerouslySetInnerHTML={{ __html: LimitedAccesscontentExceedMessage(this.state.useruniquecode) }} />
              :
              <div dangerouslySetInnerHTML={{ __html: LimitedAccessbothExceedMessage(this.state.useruniquecode) }} />
            }
          </Modal.Body>
          <Modal.Footer>
            <Button clicked={this.handleSubscriptionLimitedAccessxceedModal.bind(this)} >OK</Button>
          </Modal.Footer>
        </Modal>
        <Modal
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100vw',
            height: '100vh',
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justify: 'center'
          }}
          show={this.state.demoExceedModalOpen}
          onHide={this.handledemoExceedModal.bind(this)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4> Alert </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.problemsolvedCount < interactiveProblemExceedLimit ?
              <div dangerouslySetInnerHTML={{ __html: contentExceedMessage(this.state.useruniquecode) }} />
              :
              <div dangerouslySetInnerHTML={{ __html: bothExceedMessage(this.state.useruniquecode) }} />
            }
          </Modal.Body>
          <Modal.Footer>
            <Button clicked={this.handledemoExceedModal.bind(this)} >OK</Button>
          </Modal.Footer>
        </Modal>

        <Container className={"curriculum-container pr-0 curriculum_tour " + this.maincontainerclass}>
          <div class="row">
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}

            <ul class="curriculum-tab">
              <li>
                <a class="action-buttons" onClick={this.handleNotesClick}>
                  <img src={icNote} alt="" />
                  {
                    i18next.t("curriculum_content", { returnObjects: "true" })
                      .note
                  }
                </a>
              </li>
              <li>
                <span></span>
              </li>
              <li>
                <a
                  class={
                    ((this.state.mainTopic && this.state.mainTopic.type) ===
                      "html"
                      ? "enabled-action"
                      : "disabled-action") + " action-buttons"
                  }
                  onClick={this.handleHighlighterToggleClick}
                >
                  <img src={icHighlightText} alt="" />
                  {
                    i18next.t("curriculum_content", { returnObjects: "true" })
                      .highlight_text
                  }
                  {this.state.textHighlighterEnabled ? <>(ON)</> : <>(OFF)</>}
                </a>
              </li>
              <li>
                <span></span>
              </li>
              <li>
                <a
                  class={
                    ((this.state.mainTopic && this.state.mainTopic.type) ===
                      "Video"
                      ? "enabled-action"
                      : "disabled-action") + " action-buttons"
                  }
                  onClick={this.handleBookmarkClick}
                >
                  <>
                    <BookOutlinedIcon />
                    {
                      i18next.t("curriculum_content", { returnObjects: "true" })
                        .add_bookmark
                    }
                  </>
                </a>
              </li>
              {getCurrentUserRole() === User_Role.ContentAuthor && (this.state.mainTopic && this.state.mainTopic.type) ===
                "html" ? (
                <li style={{ paddingRight: "10px" }}>
                  {!this.state.iseditablecontent ? (
                    <Button
                      //style={{ backgroundColor: "transparent", padding: 0, color: "#4252AF", fontSize: "14px", fontWeight: "500" }}
                      clicked={this.handleaddinteraction}
                      className="rating-button addinteraction"
                    >
                      Add Interactive Questions
                    </Button>
                  ) : (
                    <Button
                      //style={{ backgroundColor: "transparent", padding: 0, color: "#4252AF", fontSize: "14px", fontWeight: "500" }}
                      clicked={this.handleaddinteractioncancel}
                      className="rating-button addinteraction"
                    >
                      Cancel
                    </Button>
                  )}
                </li>
              ) : null}
            </ul>
          </div>
          <div className="row main-row content-container position-relative">
            <div className={this.left_panel_classes}>
              <Row>
                <Col>
                  {this.state.mainTopic ? (
                    <MainContent
                      topic={this.state.mainTopic}
                      iseditablecontent={this.state.iseditablecontent}
                      onMouseUp={
                        this.state.textHighlighterEnabled
                          ? this.onMouseUpHandler
                          : null
                      }
                      hintObjectList={this.state.hintObjectList}
                      highlightedObjectList={this.state.highlightedObjectList}
                      showHighlight={this.showHighlight}
                      showHint={this.showHint}
                      hideHint={this.hideHint}
                      raisebuttondisabled={this.state.raisebuttondisabled}
                      toggleVC={this.toggleVideoConfModal}
                      isHintVisible={this.state.showHint}
                      contentId={this.props.match.params.topicId}
                      isTeacher={this.state.isTeacher}
                      call_type={Call_Type.LearningContent}
                      isLimitExceed={this.state.isLimitExceed}
                    />
                  ) : null}

                </Col>
              </Row>
              {/* <Row className='mt-5 cardsContainer'>
                                {this.state.challenges.map((challenge, index) => (

                                    <Col md={5} sm={12} key={index} className='challenge-card'>
                                        <Challenge {...challenge}
                                            currentNodeTitle={this.state.mainTopic && this.state.mainTopic.title ? this.state.mainTopic.title : ''}
                                            prevPath={this.props.location.state && this.props.location.state.prevPath ? this.props.location.state.prevPath : ['/curriculum-content']}
                                            prevNode={this.props.location.state && this.props.location.state.prevNode ? this.props.location.state.prevNode : ['Learning Content']} />
                                    </Col>
                                ))}
                            </Row> */}
              <Row>
                <CardCarousel
                  challenges={this.state.challenges}
                  currentNodeTitle={
                    this.state.mainTopic && this.state.mainTopic.title
                      ? this.state.mainTopic.title
                      : ""
                  }
                  prevPath={
                    this.props.location.state &&
                      this.props.location.state.prevPath
                      ? this.props.location.state.prevPath
                      : ["/curriculum-content"]
                  }
                  prevNode={
                    this.props.location.state &&
                      this.props.location.state.prevNode
                      ? this.props.location.state.prevNode
                      : ["Learning Content"]
                  }
                ></CardCarousel>
              </Row>
              <Row>
                <Col>
                  <hr />
                </Col>
              </Row>
              {/* Challenge Students */}
              <ChallengeStudent
                title={
                  i18next.t("curriculum_content", { returnObjects: "true" })
                    .challenge_student.title
                }
                subtitle={
                  i18next.t("curriculum_content", { returnObjects: "true" })
                    .challenge_student.subtitle
                }
                students={this.state.students}
              />
            </div>
            <div style={detectDevice() === TABLET ? { flex: '0 0 29.333%' } : {}} className={this.toggleContainerclass}>
              <div
                className="toggleContainer"
                onClick={() => this.handleToggleSidebar(!this.state.toggled)}
              >
                <FaBars />
              </div>
              <div style={{ maxWidth: '100%' }} className={this.right_panel_classes}>
                {this.state.mainTopic &&
                  this.state.mainTopic.type === "Video" ? (
                  <UpcomingInteractions
                    videoContentId={this.state.mainTopic.video_content_id}
                    subPartStartTime={this.state.mainTopic.subPartStartTime}
                    subPartEndTime={this.state.mainTopic.subPartEndTime}
                    {...this.props}
                  />
                ) : null}

                <div className="enchancement-container">
                  {this.state.contentEnhancement
                    ? this.state.contentEnhancement.map(
                      (contentEnhs, index) => (
                        <VideoThumbnail
                          {...contentEnhs}
                          key={"contentEnhance" + index}
                          modelOpen={this.openModal}
                        />
                      )
                    )
                    : null}

                  {/* {this.state.videos.map(video =>
                                <VideoThumbnail
                                    {...video}
                                    key={video.id}
                                    clicked={(event) => this.openModal(event,
                                        video.title,
                                        (<Aux>
                                            {video.type === 'iframe' ?
                                                <iframe src={video.content} width="600" height="600" scrolling="no" allowfullscreen="" title="Practical Learning"></iframe>
                                                : null}
                                        </Aux>
                                        ))}
                                />)} */}

                  {this.state.mainTopic &&
                    this.state.topicRecommendations.length > 0 ? (
                    <Carousel
                      title={"More About " + this.state.mainTopic.title}
                      data={this.state.topicRecommendations}
                      onClick={this.handleCarouselClick}
                    />
                  ) : null}
                  {this.state.recommendations.length > 0 ? (
                    <Carousel
                      title="Other Topics Related To Your Exploration"
                      data={this.state.recommendations}
                      onClick={this.handleCarouselClick}
                      isSampleData={true}
                    />
                  ) : null}
                  <ProgressImage
                    currentNodeTitle={
                      this.state.mainTopic && this.state.mainTopic.title
                        ? this.state.mainTopic.title
                        : ""
                    }
                    title={
                      i18next.t("curriculum_content", { returnObjects: "true" })
                        .progress_img.title
                    }
                    prevPath={
                      this.props.location.state &&
                        this.props.location.state.prevPath
                        ? this.props.location.state.prevPath
                        : ["/curriculum-content"]
                    }
                    prevNode={
                      this.props.location.state &&
                        this.props.location.state.prevNode
                        ? this.props.location.state.prevNode
                        : ["Learning Content"]
                    }
                    image={this.state.progressImage}
                    openContentOptionsModal={this.openContentOptionsModal}
                  />
                </div>
              </div>
            </div>
            {/* <div
              className={`${this.videoclass} vc-containercur ml-auto ${this.state.toggleVideoConfShowModal
                ? "modal--visiblecur"
                : "modal--invisiblecur"
                }`}
            > */}
            {this.state.toggleVideoConfShowModal ? (
              <VideoConferenceSideBar
                showModal={this.state.toggleVideoConfShowModal}
                toggleVideoConfModal={this.toggleVideoConfModal}
                call_type={Call_Type.LearningContent}
                isTeacher={this.state.isTeacher}
                customQuestion={true}
                topicId={this.props.match.params.topicId}
                questionId={this.state.questionId}
                from="cur"
                videoclass={this.videoclass}
              />
            ) : null}
            {/* </div> */}
          </div>
        </Container>
        <Modal1
          show={this.state.showModal}
          handleClose={this.closeModal}
          title={this.modalTitle}
          body={this.modalBody}
          showButton={this.showButton}
          popUpSize={this.popUpSize}
          resizable
        />

        {/* {
          this.state.mainTopic !== 'Text' ? (<Tour pagename="curriculumcontent"></Tour>) : null
        } */}
      </>
    );
  }
}


export default withNamespaces()(CurriculumContent);


