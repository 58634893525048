import configData from './config.json';
import { Problem_source } from './Functions';
export const apiURL = configData.API_ENDPOINT;//process.env.NODE_ENV === 'production' ? window.location.protocol + "//" + window.location.host + "/api" : "https://app.aiplato.ai/api"

// export const apiURL = window.location.protocol+"//"+window.location.host+":8001"
export const imgURL = configData.IMAGE_ENDPOINT;//process.env.NODE_ENV === 'production' ? window.location.protocol + "//" + window.location.host : "https://app.aiplato.ai";

const axios = require("axios");
const axiosInstance = axios.create({
    baseURL: apiURL,

});

export function getMLForInteraction(data) {
    return axiosInstance.post("/sessionSynopsis/synopsis/", data);
}
export function fetchCurriculumContent(data) {
    return axiosInstance.get("/curriculumContent/", data);
}

export function fetchSubSectionContent(data) {
    return axiosInstance.get("/curriculumContent/sub-section/", data);
}

export function fetchCuriosityExplorationContent(data) {
    return axiosInstance.get("/curriculumContent/curiosityExploration/", data);
}

export function getTextbookContent(data) {
    return axiosInstance.get("/curriculumContent/textbook/", data);
}

export function saveReview(data) {
    return axiosInstance.post("/curriculumContent/review/", data);
}


export function saveUserQuestion(data) {
    return axiosInstance.post("/curriculumContent/question/", data);
}

export function fetchKnowledgeBaseQuestions(data) {
    return axiosInstance.get("/knowledgebase/", data);
}
export function askChatGPT(question, askaiplatotrained, playerTime, firstQuestion, Content_type, SubSection_id) {
    return axiosInstance.get(`/knowledgebase/askChatGPT/?question=${question}&aiplatotrained=${askaiplatotrained}&videoTimestamp=${playerTime}&firstQuestion=${firstQuestion}&Content_type=${Content_type}&SubSection_id=${SubSection_id}`);
}
export function askAnyProblemQuestion(data) {
    return axiosInstance.post(`/getAnyproblemAnswer/`, data);
}

export function setKnowledgeBaseQuestion(data) {
    return axiosInstance.post("/knowledgebase/", data);
}

export function resetKnowledgeBaseData(data) {
    return axiosInstance.delete("/knowledgebase/", data);
}

export function connectToTwilio(data) {
    return axiosInstance.post("/twilio/", data);
}

export function createRoomParticipantMapping(data) {
    return axiosInstance.post("/sessionSynopsis/roomParticipantMapping/", data);
}

export function initiateTranscription(data) {
    return axiosInstance.post("/sessionSynopsis/transcribe/", data);
}

export function fetchTranscript(data) {
    return axiosInstance.put("/sessionSynopsis/transcribe/", data);
}

export function initiateSynopsis(data) {
    return axiosInstance.post("/sessionSynopsis/synopsis/", data);
}

export function storeSynopsis(data) {
    return axiosInstance.put("/sessionSynopsis/synopsis/", data);
}

export function getSynopsis(data) {
    return axiosInstance.get("/sessionSynopsis/synopsis/", data);
}

export function saveSynopsisEquation(data) {
    return axiosInstance.post("/sessionSynopsis/synopsis/equation/", data);
}

export function getSynopsisEquations(data) {
    return axiosInstance.get("/sessionSynopsis/synopsis/equation/", data);
}

export function fetchReview(data) {
    return axiosInstance.get("/curriculumContent/review/", data);
}

export function saveNote(data) {
    return axiosInstance.post("/curriculumContent/note/", data);
}

export function deleteNote(data) {
    return axiosInstance.delete("/curriculumContent/note/", data);
}

export function saveBookmark(data) {
    return axiosInstance.post("/curriculumContent/bookmark/", data);
}

export function fetchBookmark(data) {
    return axiosInstance.get("/curriculumContent/bookmark/", data);
}

export function deleteBookmark(data) {
    return axiosInstance.delete("/curriculumContent/bookmark/", data);
}

export function fetchNotesHighlight(data) {
    return axiosInstance.get("/curriculumContent/note-highlight-bookmark/", data);
}
export function saveHighlight(data) {
    return axiosInstance.post("/curriculumContent/highlight/", data);
}

export function updateHighlight(data) {
    return axiosInstance.put("/curriculumContent/highlight/", data);
}

export function deleteHighlight(data) {
    return axiosInstance.delete("/curriculumContent/highlight/", data);
}

export function fetchHighlight(data) {
    return axiosInstance.get("/curriculumContent/highlight/", data);
}

export function fetchHint(data) {
    return axiosInstance.get("/curriculumContent/textbook/hint/", data);
}

export function fetchWiris(data) {
    return axiosInstance.get("/awwboard/wiris/", data);
}

export function updateWiris(data) {
    return axiosInstance.post("/awwboard/wiris/", data);
}

export function getLatex(data) {
    return axiosInstance.get("/awwboard/toLatex/", data);
}

export function createAwwappBoard(data) {
    return axiosInstance.post("/awwboard/create/", data);
}

export function deleteAwwappBoard(data) {
    return axiosInstance.post("/awwboard/delete/", data);
}

export function fetchInstructionalContent(data) {
    return axiosInstance.get('/instructionalContent/', data);
}

export function fetchInteractiveVideo(data) {
    return axiosInstance.get('/interactiveVideo/', data);
}

export function saveInteractiveVideoResponse(data) {
    return axiosInstance.post('/instructionalVideo/', data);
}

export function checkSummativeQuestion(data) {
    return axiosInstance.post("/interactiveVideo/checkSummativeQuestion/", data);
}

export function learningcontentaction(data) {
    return axiosInstance.post("/interactiveVideo/learningcontentaction/", data);
}

export function ProgressUpdatescalculation(data) {
    return axiosInstance.get("/interactiveVideo/ProgressUpdatescalculation/", data);
}

export function Topiccompletion(data) {
    return axiosInstance.get("/interactiveVideo/topiccompletion/", data);
}

export function deleteuseractionlearningcontent(data) {
    return axiosInstance.post("/interactiveVideo/deleteuseractionlearningcontent/", data);
}



export function fetchContentEnhancement(data) {
    return axiosInstance.get('/curriculumContent/content-enhancement/', data);
}

export function fetchChallenges(data) {
    return axiosInstance.get('/challenges/', data);
}

export function checkChallengeAnswer(data) {
    return axiosInstance.post('/challenges/', data);
}

export function SaveStudentChallenge(data) {
    return axiosInstance.post('/savestudentchallenge/', data);
}

export function checkEquation(data) {

    return axiosInstance.post('/challenges/equation/', data)
}

export function fetchKnowledgeGraph(data) {
    return axiosInstance.get('/challenges/getKnowledgeGraph/', data)
}

export function getSynopsisData(roomId) {
    return axiosInstance.get(`/sessionSynopsis/getSynopsisData/?roomid=${roomId}`)
}

export function clearOnGoingConference() {
    return axiosInstance.get(`/sessionSynopsis/clearOnGoingConference/`)
}

export function fetchChallengeHint(data) {
    return axiosInstance.get('/challenges/hint/', data);
}
export function fetchChallengeHintnonfrq(data) {
    return axiosInstance.get('/challenges/hintnonfrq/', data);
}

export function fetchDiagnosticQuestions(data) {
    return axiosInstance.get('/challenges/diagnosticQuestions/', data);
}

export function checkDiagnosticQuestionAnswer(data) {
    return axiosInstance.post('/challenges/diagnosticQuestions/', data);
}

export function deletechallengeproficiency(data) {
    return axiosInstance.post("/challenges/deletechallengeproficiency/", data);
}

export function fetchTopics(data) {
    return axiosInstance.get('/topics/', data);
}
export function deleteOneAwwBoard(data) {
    return axiosInstance.get('/awwboard/deleteOneBoard/', data);
}

export function generateSVGFromMathML(data) {
    const wirisAxiosInstance = axios.create({
        baseURL: 'https://www.wiris.net/demo/editor'
    });
    return wirisAxiosInstance.get('/render', data);
}

export function generateLatexFromMathML(data) {
    const wirisAxiosInstance = axios.create({
        baseURL: 'https://www.wiris.net/demo/editor'
    });
    return wirisAxiosInstance.get(`/mathml2latex`, data);
}

export function getSympyEquation(data) {
    return axiosInstance.get('/challenges/getEquationSymPy/', data)
}
export function getNextStep(data) {
    return axiosInstance.post('/challenges/nextSteps/', data)
}

export function getEntireSolution(data) {
    return axiosInstance.get('/challenges/nextSteps/', data)
}

export function getOnGoingConferences(data) {
    return axiosInstance.get('/conference/', data)
}
export function updatestudentactivestatus(data) {

    return axiosInstance.put("/conference/", data);
}
export function updateSessionTime(data) {
    return axiosInstance.post('/session/', data)
}

export function getIPAddress() {
    const IPAxiosInstance = axios.create({
        baseURL: 'http://ip-api.com/json/'
    })
    return IPAxiosInstance.get('/');
}
export function getchallengesubsection(id) {
    return axiosInstance.get(`/subsections/?Challenge_id=${id}`)
}

export function getchallengebyId(id) {
    return axiosInstance.get(`/getChallenge/?Challenge_ID= ${id}`);
}
export function getpartnumberByGroupId(groupid) {
    return axiosInstance.get(`/getpartnumberByGroupId/?groupid= ${groupid}`);
}
export function addchallenges(data) {
    // return axiosInstance.post('/ChallengeAutherUI/', data, {
    //     headers: {
    //         'content-type': 'multipart/form-data'
    //     }
    // });

    return axiosInstance.post('/AddChallenge/', data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
}
export function getProblemstatement() {
    return axiosInstance.get('/getProblemstatement/')
}

export function updateChallenge(data) {
    return axiosInstance.post("/updateChallenge/", data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
}

export function UpdateChallengeStatusUnderReview(data) {
    return axiosInstance.post('challenges/accessflow/undereview/', data)
}


export function updateChallengenodes(data) {
    return axiosInstance.post("/updateChallengenodes/", data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
}
export function updateResources(data) {
    return axiosInstance.post("/saveResources/", data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
}

export function SaveAllResource(data) {
    return axiosInstance.post("/SaveAllResource/", data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
}

export function checkCombination(challenge_id) {
    return axiosInstance.get(`/checkCombination/?challenge_id=${challenge_id}`)
}
export function insertStudentError(challenge_id, equation) {
    return axiosInstance.get(`/insertStudentError/?challenge_id=${challenge_id}&equation=${equation}`)
}
export function deleteChallengenodes(id, nodeid) {
    return axiosInstance.get(`/getChallengenodes/?Challenge_ID= ${id}&node_id=${nodeid}`)
}
export function getChallengenodes(id) {
    return axiosInstance.get(`/getChallengenodes/?Challenge_ID= ${id}`);
}
export function getHintText(challengeId, iscorrect) {
    return axiosInstance.get(`/getHinttext/?Challenge_id=${challengeId}&iscorrect=${iscorrect}`);
}


export function fetchResources(challengeId) {
    return axiosInstance.get(`/getResourceslist/?Challenge_id=${challengeId}`);
}

export function getuserroles(data) {
    return axiosInstance.get('/getuserroles/', data);
}

export function fetchtutorprofile(data) {
    return axiosInstance.get('/gettutor/', data);
}
export function savetutor(data) {

    return axiosInstance.post('/savetutor/', data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
}
export function updatetutor(data) {
    return axiosInstance.post("/updatetutor/", data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
}
export function changepassword(data) {
    return axiosInstance.post("/changepassword/", data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
}
export function checkuseroldpassword(data) {
    return axiosInstance.get('/checkuseroldpassword/', data)
}
export function gettutorchapterpreferences() {
    return axiosInstance.get('/gettutorchapterpreferences/')
}
export function addedittutoravailability(data) {
    return axiosInstance.post("/addedittutoravailability/", data);
}
export function getTutoravailability(data) {
    return axiosInstance.get('/getTutoravailability/', data)
}
export function deletetutoravilability(data) {
    return axiosInstance.post('/deletetutoravilability/', data)
}
export function gettutorsforstudenthelp(data) {
    return axiosInstance.get('/gettutorsforstudenthelp/', data)
}
export function addeditStudentTutorBooking(data) {
    return axiosInstance.post("/addeditStudentTutorBooking/", data);
}
export function getStudentBookings(data) {
    return axiosInstance.get('/getStudentBookings/', data)
}
export function getStudentAppointments(data) {
    return axiosInstance.get('/getStudentAppointments/', data)
}
export function deletestudentbooking(data) {
    return axiosInstance.post('/deletestudentbooking/', data)
}

export function getTutornotifications(data) {
    return axiosInstance.get('/getTutornotifications/', data)
}

export function getTutornotificationsrandom(data) {
    return axiosInstance.get('/getTutornotificationsrandom/', data)
}

export function getStudentnotifications(data) {
    return axiosInstance.get('/getStudentnotifications/', data)
}
export function UpdateReadNotification(data) {
    return axiosInstance.post('/UpdateReadNotification/', data)
}

export function UpdateReadNotificationRandom(data) {
    return axiosInstance.post('/UpdateReadNotificationRandom/', data)
}
export function UpdateAllReadNotification(data) {
    return axiosInstance.post('/UpdateAllReadNotification/', data)
}

export function getGraphData(params, data) {
    return axiosInstance.get('/getCG/', data)
}

export function getresourcetopic(data) {
    return axiosInstance.get('/getresourcetopic/', data);
}

export function getresourcetopicmaster(data) {
    return axiosInstance.get('/getresourcetopicmaster/', data);
}

export function checkStudentAnswer(data) {
    return axiosInstance.post(`/challenges/checkStudentAnswer/`,data)
}

export function fetchOpenStaxSolutions() {
    return axiosInstance.get('/challenges/fetchOpenStaxSolutions/');
}


export function checkTextAnswer(data) {
    return axiosInstance.get('/challenges/checkTextAnswer/', data);
}

export function saveModelAnswer(data) {
    return axiosInstance.post('/challenges/saveModelAnswer/', data);
}
export function getTutorAnswer(data) {
    return axiosInstance.get('/challenges/getTutorAnswer/', data);
}
export function deletetextanswer(data) {
    return axiosInstance.post('/challenges/deletetextanswer/', data)
}

export function getChallengeMCQ(challengeId) {
    return axiosInstance.get(`/getChallengeMCQ/?Challenge_ID=${challengeId}`);
}

export function deleteChallengeMCQ(data) {
    return axiosInstance.post('/deleteChallengeMCQ/', data)
}

export function UpdateChallengeMCQ(data) {
    return axiosInstance.post('/UpdateChallengeMCQ/', data)
}

export function saveinteractivetextbookquestions(data) {
    return axiosInstance.post("/interactiveVideo/interactivetextAQ/", data);
}
export function getAQQuestions(data) {
    return axiosInstance.get("/interactiveVideo/interactivetextAQ/", data);
}

export function updateinteractivetextbookcontent(data) {
    return axiosInstance.post("/interactiveVideo/updateinteractivetextbookcontent/", data);
}
export function get_textbooksummativequestions(data) {
    return axiosInstance.get("/interactiveVideo/get_textbooksummativequestions/", data);
}
export function createsessionforclassroomquestion(data) {
    return axiosInstance.post("/Classroom_Session_Actions/", data);
}
export function stopsessionforclassroomquestion(data) {
    return axiosInstance.put("/Classroom_Session_Actions/", data);
}
export function getrepresentativequestions(data) {
    return axiosInstance.get(`/Representative_Questions_Actions/`, data);
}
export function UpdateClassroomTranscription(data) {
    return axiosInstance.put("/UpdateClassroomTranscription/", data);
}
export function getSessionList(data) {
    return axiosInstance.get(`/Classroom_Session_Actions/`, data);
}
export function getStudentSessionList(data) {
    return axiosInstance.get("/get_student_sesssiondata/", data);
}
export function savesessionsynopsisresource(data) {
    return axiosInstance.post("/sessionSynopsis/SessionResourcesActions/", data);
}
export function getrecommendedlinks_problems(data) {
    return axiosInstance.get(`/sessionSynopsis/SessionResourcesActions/`, data);
}
export function edithomeworkproblems(data, problemSource) {
    if (problemSource !== Problem_source.apptesthomework) {
        return axiosInstance.put("/challenges/homeworkproblemsactions/", data);
    }
    else {
        return axiosInstance.put("/challenges/apptesthomeworkproblemsactions/", data);
    }
}
export function gethomeworkproblems(data) {
    return axiosInstance.get("/challenges/homeworkproblemsactions/", data);
}
export function DeleteHomeworkchallenge(data, problemSource) {
    if (problemSource !== Problem_source.apptesthomework) {
        return axiosInstance.post("/challenges/deleteHomeworkchallenge/", data);
    }
    else {
        return axiosInstance.post("/challenges/deleteappTestHomeworkchallenge/", data);
    }
}
export function savehomeworkproblems(data, problemSource) {
    if (problemSource !== Problem_source.apptesthomework) {
        return axiosInstance.post("/challenges/homeworkproblemsactions/", data);
    }
    else {
        return axiosInstance.post("/challenges/apptesthomeworkproblemsactions/", data);
    }
}

export function getHomeworkChallenges(data) {
    return axiosInstance.get(`/challenges/homeworkchallenges/`, data);
}
export function getstudenthomeworklist(data) {
    return axiosInstance.get("/challenges/getstudenthomeworklist_bychallenge/", data);
}
export function studenthomework_review(data) {
    return axiosInstance.get("/challenges/studenthomework_review/", data);
}
export function studenthomework_detail(data) {
    return axiosInstance.get("/challenges/studenthomework_detail/", data);
}
export function updatestudenthomeworklist(data) {
    return axiosInstance.put("/challenges/getstudenthomeworklist_bychallenge/", data);
}

export function updatestudenthomeworklistfeedbackcommon(data) {
    return axiosInstance.put("/challenges/updatestudenthomeworklistfeedbackcommon/", data);
}

export function UploadStudentsAndTA(data) {
    return axiosInstance.post("/uploadUsers/", data);
}

export function getRoleDetails(userId) {
    return axiosInstance.get(`/getroledetails/?user_id=${userId}`);
}

export function getBarcode(data) {
    const wirisAxiosInstance = axios.create({
        baseURL: 'https://barcodeapi.org/api'
    });
    return wirisAxiosInstance.get(`/auto/${data}`);
}

export function reviseTranscriptInClassApp(data) {
    return axiosInstance.get("/reviseTranscriptInClassApp/", data);
}

export function storeCameraImage(data) {
    return axiosInstance.post("/addUserImagetoTable/", data);
}

export function getstudentlist_nonfrqReview(data) {
    return axiosInstance.get("/challenges/AWWNonFRQ_Actions/", data);
}
export function studentnonfrqhomework_detail(data) {
    return axiosInstance.get("/challenges/studentnonfrqhomework_detail/", data);
}
export function update_nonfrqReview(data) {
    return axiosInstance.put("/challenges/AWWNonFRQ_Actions/", data);
}

export function checksessionsexistsforquestionsubmission(data) {
    return axiosInstance.get("/Classroom_Session_Actions/", data);
}

export function savecalssroomquestionByStudent(data) {
    return axiosInstance.post("/Classroom_Session_QuestionsActions/", data);
}

export function updatetotalcount(data) {
    return axiosInstance.put("/Representative_Questions_Actions/", data);
}

export function getStudentListbyProfId(data, isptTeacher) {
    if (isptTeacher === true || isptTeacher === "true") {
        return axiosInstance.get('/getStudentListbyPtTeacherId/', data);
    }
    else {
        return axiosInstance.get('/getStudentListbyProfId/', data);
    }

}

export function savedemouserdetail(data) {
    return axiosInstance.post("/demoUserDetails_actions/", data);
}

export function getdemouserdetail(data) {
    return axiosInstance.get('/demoUserDetails_actions/', data);
}
export function datacollectionssteps(data) {
    return axiosInstance.get(`/dataCollection_ShowNextStep/`, data);
}
export function getstudent_List() {
    return axiosInstance.get(`/getstudent_List/`);
}

export function datacollection_UserActions(data) {
    return axiosInstance.get(`/datacollection_UserActions/`, data);
}
export function datacollection_UserEquations(data) {
    return axiosInstance.get(`/datacollection_UserEquations/`, data);
}

export function datacollection_UserFeedback(data) {
    return axiosInstance.get(`/datacollection_UserFeedback/`, data);
}

export function datacollection_NewUserdata(data) {
    return axiosInstance.get(`/datacollection_NewUserdata/`, data);
}

export function get_challenge_symbols(data) {
    return axiosInstance.get("/challenges/challenge_substitution_actions/", data);
}

export function insertSymbolSubs(data) {
    return axiosInstance.post(`/insertSymbolSubs_actions/`, data);
}
export function deleteSymbolSubs(data) {
    return axiosInstance.put(`/insertSymbolSubs_actions/`, data);
}
export function studentSubscription(data) {
    return axiosInstance.post(`/studentSubscription/`, data);
}
export function dashboardnodechallenges(data) {
    return axiosInstance.get("/challenges/dashboardnodechallenges/", data);
}
export function Checkmywork_capture_image(data) {
    return axiosInstance.post('/challenges/Checkmywork_capture_image/', data)
}

export function saveUIConfigDetails(data) {
    return axiosInstance.post("/uiConfig_Actions/", data);
}

export function getUIConfigDetails(data) {
    return axiosInstance.get("/uiConfig_Actions/", data);
}

export function gethomeworktitledropdown(data) {
    return axiosInstance.get("/challenges/gethomeworktitledropdown/", data);
}
export function datacollection_visitorsummary(data) {
    return axiosInstance.get(`/datacollection_visitorsummary/`, data);
}

export function gettreeviewdatacommon(data) {
    return axiosInstance.get(`/gettreeviewdatacommon/`, data);
}

export function getchallenges(data) {
    return axiosInstance.get('/challenges/getchallenges/', data);
}
export function getchallengeswithchaptersandsections(data) {
    return axiosInstance.post('/challenges/getchallengeswithchaptersandsections/', data);
}

export function checkmyworkfeedbackupdate(data) {
    return axiosInstance.post('/challenges/checkmyworkfeedbackupdate/', data);
}
export function checkmyworkfeedbackthumbsupdownupdate(data) {
    return axiosInstance.put('/challenges/checkmyworkfeedbackupdate/', data);
}
export function getpracticepaperlist(data) {
    return axiosInstance.get('/challenges/practicepaperlist/', data);
}
export function ptTestSubmit(data) {
    return axiosInstance.post('/challenges/saveuserassignment/', data)
}
export function PTTimerSubmitUpdate(data) {
    return axiosInstance.post('/challenges/PTTimerSubmitUpdate/', data)
}
export function getHomeworkChallengesByPracticeId(data) {
    return axiosInstance.get(`/challenges/HomeworkChallengesByPracticeId/`, data);
}


export function getpttimedata(data) {
    return axiosInstance.get(`/challenges/ptusertimlapse/`, data);
}
export function savepttimer(data) {
    return axiosInstance.post(`/challenges/ptusertimlapse/`, data);
}

export function savepttimerRice(data) {
    return axiosInstance.post(`/challenges/ptusertimlapseRice/`, data);
}

export function updatePTAutoSubmit(data) {
    return axiosInstance.post(`/challenges/userassigmentautosubmit/`, data);
}

export function studenthomework_reviewPT(data) {
    return axiosInstance.get("/challenges/studenthomework_reviewPT/", data);
}

export function student_challengestatefrq(data) {
    return axiosInstance.get("/challenges/student_challengestatefrq/", data);
}
export function student_challengestatenonfrq(data) {
    return axiosInstance.get("/challenges/student_challengestatenonfrq/", data);
}
export function createboardinteractive(data) {
    return axiosInstance.post("/awwboard/createboardinteractive/", data);
}

export function saveUpdateFRQEquations(data) {
    return axiosInstance.post("/challenges/frqEquations/", data);
}

export function getFRQEquations(data) {
    return axiosInstance.get("/challenges/frqEquations/", data);
}
export function is_pt_submitted(data) {
    return axiosInstance.get("/challenges/is_assignment_submitted/", data);
}
export function deleteallequations(data) {
    return axiosInstance.post("/challenges/deleteallequations/", data);
}

export function CalculatePTFRQGrade(data) {
    return axiosInstance.post('/challenges/CalculatePTFRQGrade/', data)
}

export function getCalculatePTFRQGrade(data) {
    return axiosInstance.get("/challenges/CalculatePTFRQGrade/", data);
}

export function CalculatePTMCQGrade(data) {
    return axiosInstance.post('/challenges/checkmcqscore/', data)
}

export function updateawwboardisfixed(data) {
    return axiosInstance.post("/challenges/updateawwboardisfixed/", data);
}

export function sessionsynopsisdelete(data) {
    return axiosInstance.post("/sessionSynopsis/synopsisdelete/", data);
}

export function getInteractionEvaluation(data) {
    return axiosInstance.get(`/getInteractionEvaluation/`, data);
}

export function getChallengesInteractionEvaluate(data) {
    return axiosInstance.get(`/getChallengesInteractionEvaluate/`, data);
}

export function getTranscriptIdsInteractionEvaluate(data) {
    return axiosInstance.get(`/getTranscriptIdsInteractionEvaluate/`, data);
}
export function saveFeedbackDetails(data) {
    return axiosInstance.post("/feedback_actions/", data);
}

export function createSessionforFeedback(data) {
    return axiosInstance.post("/Feedback_Session_Creation/", data);
}
export function homeworkList_Rice(data) {
    return axiosInstance.get(`/homeworkList_Rice/`, data);
}
export function studentHomeworkList_Rice(data) {
    return axiosInstance.get(`/studentHomeworkList_Rice/`, data);
}
export function resetstudentresubmission(data) {
    return axiosInstance.post("/resetstudentresubmission/", data);
}

export function saveassignment(data) {
    return axiosInstance.post("/challenges/saveassignment/", data);
}

export function getassignmenttitle(data) {
    return axiosInstance.get("/challenges/getassignmenttitle/", data);
}

export function getAssignmentList(data) {
    return axiosInstance.get("/challenges/getassignmentlist/", data);
}

export function saveAssgnmentUserStatus(data) {
    return axiosInstance.post("/challenges/saveassignmentuserstatus/", data);
}

export function getAssignmentUserTimer(data) {
    return axiosInstance.get("/challenges/getassignmentusertimer/", data);
}

export function saveAssignmentUserTimer(data) {
    return axiosInstance.post("/challenges/saveassignmentusertimer/", data);
}

export function getAssignmentDetailsById(data) {
    return axiosInstance.get("/challenges/getassignmentsproblems/", data);

}

export function getInClassQASummary(data) {
    return axiosInstance.get("/inClassQAReport/", data);
}

export function getProfessorsforInstituteCourse(data) {
    return axiosInstance.get("/getProfessorsforinstitutecourse/", data);
}

export function saveuserassignment(data) {
    return axiosInstance.post('/challenges/saveuserassignment/', data)
}
export function getcoursesbyinstituteid(data) {
    return axiosInstance.get("/getcoursesbyinstituteid/", data);
}

export function updateassignment(data) {
    return axiosInstance.put("/challenges/updateassignment/", data);
}
export function problemparticipationReport(data) {
    return axiosInstance.get("/problemparticipationReport/", data);
}
export function saveuseractions(data) {
    return axiosInstance.post('/challenges/saveuseractions/', data)
}

export function getUserRoleByUserId(data) {
    return axiosInstance.get("/getUserRoleByUserId/", data);
}

export function problemcompletion_byweek(data) {
    return axiosInstance.get("/problemcompletion_byweek/", data);
}

export function savekbdata(data) {

    return axiosInstance.post("/AddKB_Acction/", data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
}

export function getCommonEquuationErrors_Report(data) {
    return axiosInstance.get("/getCommonEquuationErrors/", data);
}

export function validateTextProblemUser(data) {
    return axiosInstance.get("/validateTextproblemuser/", data);
}

export function saveuserFeedback(data) {
    return axiosInstance.post("/saveuserFeedback/", data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
}
export function userfeedbackreports(data) {
    return axiosInstance.get("/userfeedbackreports/", data);
}
export function getmultipartchallengelistbychallengeid(data) {
    return axiosInstance.get("/challenges/getmultipartchallengelistbychallengeid/", data);
}
export function studentTextProblemWorkReview_Detail(data) {
    return axiosInstance.get("/challenges/studentTextProblemWorkReview_Detail/", data);
}
export function updateTextProblemReviewByProfessor(data) {
    return axiosInstance.put(`/challenges/checkStudentAnswer/`,data)
}

export function savesubscriptionlimiteduserdetail(data) {
    return axiosInstance.post("/subscriptiolimitedaccessusers_Actions/", data);
}

export function getsubscriptionlimiteduserdetail(data) {
    return axiosInstance.get('/subscriptiolimitedaccessusers_Actions/', data);
}

export function getRecommendedChallenges(data) {
    return axiosInstance.get("/getRecommendedChallenges/", data);
}