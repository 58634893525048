import React from 'react';
import './ProgressSidebar.scss';
import Draggable from 'react-draggable';
import { showFullApp, detectOS, detectDevice, DESKTOP } from '../../../common/Functions'
import { isNil } from 'lodash';

export default (props) => {
    let classes = null;
    let osdetect = detectOS()
    //debugger;
    if (props.show) {
        if (!isNil(props.fromLeft) && props.fromLeft) {
            classes = ['progress-sidebar-card d-flex flex-column', 'show-left'];
        } else {
            classes = ['progress-sidebar-card d-flex flex-column', 'show'];
        }

    } else {
        classes = ['progress-sidebar-card d-flex flex-column', 'hide'];
    }


    return (
        <>
            {
                detectDevice() === DESKTOP
                    //|| (showFullApp() || (osdetect === "Windows" || osdetect === "MacOS" || osdetect === "Linux")) 
                    ? (
                        <Draggable handle='.close-button-container'
                            cancel=".ico-times"
                        >
                            <div className={classes.join(' ')}>
                                <div className='close-button-container d-flex justify-content-between'>
                                    <strong className='pl-3 pt-2'>{props.title}</strong>
                                    <button onClick={props.closeSidebar}><i class="ico-times"></i></button>
                                </div>
                                <div className='progress-sidebar-content'>
                                    {props.children}
                                </div>
                            </div>
                        </Draggable>
                    ) :
                    (
                        <div className={classes.join(' ')}>
                            <div className='close-button-container d-flex justify-content-between'>
                                <strong className='pl-3 pt-2'>{props.title}</strong>
                                <button onClick={props.closeSidebar}><i class="ico-times"></i></button>
                            </div>
                            <div className='progress-sidebar-content'>
                                {props.children}
                            </div>
                        </div>
                    )
            }
        </>

    );
}
