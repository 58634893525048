import React, { useState, useEffect } from 'react';
import './Answerboard.scss';
import { isNil } from 'lodash';
import Aux from '../../hoc/Wrapper';
import { Row, Col } from 'react-bootstrap';
import { IconButton } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import HintIcon from '../../assets/images/icon-hint-show.svg';
import checkWorkIcon from '../../assets/images/icon-check.svg';
import ShowNextstepIcon from '../../assets/images/icon-show-next-step.svg';
import ShowFullSolutionIcon from '../../assets/images/icon-show-full-solution.svg';
import CheckMyworkResultC from './CheckMyworkResultC';
import MathJaxRenderer from '../Common/MathJaxRenderer/MathJaxRenderer';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { getCurrentUserRole, Problem_source, getCurrentUserId, User_Role, formatNumber } from '../../common/Functions';
import {
    updatestudenthomeworklist, updateTextProblemReviewByProfessor, studentTextProblemWorkReview_Detail
} from '../../common/API'
import { toast } from 'react-toastify';
import Button from '../Common/Button/Button';
import CircularProgress from '@mui/material/CircularProgress';

const HomeworkReviewDetailNonFRQText = (props) => {

    const [loading, setloading] = useState(false)
    const [asgreviewmasterdata, setasgreviewmasterdata] = useState([])
    const [reSubmitMessageShow, setReSubmitMessageShow] = useState(false)
    const [displayMessag, setdisplayMessag] = React.useState("");
    const [modalData1, setmodalData1] = useState([])
    const [getpoints, setgetpoints] = useState(0)

    const [ta_points, setta_points] = useState(0)
    const [ta_feedback, setta_feedback] = useState("")


    useEffect(() => {
        setloading(true)

        setdisplayMessag("Fetching Data...")
        let reqData = {
            challenge_id: props.challenge_id,
            studentId: props.studentId,
            assignment_id: props.assignment_id,
            problem_source: Problem_source.assignment
        }
        studentTextProblemWorkReview_Detail({ params: reqData }).then(res => {
            setloading(false)
            if (res.data !== undefined && res.data !== null) {
                if (res.data.data[0] !== undefined) {
                    setasgreviewmasterdata(res.data.data[0])
                    if (res.data.data[0].totalgrade !== undefined && res.data.data[0].totalgrade !== null) {
                        setgetpoints(parseFloat(res.data.data[0].totalgrade).toFixed(2))
                    }
                    if (res.data.data[0].submit_id !== undefined && res.data.data[0].submit_id !== null) {
                        if (parseInt(res.data.data[0].submit_id) > 1)
                            setReSubmitMessageShow(true)
                        else {
                            setReSubmitMessageShow(false)
                        }
                    }
                    else {
                        setReSubmitMessageShow(false)
                    }
                    if (res.data.data[0].children !== undefined && res.data.data[0].children !== null && res.data.data[0].children.length > 0) {
                        setmodalData1(res.data.data[0].children)
                    }
                    else {
                        resetdta()
                    }
                }
            }


        }).catch(err => {
            setloading(false)
            console.error(err.message);
        })
    }, [])

    const resetdta = () => {
        setmodalData1(null)
        setloading(false)
        props.showreviewdnonFRQModalclose()
    }

    const handlePointsChangeEvent = (e, index) => {

        e.preventDefault();
        e.stopPropagation();
        let changeData = []
        if (modalData1 !== undefined && modalData1.length > 0) {
            changeData = [...modalData1]
            if (changeData[index] !== undefined) {
                let tempArr = changeData[index]
                tempArr.points = e.target.value
                changeData[index] = tempArr
                setmodalData1(changeData)
                debugger;


                setta_points(e.target.value)
                setgetpoints(e.target.value)

            }
        }

    }
    const handleFeedbackChangeEvent = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        let changeData = []
        if (modalData1 !== undefined && modalData1.length > 0) {
            changeData = [...modalData1]
            if (changeData[index] !== undefined) {
                let tempArr = changeData[index]
                tempArr.ta_feedback = e.target.value
                changeData[index] = tempArr
                setmodalData1(changeData)
                setta_feedback(e.target.value)
            }
        }
    }

    const handleUpdate = () => {

        let form_data = new FormData();
        form_data.append("problem_source", Problem_source.assignment)
        form_data.append("challengeId", props.challenge_id)
        form_data.append("ta_user_id", getCurrentUserId())
        form_data.append("user_id", props.studentId)
        form_data.append("assignment_id", props.assignment_id)
        form_data.append("boardId", props.boardId)
        form_data.append("ta_points", ta_points)
        form_data.append("ta_feedback", ta_feedback)

        updateTextProblemReviewByProfessor(form_data).then(res => {
            if (res.status === 200) {
                toast.success("Updated successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                props.showreviewdModalclose()
                props.frqpointschangedHandler(props.questionType, ta_points, props.showfeedbackSelectedQuestionIndex)
            }
        }).catch(err => {
            toast.error("Error!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            console.error(err.message)
        })
    }

    return (
        <>
            {
                reSubmitMessageShow ? (<div>
                    <span style={{ fontWeight: "bold", fontSize: "18px" }}>Notification: Student Resubmission</span>
                    <span style={{ fontWeight: "bold" }}> A student has resubmitted their homework. Please review and provide feedback on the updated submission.</span>
                </div>) : null}


            <div className='w-100 d-flex bg-white pr-2' >
                <div className={'div100'}>
                    {
                        asgreviewmasterdata !== null && asgreviewmasterdata !== undefined ?
                            (
                                <>
                                    {asgreviewmasterdata.isgraded ?
                                        <div style={{ paddingLeft: "20px", background: "#fff", maxWidth: "700px" }} className='popupSectionTitle'>
                                            Total Points: {getpoints}/{formatNumber(asgreviewmasterdata.totalchallengePoint)}
                                            {
                                                parseInt(props.isPtSubmitted) === 1 ? (<span style={{ paddingLeft: "5px", fontSize: "13px" }}>
                                                    (Important: Your grades reflect your submitted solutions only. Any modifications afterward won't alter your grades.)
                                                </span>) : null
                                            }
                                        </div>
                                        : null
                                    }

                                    {
                                        modalData1 !== undefined && modalData1 !== null && modalData1.length > 0 ?
                                            (
                                                modalData1.map((item, index) => {
                                                    let converedclassname = "mt-1 align-items-center eqfontText popupSectionTitlemediumlatex"
                                                    let missingconceptclassname = "mt-1 align-items-center eqfontText popupSectionTitlemediumlatex"
                                                    let containerclass = ""
                                                    let spanclass = ""

                                                    let covered_concepts = null
                                                    let missing_concepts = null
                                                    let explnation = null


                                                    let converedtopics = []
                                                    let missingconcepts = [];

                                                    if (item.student_answer !== undefined && item.student_answer !== null && item.student_answer.length > 0) {
                                                        covered_concepts = item.student_answer[0].covered_concepts
                                                        missing_concepts = item.student_answer[0].missing_concepts
                                                        explnation = item.student_answer[0].Explanation
                                                    }
                                                    if (covered_concepts !== null) {
                                                        converedclassname = converedclassname + " " + "green"
                                                        containerclass = "correctAnswerContainerstudent"
                                                        spanclass = "answer-correct-sign"


                                                        covered_concepts.forEach((rem) => {
                                                            let data = ""
                                                            data = rem
                                                            let contentdata1 = (
                                                                <Aux>
                                                                    {
                                                                        (data && data !== null && data !== 'null' && data !== "null" && data != "undefined" && data !== "" && data !== '' && data !== ',' && data !== ",") ?
                                                                            <div className="mb-1 mt-1" dangerouslySetInnerHTML={{ __html: data }}></div>
                                                                            : null
                                                                    }


                                                                </Aux>
                                                            );
                                                            converedtopics.push(contentdata1)
                                                        })
                                                    }
                                                    if (missing_concepts !== null) {
                                                        missingconceptclassname = missingconceptclassname + " " + "red"

                                                        missing_concepts.forEach((rem) => {
                                                            let data = ""
                                                            data = rem
                                                            let contentdata1 = (
                                                                <Aux>
                                                                    {
                                                                        (data && data !== null && data !== 'null' && data !== "null" && data != "undefined" && data !== "" && data !== '' && data !== ',' && data !== ",") ?
                                                                            <div className="mb-1 mt-1" dangerouslySetInnerHTML={{ __html: data }}></div>
                                                                            : null
                                                                    }
                                                                </Aux>
                                                            );
                                                            missingconcepts.push(contentdata1)
                                                        })
                                                    }


                                                    return (
                                                        <>
                                                            <div className={containerclass}>
                                                                <span className={spanclass}></span>
                                                                <div className='answer-container'>
                                                                    <div className='d-flex align-items-center justify-content-between pb-3'>
                                                                        <div>
                                                                            <p className='yrResTxt'>Your Response</p>
                                                                        </div>
                                                                        {
                                                                            item.isgraded ?
                                                                                (
                                                                                    <>
                                                                                        <div className='pointsContainer '>
                                                                                            {

                                                                                                <>
                                                                                                    <div style={{ textAlign: "end" }} className='d-flex align-items-center justify-content-end'>
                                                                                                        <span>Points:</span>
                                                                                                        {
                                                                                                            getCurrentUserRole() === User_Role.Student ? (<span style={{ marginLeft: '5px' }}>{item.points}</span>) :
                                                                                                                (
                                                                                                                    <input style={{ marginLeft: '5px' }} name="points" class="form-control bg-yellow" type='text'
                                                                                                                        onChange={(e) => handlePointsChangeEvent(e, index)}
                                                                                                                        value={item.points}></input>
                                                                                                                )
                                                                                                        }
                                                                                                    </div>

                                                                                                </>

                                                                                            }
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                                : null
                                                                        }
                                                                    </div>

                                                                    <Row className='no-gutters'>
                                                                        <Col sm="10"> <div className="feedbackBox popupSectionTitlemediumwithoutborder" style={{ width: "750px", fontSize: "14px" }}>

                                                                            <div dangerouslySetInnerHTML={{ __html: item.answer_text }}></div>
                                                                        </div></Col>
                                                                        <Col sm="2" style={{ textAlign: "end", paddingLeft: "10px" }}>

                                                                            {item.showmodelanswerused !== undefined && item.showmodelanswerused !== "" && item.showmodelanswerused == 1 ?
                                                                                (<Tooltip title="Show Solution Used" placement='top' arrow >
                                                                                    <IconButton>
                                                                                        <div className={`boardBtnsreview`}>
                                                                                            <img alt='Show Solution Used' src={ShowFullSolutionIcon} />
                                                                                        </div>
                                                                                    </IconButton>
                                                                                </Tooltip>) : null
                                                                            }
                                                                        </Col>
                                                                    </Row>




                                                                    {
                                                                        explnation !== null && explnation !== "" ? (<>
                                                                            <div className='popupSectionTitlemediumborder popupSectionTitlemedium' style={{ marginTop: "20px" }}>Explanation: </div>
                                                                            <div className='modal-content divFacts'>
                                                                                {(explnation !== null ? explnation.split('\n').map(paragraph =>
                                                                                    <p className='feedbackmodal p'>
                                                                                        {paragraph.split('\n').reduce((total, line) => [total, <br />, line])}
                                                                                    </p>
                                                                                ) : "")}
                                                                            </div>
                                                                        </>) : null
                                                                    }



                                                                    {
                                                                        converedtopics !== undefined && converedtopics !== null && converedtopics.length > 0 ?
                                                                            (
                                                                                <>
                                                                                    <div className='popupSectionTitlemediumborder popupSectionTitlemedium' style={{ marginTop: "20px" }}>Covered Topics: </div>
                                                                                    <div className={converedclassname} style={{ width: "600px" }}>
                                                                                        {converedtopics}
                                                                                    </div></>) : null
                                                                    }

                                                                    {
                                                                        missingconcepts !== undefined && missingconcepts !== null && missingconcepts.length > 0 ?
                                                                            (
                                                                                <>
                                                                                    <div className='popupSectionTitlemediumborder popupSectionTitlemedium' style={{ marginTop: "20px" }}>Missing Concepts: </div>
                                                                                    <div className={missingconceptclassname} style={{ width: "600px" }}>
                                                                                        {missingconcepts}
                                                                                    </div></>) : null
                                                                    }





                                                                    {
                                                                        getCurrentUserRole() === User_Role.Student ? (
                                                                            <>
                                                                                {
                                                                                    item.ta_feedback && item.ta_feedback !== undefined && item.ta_feedback !== null ?
                                                                                        (
                                                                                            <div className='mt-4'
                                                                                                style={{ paddingTop: "10px" }}>
                                                                                                <div className="popupSectionTitlemedium">Additional feedback by professor</div>
                                                                                                <div className='feedbackBox commonfeedbackclasswithoutHeight'>{item.ta_feedback}</div>
                                                                                            </div>
                                                                                        ) : null
                                                                                }
                                                                            </>
                                                                        ) : (<div className='feedbackBox'>
                                                                            <span >Additional feedback by professor</span>
                                                                            <textarea rows={3} type='text'
                                                                                value={item.ta_feedback}
                                                                                onChange={(e) => handleFeedbackChangeEvent(e, index)}
                                                                            >
                                                                            </textarea>
                                                                        </div>)

                                                                    }



                                                                </div>
                                                            </div>
                                                        </>
                                                    )


                                                })



                                            ) : null
                                    }




                                </>
                            ) : (<div style={{ padding: "20px", fontSize: "18px", margin: "10px" }}>{displayMessag}</div>)
                    }
                </div>
            </div>

            {
                getCurrentUserRole() === User_Role.Student ? (
                    <div className='modal-button text-center'><Button className="grey-btn" clicked={props.showreviewdModalclose}> Ok </Button></div>) :
                    (
                        <>
                            {
                                modalData1 !== null && modalData1 !== undefined ?
                                    <div className='modal-button text-center'>
                                        <Button className="yellow-btn" clicked={() => handleUpdate()}> Update </Button>
                                        <span style={{ marginLeft: "10px" }}>
                                            <Button className="grey-btn" clicked={props.showreviewdModalclose}> Cancel </Button></span>
                                    </div>
                                    : <div className='modal-button text-center'>
                                        <span style={{ marginLeft: "10px" }}>
                                            <Button className="grey-btn" clicked={props.showreviewdModalclose}> Cancel </Button></span>
                                    </div>
                            }
                        </>
                    )
            }

            {
                loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: "#164b99",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )
            }

        </>
    )

}
export default React.memo(HomeworkReviewDetailNonFRQText);