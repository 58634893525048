import React, { useState, useEffect } from 'react';
import './Answerboard.scss';
import { isNil } from 'lodash';
import Aux from '../../hoc/Wrapper';
import { Row, Col } from 'react-bootstrap';
import { IconButton } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import HintIcon from '../../assets/images/icon-hint-show.svg';
import checkWorkIcon from '../../assets/images/icon-check.svg';
import ShowNextstepIcon from '../../assets/images/icon-show-next-step.svg';
import ShowFullSolutionIcon from '../../assets/images/icon-show-full-solution.svg';
import CheckMyworkResultC from './CheckMyworkResultC';
import MathJaxRenderer from '../Common/MathJaxRenderer/MathJaxRenderer';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { getCurrentUserRole, Problem_source, getCurrentUserId, User_Role } from '../../common/Functions';
import {
    updatestudenthomeworklist, updatestudenthomeworklistfeedbackcommon, studenthomework_detail
} from '../../common/API'
import { toast } from 'react-toastify';
import Button from '../Common/Button/Button';
import CircularProgress from '@mui/material/CircularProgress';

const HomeworkReviewDetail = (props) => {

    const [homeworkreviewdata, sethomeworkreviewdata] = useState([])
    const [flowUIJson, setflowUIJson] = useState([])
    const [loading, setloading] = useState(false)
    const [noequationfound, setnoequationfound] = useState(false)
    const [hintDivVisible, setHintDivVisible] = useState(false)
    const [checkMyWorkDivVisible, setCheckMyWorkDivVisible] = useState(false)
    const [showNextStepDivVisible, setShowNextStepDivVisible] = useState(false)
    const [showSolutionDivVisible, setShowSolutionDivVisible] = useState(false)
    const [userGetPoints, setUserGetPoints] = useState(0)
    const [userTotalPointScore, setUserTotalPointScore] = useState(0)
    const [commonFeedback, setCommonFeedback] = useState("")
    const [reSubmitMessageShow, setReSubmitMessageShow] = useState(false)
    const [displayMessag, setdisplayMessag] = useState("")
    const [asgreviewmasterdata, setasgreviewmasterdata] = useState([])
    let stepNo = 0;
    let line_match = 0;
    let isLastStepCorrect = false;
    let correct_version_exists = false;
    let correct_version_line_no = -1;
    const correct_step_feedback =
        ["Well done!", "You've nailed it.", "Spot on.", "Fantastic."];
    let ind_correct_step_feedback = Math.floor(Math.random() * 4);
    const correct_step_feedback_ending =
        ["", ""];
    let ind_correct_step_feedback_ending = Math.floor(Math.random() * 2);


    useEffect(() => {
        setloading(true)

        setdisplayMessag("Fetching Data...")
        let reqData = {
            challenge_id: props.challenge_id,
            studentId: props.studentId,
            assignment_id: props.assignment_id,
            problem_source: Problem_source.assignment
        }
        studenthomework_detail({ params: reqData }).then(res => {
            debugger;
            setloading(false)
            if (res.data.data[0] !== undefined) {
                setasgreviewmasterdata(res.data.data[0])
                let incorrectattemptcount = res.data.data[0].incorrectattemptcount
                props.setattemptedIncorrecthandle(incorrectattemptcount)
                if (props.totalcheckmyworkTrial > 0) {
                    let totalattempt = parseInt(incorrectattemptcount)
                    let deductedper = ((totalattempt) * 20)
                    props.setdeductedpointshandle(deductedper)
                }

                if (res.data.data[0].common_feedback !== undefined && res.data.data[0].common_feedback !== null) {
                    setCommonFeedback(res.data.data[0].common_feedback)
                }
                if (res.data.data[0].submit_id !== undefined && res.data.data[0].submit_id !== null && res.data.data[0].is_review_complete !== undefined && res.data.data[0].is_review_complete !== null && res.data.data[0].is_review_complete !== "false" && res.data.data[0].is_review_complete !== false) {
                    if (parseInt(res.data.data[0].submit_id) > 1)
                        setReSubmitMessageShow(true)
                    else {
                        setReSubmitMessageShow(false)
                    }
                }
                else {
                    setReSubmitMessageShow(false)
                }
                if (res.data.data[0].equations !== undefined && res.data.data[0].equations.length > 0) {
                    if (res.data.data[0].equations[0].result !== undefined && res.data.data[0].equations[0].result !== null && res.data.data[0].equations[0].result.length > 0) {
                        if (res.data.data[0].equations[0].result[0].inputLatex !== "" && res.data.data[0].equations[0].result[0].inputLatex !== null && res.data.data[0].equations[0].result[0].inputLatex !== undefined) {
                            sethomeworkreviewdata(res.data.data[0].equations)
                            setflowUIJson(res.data.data[0].equations[0].ui_json)
                            setnoequationfound(false)
                        }
                        else {
                            resetdta()
                        }
                    }
                    else {
                        resetdta()
                    }
                }
                else {
                    resetdta()
                }
            }
            else {
                resetdta()
                setdisplayMessag("No Data Found!")
            }

        }).catch(err => {
            setloading(false)
            alert(err.message)
            console.error(err.message);
        })
    }, [])
    const resetdta = () => {
        sethomeworkreviewdata(null)
        setflowUIJson(null)
        setnoequationfound(true)
        setloading(false)
        // props.showreviewdModalclose()
    }
    const handlecommonfeedbackchanged = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setCommonFeedback(e.target.value)
    }

    const handleFeedbackChangeEventCorrect = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        let changeData = []
        if (homeworkreviewdata !== undefined && homeworkreviewdata.length > 0) {
            changeData = [...homeworkreviewdata]
            if (changeData[0].result[index] !== undefined) {
                let tempArr = changeData[0].result[index]
                tempArr.tafeedback = e.target.value
                changeData[0].result[index] = tempArr
                sethomeworkreviewdata(changeData)
            }
        }

    }
    const handleHintPointsChangeEvent = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        let changeData = []
        if (homeworkreviewdata !== undefined && homeworkreviewdata.length > 0) {
            changeData = [...homeworkreviewdata]
            if (changeData[0].result[index] !== undefined) {
                let tempArr = changeData[0].result[index]
                tempArr.hint_points = e.target.value
                changeData[0].result[index] = tempArr
                sethomeworkreviewdata(changeData)
            }
        }
    }
    const handlePointsChangeEvent = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        let changeData = []
        if (homeworkreviewdata !== undefined && homeworkreviewdata.length > 0) {
            changeData = [...homeworkreviewdata]
            if (changeData[0].result[index] !== undefined) {
                let tempArr = changeData[0].result[index]
                tempArr.points = e.target.value
                changeData[0].result[index] = tempArr
                debugger;
                sethomeworkreviewdata(changeData)
            }
        }
        let ta_totalgrades = 0
        changeData[0].result.map((item) => {
            if (Number.isInteger(item.line_match)) {
                // ta_hint_points = parseFloat(ta_hint_points, 2) - (parseFloat(item.hint_points, 2))
                if (item.status === "correct") {

                    ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.points, 2)
                    // ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.hint_points, 2)

                    if (parseFloat(item.hint_points, 2) < 0) {
                        ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.hint_points, 2)
                    }
                    else {
                        ta_totalgrades = parseFloat(ta_totalgrades, 2) - parseFloat(item.hint_points, 2)
                    }

                }
                else {

                    if (parseFloat(item.points, 2) < 0) {
                        ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.points, 2)
                    }
                    else {
                        ta_totalgrades = parseFloat(ta_totalgrades, 2) - parseFloat(item.points, 2)
                    }

                    // ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.hint_points, 2)
                }
            }

        })
        setUserGetPoints(parseFloat(ta_totalgrades).toFixed(2))

    }

    const handleUpdateonlyFeedback = () => {
        setloading(true)
        let form_data = new FormData();
        form_data.append("challengeId", props.challenge_id)
        form_data.append("ta_user_id", getCurrentUserId())
        form_data.append("user_id", props.studentId)
        form_data.append("boardId", props.boardId)
        form_data.append("assignment_id", props.assignment_id)
        form_data.append("problem_source", Problem_source.assignment)
        form_data.append("common_feedback", commonFeedback)
        updatestudenthomeworklistfeedbackcommon(form_data).then(res => {
            if (res.status === 200) {
                toast.success("Updated successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                setloading(false)
                props.showreviewdModalclose()

            }
        }).catch(err => {
            toast.error("Error!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            console.error(err.message)
            setloading(false)
        })
    }
    const handleUpdate = () => {
        setloading(true)
        let tafeedbackData = []
        let ta_totalgrades = 0
        let ta_hint_points = 0
        homeworkreviewdata[0].result.map((item) => {
            if (Number.isInteger(item.line_match)) {
                // ta_hint_points = parseFloat(ta_hint_points, 2) - (parseFloat(item.hint_points, 2))
                if (item.status === "correct") {
                    tafeedbackData.push({
                        "status": item.status,
                        "eq_correct": item.eq_correct,
                        "inputLatex": item.inputLatex,
                        "points": item.points,
                        "line_match": item.line_match,
                        "tafeedback": item.tafeedback,
                        "hintdata": item.hintdata,
                        "hint_points": item.hint_points,
                        "checkmyworkuseddata": item.checkmyworkuseddata,
                        "expectedSymbols": item.expectedSymbols,
                        "explanation": item.explanation,
                        "final": item.final,
                        "is_partially_complete": item.is_partially_complete,
                        "is_ue": item.is_ue,
                        "isthumbsup": item.isthumbsup,
                        "linenumber": item.linenumber,
                        "new": item.new,
                        "nextstep_used": item.nextstep_used,
                        "node_id": item.node_id,
                        "remedial_source": item.remedial_source,
                        "remedial_source_display_name": item.remedial_source_display_name,
                        "remedial_text": item.remedial_text,
                        "show_solution_used": item.show_solution_used,
                        "symbolsDiff": item.symbolsDiff,
                        "totalpointscore": item.totalpointscore,
                        "updateremedialafterSubmithw": item.updateremedialafterSubmithw
                    },
                    )

                    ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.points, 2)
                    // ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.hint_points, 2)

                    if (parseFloat(item.hint_points, 2) < 0) {
                        ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.hint_points, 2)
                    }
                    else {
                        ta_totalgrades = parseFloat(ta_totalgrades, 2) - parseFloat(item.hint_points, 2)
                    }

                }
                else {
                    tafeedbackData.push({
                        "status": item.status,
                        "eq_correct": item.eq_correct === undefined ? item.inputLatex : item.eq_correct,
                        "inputLatex": item.inputLatex,
                        "points": item.points,
                        "line_match": item.line_match,
                        "remedial_text": item.remedial_text,
                        "remedial_text2": item.remedial_text2,
                        "remedial_source_display_name": item.remedial_source_display_name,
                        "remedial_source": item.remedial_source,
                        "tafeedback": item.tafeedback,
                        "hintdata": item.hintdata,
                        "hint_points": item.hint_points,
                        "checkmyworkuseddata": item.checkmyworkuseddata,
                        "correct_version_exists": item.correct_version_exists,
                        "correct_version_line_no": item.correct_version_line_no,
                        "expectedSymbols": item.expectedSymbols,
                        "final": item.final,
                        "is_ue": item.is_ue,
                        "isthumbsup": item.isthumbsup,
                        "linenumber": item.linenumber,
                        "nextstep_used": item.nextstep_used,
                        "node_id": item.node_id,
                        "show_solution_used": item.show_solution_used,
                        "symbolsDiff": item.symbolsDiff,
                        "totalpointscore": item.totalpointscore,
                        "updateremedialafterSubmithw": item.updateremedialafterSubmithw
                    },
                    )

                    if (parseFloat(item.points, 2) < 0) {
                        ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.points, 2)
                    }
                    else {
                        ta_totalgrades = parseFloat(ta_totalgrades, 2) - parseFloat(item.points, 2)
                    }
                }
            }
            return tafeedbackData

        })
        console.log("ta feedback json - " + JSON.stringify(tafeedbackData))
        let form_data = new FormData();
        form_data.append("challengeId", props.challenge_id)
        form_data.append("ta_user_id", getCurrentUserId())
        form_data.append("user_id", props.studentId)
        form_data.append("boardId", props.boardId)
        form_data.append("ta_feedback", JSON.stringify(tafeedbackData))
        form_data.append("ta_totalgrades", ta_totalgrades)
        form_data.append("ta_hint_points", ta_hint_points)
        form_data.append("assignment_id", props.assignment_id)
        form_data.append("problem_source", Problem_source.assignment)
        form_data.append("common_feedback", commonFeedback)


        updatestudenthomeworklist(form_data).then(res => {
            if (res.status === 200) {
                toast.success("Updated successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                setloading(false)
                props.showreviewdModalclose()
                props.frqpointschangedHandler(props.questionType, ta_totalgrades, props.showfeedbackSelectedQuestionIndex)

            }
        }).catch(err => {
            toast.error("Error!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            console.error(err.message)
            setloading(false)
        })
    }

    const hinticonhandler = () => {
        setHintDivVisible(!hintDivVisible)
    }
    const checkmyworkiconhandler = () => {
        setCheckMyWorkDivVisible(!checkMyWorkDivVisible)
    }
    const showNextStephandler = () => {
        setShowNextStepDivVisible(!showNextStepDivVisible)
    }
    const showSholutionhandler = () => {
        setShowSolutionDivVisible(!showSolutionDivVisible)
    }

    const getAssumedSteps = (lineNumber, equations) => {
        let tempArr = [];
        equations.forEach(element => {
            if (element.line_match < lineNumber && element.line_match > lineNumber - 1) {
                tempArr.push({ latex: element.inputLatex, points: element.points })
            }
        });

        return (
            <div style={{paddingLeft:'20px'}}>
                <span style={{fontSize:'18px'}}>Assumed Correct Prior Steps.</span>
                <ul>
                    {tempArr.map((element, index) => (
                        <li style={{ paddingTop: '10px',display:'flex' }} key={index}>{element.latex} &nbsp;&nbsp;&nbsp; Points:{element.points}</li>
                    ))}
                </ul>
            </div>

        );
    }
    const checkifcorrectversionexists = (correct_version_exists, line_match_number, current_line) => {
        if (correct_version_exists) {
            //return <div className='extra_instructions'>(Note: You have already entered the correct version of this step in line # {line_match_number} 🤔)</div>
            if (line_match_number > current_line) {
                return <div className='extra_instructions'>Note: Your <label className="lineNumber">line #{current_line}</label> is a bit off, but you've quickly corrected it with <label className="lineNumber">line #{line_match_number}</label>. Great job adapting and finding the right approach! </div>
                //Note: interim incorrect step. You have already entered the correct version of this step in line # {line_match_number}</div>
                //Great work on noticing the mistake in line 1 and correcting it with the appropriate equation in line 2! 
            }
            else if (line_match_number == -1) {
                return <div className='extra_instructions'>Note: Your <label className="lineNumber">line #{current_line}</label> is a bit off, but you've quickly corrected it in your mind as you have progressed to the more advanced step further in your solution. Great job adapting and finding the right approach!</div>
            }
            else {
                return <div className='extra_instructions'>Note: It seems like you've retraced your <label className="lineNumber">line #{current_line}</label>, but you actually had it right the first time in your <label className="lineNumber">line #{line_match_number}</label>. Stick with your initial approach. You're doing great!</div>
            }

        }
        return null
    }
    const getStatusColor = (item) => {
        let status = 'correct';
        if (item.status === "correct") {
            status = 'correct';
        } else if (item.status === 'unrecognized') {
            if (item.eq_status === 'correct') {
                status = 'correct'
            } else {
                status = 'incorrect'
            }
        }
        else {
            status = 'incorrect'
        }

        return status
    }
    return (
        <>
            {
                reSubmitMessageShow ? (<div>
                    <span style={{ fontWeight: "bold", fontSize: "18px" }}>Notification: Student Resubmission</span>
                    <span style={{ fontWeight: "bold" }}> A student has resubmitted their homework. Please review and provide feedback on the updated submission.</span>
                </div>) : null
            }
            {
                noequationfound ? (
                    <div className='correctAnswerContainer'>
                        <div className='answer-container'>
                            <div className='d-flex align-items-center justify-content-between pb-3'>
                                <div >
                                    <p className='yrResTxt'>No equations found for - {props.selectedStudentName}</p>

                                </div>
                            </div>
                            <div className="commonfeedbackclass mt-4">
                                <div className="popupSectionTitle">Overall feedback by professor</div>
                                <div className='feedbackBox'>
                                    <span >Feedback</span>
                                    <textarea rows={8} type='text'
                                        value={commonFeedback}
                                        onChange={(e) => handlecommonfeedbackchanged(e)}>
                                    </textarea>

                                </div>

                            </div>
                        </div>
                    </div>) :
                    (<div className='w-100 d-flex bg-white pr-2' >
                        <div className={'div100'}>
                            {

                                asgreviewmasterdata !== null && asgreviewmasterdata !== undefined ?
                                    (
                                        <>
                                            {asgreviewmasterdata.isgraded ?
                                                <div style={{ paddingLeft: "20px", background: "#fff", maxWidth: "700px" }} className='popupSectionTitle'>
                                                    Total Points: {asgreviewmasterdata.totalgrade}/{asgreviewmasterdata.totalpointscore}
                                                    {
                                                        parseInt(props.isPtSubmitted) === 1 ? (<span style={{ paddingLeft: "5px", fontSize: "13px" }}>(Important: Your grades reflect your submitted solutions only. Any modifications afterward won't alter your grades.)</span>) : null
                                                    }
                                                </div>
                                                : null
                                            }
                                            {

                                                homeworkreviewdata !== undefined && homeworkreviewdata !== null && homeworkreviewdata.length > 0 && homeworkreviewdata[0].result !== undefined ?
                                                    (

                                                        homeworkreviewdata[0].result.map((item, index) => {
                                                            let hint_Data = item.hintdata
                                                            let expclass = "mt-1 d-flex align-items-center eqfont modalscroll popupSectionTitlemediumlatex, eqpadding"

                                                            let equationclassname = "mt-1 d-flex align-items-center eqfont modalscroll popupSectionTitlemediumlatex, eqpadding"
                                                            let containerclass = ""
                                                            let spanclass = ""

                                                            let status = getStatusColor(item);

                                                            if (status === "correct") {
                                                                equationclassname = equationclassname + " " + "green"
                                                                containerclass = "correctAnswerContainerstudent"
                                                                spanclass = "answer-correct-sign"
                                                            }
                                                            else {
                                                                equationclassname = equationclassname + " " + "red"
                                                                containerclass = "incorrectAnswerContainerstudent"
                                                                spanclass = "answer-incorrect-sign"
                                                            }


                                                            let tafeedbackdisplay = ""

                                                            if (item.tafeedback !== null && item.tafeedback !== undefined &&
                                                                item.tafeedback !== "" && item.tafeedback !== "null" && item.tafeedback !== 'null'
                                                                && item.tafeedback !== 'undefined' && item.tafeedback !== '' && item.tafeedback !== "undefined") {
                                                                tafeedbackdisplay = item.tafeedback.replace(/(<([^>]+)>)/ig, '')
                                                            }

                                                            line_match = item.line_match;
                                                            let latexdata = !isNil(item.mathml) ? item.mathml : item.inputLatex.replace(/,\s*$/, "")
                                                            stepNo += 1
                                                            isLastStepCorrect = getStatusColor(item) === 'correct' ? true : false
                                                            // isLastStepCorrect = item.status === 'correct' ? true : false
                                                            correct_version_exists = item.correct_version_exists ? item.correct_version_exists : false
                                                            if (isLastStepCorrect === false)
                                                                correct_version_exists = item.correct_version_exists ? item.correct_version_exists : false
                                                            if (correct_version_exists) {
                                                                correct_version_line_no = item.correct_version_line_no;
                                                            }

                                                            let lastElement3 = checkifcorrectversionexists(correct_version_exists, correct_version_line_no, line_match)
                                                            let recomTitledisplay = false
                                                            let contentdatafinal = []
                                                            // let remedialtext = []
                                                            if (item.remedial_text !== undefined && item.remedial_text !== null && item.remedial_text !== "undefined" && item.remedial_text !== "null") {
                                                                for (let i = 0; i <= item.remedial_text.length; i++) {
                                                                    let data = ""
                                                                    let data1 = ""
                                                                    let data3 = ""
                                                                    let data4 = ""

                                                                    data = item.remedial_text[i]
                                                                    data1 = item.remedial_text2[i]
                                                                    data3 = item.remedial_source[i]
                                                                    data4 = item.remedial_source_display_name[i]
                                                                    if (data && data !== null && data !== 'null' && data !== "null" && data != "undefined" && data !== "" && data !== '') {
                                                                        data = data.toString().replace(/^,/, '').replace(/,\s*$/, "");
                                                                        data = data.replace(".,", ".")
                                                                    }
                                                                    if (data1 && data1 !== null && data1 !== 'null' && data1 !== "null" && data1 != "undefined" && data1 !== "" && data1 !== '') {
                                                                        data1 = data1.toString().replace(/^,/, '').replace(/,\s*$/, "");
                                                                        data1 = data1.replace(".,", ".")
                                                                    }
                                                                    if (data3 && data3 !== null && data3 !== 'null' && data3 !== "null" && data3 != "undefined" && data3 !== "" && data3 !== '') {
                                                                        data3 = data3.toString().replace(/^,/, '').replace(/,\s*$/, "");
                                                                        data3 = data3.replace(".,", ".")
                                                                        recomTitledisplay = true
                                                                    }
                                                                    if (data4 && data4 !== null && data4 !== 'null' && data4 !== "null" && data4 != "undefined" && data4 !== "" && data4 !== '') {
                                                                        data4 = data4.toString().replace(/^,/, '').replace(/,\s*$/, "");
                                                                        data4 = data4.replace(".,", ".")
                                                                    }

                                                                    let contentdata = (
                                                                        <Aux>
                                                                            {
                                                                                (data && data !== null && data !== 'null' && data !== "null" && data != "undefined" && data !== "" && data !== '' && data !== ',' && data !== ",") ?
                                                                                    <div className="mb-1 mt-3" dangerouslySetInnerHTML={{ __html: data.toString().replace(/^,/, '').replace(/,\s*$/, "") }}></div>
                                                                                    : null
                                                                            }
                                                                            {
                                                                                (data1 && data1 !== null && data1 !== 'null' && data1 !== "null" && data1 != "undefined" && data1 !== "" && data1 !== '' && data1 !== ",") ?
                                                                                    <div className="mb-1" dangerouslySetInnerHTML={{ __html: data1.toString().replace(/^,/, '').replace(/,\s*$/, "") }}></div>
                                                                                    : null
                                                                            }
                                                                            {
                                                                                (data3 && data3 !== null && data3 !== 'null' && data3 !== "null" && data3 != "undefined" && data3 !== "" && data3 !== '') ?
                                                                                    <a href={data3}
                                                                                        target='_blank' rel="noopener noreferrer">
                                                                                        <div dangerouslySetInnerHTML={{ __html: data4 ? data4.toString().replace(/^,/, '').replace(/,\s*$/, "") : data3.toString().replace(/^,/, '').replace(/,\s*$/, "") }}></div></a>
                                                                                    : null
                                                                            }

                                                                        </Aux>
                                                                    );
                                                                    contentdatafinal.push(contentdata)


                                                                }
                                                            }


                                                            return (
                                                                Number.isInteger(item.line_match) ?
                                                                    (
                                                                        <>
                                                                            <div className={containerclass}>

                                                                                <span className={spanclass}></span>
                                                                                <div className='answer-container'>
                                                                                    <div className='d-flex align-items-center justify-content-between'>
                                                                                        <div>
                                                                                            <p className='yrResTxt'>Your Response</p>
                                                                                        </div>
                                                                                        {
                                                                                            asgreviewmasterdata.isgraded ?
                                                                                                (
                                                                                                    <>
                                                                                                        <div className='pointsContainer '>
                                                                                                            {

                                                                                                                <>
                                                                                                                    <div style={{ textAlign: "end" }} className='d-flex align-items-center justify-content-end'>
                                                                                                                        <span>Points:</span>
                                                                                                                        {
                                                                                                                            getCurrentUserRole() === User_Role.Student ? (<span style={{ marginLeft: '5px' }}>{item.points}</span>) :
                                                                                                                                (
                                                                                                                                    <input style={{ marginLeft: '5px' }} name="points" class="form-control bg-yellow" type='text'
                                                                                                                                        onChange={(e) => handlePointsChangeEvent(e, index)}
                                                                                                                                        value={item.points}></input>
                                                                                                                                )
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    {
                                                                                                                        parseFloat(item.points) > 2 ?
                                                                                                                            (<span>Note: Given full credit for all steps up to this point.</span>) : null
                                                                                                                    }</>

                                                                                                            }
                                                                                                        </div>
                                                                                                    </>
                                                                                                )
                                                                                                : null
                                                                                        }
                                                                                    </div>
                                                                                    {lastElement3}
                                                                                    {/* {
                                                                                        getStatusColor(item) === 'correct' ? (
                                                                                            <div className="green">
                                                                                                <CheckCircleIcon style={{ fill: "green" }} />
                                                                                                {
                                                                                                    <span><span>{correct_step_feedback[ind_correct_step_feedback]} </span>
                                                                                                        <span>{correct_step_feedback_ending[ind_correct_step_feedback_ending]}</span
                                                                                                        ></span>
                                                                                                }
                                                                                            </div>
                                                                                        ) : null
                                                                                    } */}
                                                                                    <div className={equationclassname}>
                                                                                        <MathJaxRenderer math={`${latexdata}`}></MathJaxRenderer>

                                                                                    </div>
                                                                                    {
                                                                                        getStatusColor(item) === 'correct' ? (
                                                                                            <>
                                                                                                <br />
                                                                                                <div className={expclass}>  <MathJaxRenderer math={item.explanation}></MathJaxRenderer></div>
                                                                                            </>) : <div className={expclass}>  <MathJaxRenderer math={item.remedial_text}></MathJaxRenderer></div>
                                                                                    }
                                                                                    {
                                                                                          asgreviewmasterdata.isgraded ?  getAssumedSteps(item.line_match, homeworkreviewdata[0].result) : null
                                                                                    }
                                                                                    <Row className='no-gutters'>
                                                                                        <Col sm="9"></Col>

                                                                                        <Col sm="3" style={{ textAlign: "end", paddingLeft: "10px" }}>
                                                                                            {
                                                                                                item.hintdata !== undefined && item.hintdata !== "" && item.hintdata !== "[]" && item.hintdata.length > 0 ?
                                                                                                    (<Tooltip title="Hints Used" placement='top' arrow >
                                                                                                        <IconButton onClick={hinticonhandler}>
                                                                                                            <div className={`boardBtnsreview `}                                                >
                                                                                                                <img src={HintIcon} />
                                                                                                            </div>

                                                                                                        </IconButton>
                                                                                                    </Tooltip>) : null
                                                                                            }
                                                                                            {
                                                                                                item.checkmyworkuseddata !== undefined && item.checkmyworkuseddata !== null && item.checkmyworkuseddata !== "" ?
                                                                                                    (<Tooltip title="Feedback Used" placement='top' arrow>
                                                                                                        <IconButton onClick={checkmyworkiconhandler}>
                                                                                                            <div className={`boardBtnsreview`}>
                                                                                                                <img alt='Feedback Used' src={checkWorkIcon} />
                                                                                                            </div>
                                                                                                        </IconButton>
                                                                                                    </Tooltip>) : null
                                                                                            }
                                                                                            {
                                                                                                item.nextstep_used !== undefined && item.nextstep_used !== null && item.nextstep_used !== "" ?
                                                                                                    (<Tooltip title="Hint: Next Step Used" placement='top' arrow>
                                                                                                        <IconButton onClick={showNextStephandler}>
                                                                                                            <div className={`boardBtnsreview`}>
                                                                                                                <img alt='Hint: Next Step Used' src={ShowNextstepIcon} />
                                                                                                            </div>
                                                                                                        </IconButton>
                                                                                                    </Tooltip>) : null
                                                                                            }
                                                                                            {
                                                                                                item.show_solution_used !== undefined && item.show_solution_used !== null && item.show_solution_used !== "" ?
                                                                                                    (<Tooltip title="Show Solution Used" placement='top' arrow>
                                                                                                        <IconButton onClick={showSholutionhandler}>
                                                                                                            <div className={`boardBtnsreview`}>
                                                                                                                <img alt='Show Solution Used' src={ShowFullSolutionIcon} />
                                                                                                            </div>
                                                                                                        </IconButton>
                                                                                                    </Tooltip>) : null
                                                                                            }
                                                                                        </Col>
                                                                                    </Row>


                                                                                    {
                                                                                        contentdatafinal !== undefined && contentdatafinal !== null && contentdatafinal.length > 0 ?
                                                                                            (
                                                                                                <>
                                                                                                    {
                                                                                                        recomTitledisplay ? (
                                                                                                            <div className='recommendedBox red mt-1' >
                                                                                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                                                    {/* {
                                                                                                                        recomTitledisplay ? (<span style={{ color: "#424242" }}>Recommended Resources</span>) : null
                                                                                                                    } */}

                                                                                                                    {
                                                                                                                        item.updateremedialafterSubmithw ? (
                                                                                                                            <div style={{ color: "#96c5f3" }}>Updated</div>
                                                                                                                        ) : null
                                                                                                                    }
                                                                                                                </div>
                                                                                                                {contentdatafinal}
                                                                                                            </div>) : null
                                                                                                    }
                                                                                                </>
                                                                                            ) : null
                                                                                    }



                                                                                    {
                                                                                        getCurrentUserRole() === User_Role.Student ? (
                                                                                            <>
                                                                                                {
                                                                                                    tafeedbackdisplay !== "" ? (
                                                                                                        <div className='mt-4'
                                                                                                            style={{ paddingTop: "10px" }}>
                                                                                                            <div className="popupSectionTitlemedium">Additional feedback by professor</div>
                                                                                                            <div className='commonfeedbackclasswithoutHeight feedbackBox'>{tafeedbackdisplay}</div>
                                                                                                        </div>
                                                                                                    ) : null
                                                                                                }

                                                                                            </>
                                                                                        )
                                                                                            :
                                                                                            (
                                                                                                <div className='feedbackBox'>
                                                                                                    <span >Additional feedback by professor</span>
                                                                                                    <textarea rows={3} type='text'
                                                                                                        value={item.tafeedback}
                                                                                                        onChange={(e) => handleFeedbackChangeEventCorrect(e, index)}>
                                                                                                    </textarea>

                                                                                                </div>)
                                                                                    }
                                                                                    {
                                                                                        hintDivVisible ? (
                                                                                            <>
                                                                                                {item.hintdata !== undefined && item.hintdata !== "" && item.hintdata !== "[]" && item.hintdata.length > 0 ?
                                                                                                    <><div className='whiteBox'><div className='popupSectionTitlemediumborder' style={{ display: "flex", marginTop: "10px", justifyContent: "space-between" }}>
                                                                                                        <div className='popupSectionTitlemedium'> Hints Used</div>
                                                                                                        {props.isGradedAsg ?
                                                                                                            <div style={{ textAlign: "end" }} className='d-flex align-items-center justify-content-end'>
                                                                                                                <span>Hint Points:</span>
                                                                                                                {
                                                                                                                    getCurrentUserRole() === User_Role.Student ?
                                                                                                                        (<span style={{ marginLeft: '5px' }}>{item.hint_points}</span>)
                                                                                                                        : (<input style={{ marginLeft: '5px' }} name="points" class="form-control bg-yellow" type='text'
                                                                                                                            onChange={(e) => handleHintPointsChangeEvent(e, index)}
                                                                                                                            value={item.ta_hint_points}></input>)
                                                                                                                }


                                                                                                            </div>
                                                                                                            : null}
                                                                                                    </div>
                                                                                                        <div className='tuorporfile-content-card'>
                                                                                                            {
                                                                                                                item.hintdata.map((hint) => {

                                                                                                                    let displaystring = ""
                                                                                                                    if (hint.hint_remedial_resource_display_name !== "None" && hint.hint_remedial_resource_display_name !== null && hint.hint_remedial_resource_display_name !== undefined && hint.hint_remedial_resource_display_name !== "null" && hint.hint_remedial_resource_display_name !== "undefined") {
                                                                                                                        displaystring = hint.hint_remedial_resource_display_name
                                                                                                                    }
                                                                                                                    else if (hint.hint_remedial_source !== "None" && hint.hint_remedial_source !== null && hint.hint_remedial_source !== undefined && hint.hint_remedial_source !== "null" && hint.hint_remedial_source !== "undefined") {
                                                                                                                        displaystring = hint.hint_remedial_source
                                                                                                                    }
                                                                                                                    return (
                                                                                                                        <div className='profile-card-text'>
                                                                                                                            <Row>
                                                                                                                                <Col>
                                                                                                                                    <div className='modal-content' style={{ marginBottom: '10px', borderBottom: '1px solid lightGray' }}>
                                                                                                                                        <p className='mb-2' dangerouslySetInnerHTML={{ __html: hint.hint_text }} />

                                                                                                                                        <a href={hint.hint_remedial_source} target='_blank' rel="noopener noreferrer">
                                                                                                                                            <p className='mb-2' dangerouslySetInnerHTML={{ __html: displaystring }} />
                                                                                                                                        </a>

                                                                                                                                    </div>
                                                                                                                                </Col>
                                                                                                                            </Row>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })
                                                                                                            }

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    </>
                                                                                                    : <div className=''></div>
                                                                                                }
                                                                                            </>
                                                                                        ) : null
                                                                                    }
                                                                                    {
                                                                                        checkMyWorkDivVisible ? (
                                                                                            <>
                                                                                                {
                                                                                                    item.checkmyworkuseddata !== undefined && item.checkmyworkuseddata !== "" && item.checkmyworkuseddata !== "[]" && item.checkmyworkuseddata.length > 0 ?

                                                                                                        (
                                                                                                            <>
                                                                                                                <div style={{ maxWidth: "500px" }}>
                                                                                                                    <div className='popupSectionTitlemedium'> Feedback Used</div>
                                                                                                                    <CheckMyworkResultC checkmyworkuseddata={item.checkmyworkuseddata}></CheckMyworkResultC>
                                                                                                                </div>
                                                                                                            </>

                                                                                                        ) : null

                                                                                                }
                                                                                            </>
                                                                                        ) : null
                                                                                    }


                                                                                </div>
                                                                            </div >
                                                                        </>
                                                                    ) : null
                                                            )
                                                        })
                                                    ) : null
                                            }
                                            {
                                                getCurrentUserRole() !== User_Role.Student ? (
                                                    <div className="commonfeedbackclass mt-4">
                                                        <div className="popupSectionTitle">Overall feedback by professor</div>
                                                        <div className='feedbackBox'>
                                                            <span >Feedback</span>
                                                            <textarea rows={8} type='text'
                                                                value={commonFeedback}
                                                                onChange={(e) => handlecommonfeedbackchanged(e)}>
                                                            </textarea>

                                                        </div>

                                                    </div>) :
                                                    (
                                                        <>
                                                            {
                                                                commonFeedback !== "" && commonFeedback !== undefined && commonFeedback !== null ?
                                                                    (<div className='commonfeedbackclasswithoutHeight mt-4 '
                                                                        style={{ paddingTop: "10px" }}>
                                                                        <div className="popupSectionTitle">Overall feedback by professor</div>
                                                                        <div className='feedbackBox'>{commonFeedback}</div>
                                                                    </div>) : null
                                                            }
                                                        </>
                                                    )
                                            }
                                        </>
                                    ) : (<div style={{ padding: "20px", fontSize: "18px", margin: "10px" }}>{displayMessag}</div>)
                            }
                        </div>
                    </div >)
            }

            {
                getCurrentUserRole() === User_Role.Student ? (
                    <div className='modal-button text-center'><Button className="grey-btn" clicked={props.showreviewdModalclose}> Ok </Button></div>) :
                    (
                        <>
                            {
                                homeworkreviewdata !== null && homeworkreviewdata !== undefined ?
                                    <div className='modal-button text-center'>
                                        <Button className="yellow-btn" clicked={() => handleUpdate()}> Update </Button>
                                        <span style={{ marginLeft: "10px" }}>
                                            <Button className="grey-btn" clicked={props.showreviewdModalclose}> Cancel </Button></span>
                                    </div>
                                    : <div className='modal-button text-center'>
                                        <Button className="yellow-btn" clicked={() => handleUpdateonlyFeedback()}> Update </Button>
                                        <span style={{ marginLeft: "10px" }}>
                                            <Button className="grey-btn" clicked={props.showreviewdModalclose}> Cancel </Button></span>
                                    </div>
                            }
                        </>
                    )
            }

            {
                loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: "#164b99",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )
            }

        </>
    )

}
export default React.memo(HomeworkReviewDetail);