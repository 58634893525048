import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { TextField, Grid, Box, Button, FormLabel } from '@mui/material';
import { getCurrentUserId, Feedback_Actions, Feedback_Text } from '../../common/Functions';
import { saveuserFeedback } from '../../common/API';
import { FaStar } from "react-icons/fa";
import { Container, RadioRate, Rating } from "../Feedback/RatingStyles";
import Radiobutton from '../Common/Radiobutton/Radiobutton';


class CommonFeedback extends Component {

    state = {
        // feedbackcomment: this.props.feedbackcommentrating,
        // equationentryrating: this.props.equationentryrating,
        // equationstepbysteprating: this.props.equationstepbysteprating,
        // evaluationairating: this.props.actionName === Feedback_Actions.evaluationfeedback ? this.props.evaluationexperiencerating : this.props.aiexperiencerating,
        // incorrectequationsrating: this.props.incorrectequationsrating,
        // correctequationsrating: this.props.correctequationsrating,
        // immediatehelprating: this.props.immediatehelprating

        feedbackcomment: "",
        equationentryrating: 0,
        equationstepbysteprating: "",
        evaluationairating: 0,
        incorrectequationsrating: 0,
        correctequationsrating: 0,
        immediatehelprating: 0
    }

    handlefeedbackcommment = (e) => {
        this.setState({ feedbackcomment: e.target.value })
        this.props.handleRatingstate(Feedback_Text.feedbackcomment, e.target.value)
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let listdata = []
        if (this.props.actionName === Feedback_Actions.evaluationfeedback) {
            if (parseInt(this.state.evaluationairating) > 0) {
                const ratingdata = {
                    feedback_text: Feedback_Text.evaluationexperience,
                    feedback_value: this.state.evaluationairating
                }
                listdata.push(ratingdata)
            }

            if (this.props.isanyEquationIncorrect) {
                if (parseInt(this.state.incorrectequationsrating) > 0) {
                    const ratingdatacorrectIncorrect = {
                        feedback_text: Feedback_Text.incorrectequations,
                        feedback_value: this.state.incorrectequationsrating
                    }
                    listdata.push(ratingdatacorrectIncorrect)
                }
            }
            else {
                if (parseInt(this.state.correctequationsrating) > 0) {
                    const ratingdatacorrectIncorrect = {
                        feedback_text: Feedback_Text.correctequations,
                        feedback_value: this.state.correctequationsrating
                    }
                    listdata.push(ratingdatacorrectIncorrect)
                }
            }
            if (parseInt(this.state.immediatehelprating) > 0) {
                const ratingdataimmediatehelp = {
                    feedback_text: Feedback_Text.immediatehelp,
                    feedback_value: this.state.immediatehelprating
                }
                listdata.push(ratingdataimmediatehelp)
            }
        }
        else if (this.props.actionName === Feedback_Actions.equationfeedback) {
            if (parseInt(this.state.equationentryrating) > 0) {
                const equationentryratingfeedback = {
                    feedback_text: Feedback_Text.equationentry,
                    feedback_value: this.state.equationentryrating
                }
                listdata.push(equationentryratingfeedback)
            }


            if (this.state.equationstepbysteprating !== undefined && this.state.equationstepbysteprating !== null && this.state.equationstepbysteprating !== "") {
                const equationstepbystepratingfeedback = {
                    feedback_text: Feedback_Text.equationstepbystep,
                    feedback_value: this.state.equationstepbysteprating
                }
                listdata.push(equationstepbystepratingfeedback)
            }
        }
        else {

            if (parseInt(this.state.evaluationairating) > 0) {
                const aifeedback = {
                    feedback_text: Feedback_Text.aiexperience,
                    feedback_value: this.state.evaluationairating
                }
                listdata.push(aifeedback)
            }
        }

        if (this.state.feedbackcomment !== null && this.state.feedbackcomment !== undefined && this.state.feedbackcomment !== "") {
            const feedback_comment = {
                feedback_text: Feedback_Text.feedbackcomment,
                feedback_value: this.state.feedbackcomment
            }
            listdata.push(feedback_comment)
        }

        let form_data = new FormData();

        form_data.append("userId", getCurrentUserId());
        form_data.append("ratinglist", JSON.stringify(listdata));
        form_data.append("feedback_type", this.props.actionName);
        form_data.append("challenge_id", this.props.challenge_id !== undefined && this.props.challenge_id !== null ? this.props.challenge_id : "");
        form_data.append("boardid", this.props.boardid !== undefined && this.props.boardid !== null ? this.props.boardid : "");
        form_data.append("assignment_id", this.props.assignment_id !== undefined && this.props.assignment_id !== null ? this.props.assignment_id : "");
        form_data.append("problem_source", this.props.problem_source !== undefined && this.props.problem_source !== null ? this.props.problem_source : "");
        saveuserFeedback(form_data).then(res => {
            toast.success("Thank you for writting to us !", {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            /* Clear Form Data */
            this.setState({
                feedbackcomment: "",
                equationentryrating: 0,
                equationstepbysteprating: "",
                evaluationairating: 0,
                incorrectequationsrating: 0,
                correctequationsrating: 0,
                immediatehelprating: 0
            })
            this.props.onClose()
        })
            .catch(err => {
                console.error(err.message);
                toast.error("Some error occurred", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })

    }

    handlestepanswerratingChange = (e) => {
        this.setState({
            equationstepbysteprating: e.target.value
        })
        this.props.handleRatingstate(Feedback_Text.equationstepbystep, e.target.value)
    }

    render() {


        return (
            <>

                <div style={{ maxWidth: "400px", maxHeight: "200px" }}>
                    <Box component="form">
                        <Grid container spacing={1}>

                            {
                                this.props.ratingforequation ? (<>

                                    <Grid item xs={12}>
                                        <FormLabel id="rate1" sx={{ marginBottom: "1px !important", fontSize: "0.85rem" }}>Do you find Equation Entry easy to use?</FormLabel>
                                        <Container>
                                            {[...Array(5)].map((item, index) => {
                                                const givenRating = index + 1;
                                                return (
                                                    <label>
                                                        <RadioRate
                                                            type="radio"
                                                            value={givenRating}
                                                            onClick={() => {
                                                                this.setState({ equationentryrating: givenRating })
                                                                this.props.handleRatingstate(Feedback_Text.equationentry, givenRating)
                                                            }}
                                                        />
                                                        <Rating>
                                                            <FaStar
                                                                color={
                                                                    givenRating < this.state.equationentryrating || givenRating === this.state.equationentryrating
                                                                        ? "#d7ea4e"
                                                                        : "rgb(192,192,192)"
                                                                }
                                                            />
                                                        </Rating>
                                                    </label>
                                                );
                                            })}
                                        </Container>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormLabel id="rate2" sx={{ marginBottom: "1px !important", fontSize: "0.85rem" }}>Do you prefer this free-form style, where you can build your solution naturally, step-by-step, compared to platforms that allow one-step answers?</FormLabel>
                                        <Container>
                                            <fieldset onChange={this.handlestepanswerratingChange.bind(this)}>
                                                <div className="d-flex align-items-center justify-content-start">
                                                    <Radiobutton name="abstractreview" value='Free form step by step' checked={this.state.equationstepbysteprating === 'Free form step by step'} text='Free form step by step' />
                                                    <Radiobutton name="abstractreview" value='Only final step answer' checked={this.state.equationstepbysteprating === 'Only final step answer'} text='Only final step answer' />

                                                </div>
                                            </fieldset>

                                        </Container>
                                    </Grid>

                                </>) :

                                    (
                                        <>
                                            <Grid item xs={12}>
                                                <FormLabel id="rate6" sx={{ marginBottom: "1px !important", fontSize: "0.85rem" }}>{this.props.ratingTitle} </FormLabel>
                                                <Container>
                                                    {[...Array(5)].map((item, index) => {
                                                        const givenRating = index + 1;
                                                        return (
                                                            <label>
                                                                <RadioRate
                                                                    type="radio"
                                                                    value={givenRating}
                                                                    onClick={() => {
                                                                        this.setState({ evaluationairating: givenRating })
                                                                        if (this.props.actionName === Feedback_Actions.evaluationfeedback) {
                                                                            this.props.handleRatingstate(Feedback_Text.evaluationexperience, givenRating)
                                                                        }
                                                                        else if (this.props.actionName === Feedback_Actions.askaifeedback) {
                                                                            this.props.handleRatingstate(Feedback_Text.aiexperience, givenRating)
                                                                        }
                                                                    }}
                                                                />
                                                                <Rating>
                                                                    <FaStar
                                                                        color={
                                                                            givenRating < this.state.evaluationairating || givenRating === this.state.evaluationairating
                                                                                ? "#d7ea4e"
                                                                                : "rgb(192,192,192)"
                                                                        }
                                                                    />
                                                                </Rating>
                                                            </label>
                                                        );
                                                    })}
                                                </Container>
                                            </Grid>

                                            {
                                                this.props.isPopupFromEvaluate ? (
                                                    <>
                                                        {
                                                            this.props.isanyEquationIncorrect ?
                                                                (
                                                                    <>
                                                                        <Grid item xs={12} >
                                                                            <FormLabel id="rate3" sx={{ marginBottom: "1px !important", fontSize: "0.85rem" }}>Did the evaluation help you identify and fix the error?</FormLabel>
                                                                            <Container>
                                                                                {[...Array(5)].map((item, index) => {
                                                                                    const givenRating = index + 1;
                                                                                    return (
                                                                                        <label>
                                                                                            <RadioRate
                                                                                                type="radio"
                                                                                                value={givenRating}
                                                                                                onClick={() => {
                                                                                                    this.setState({ incorrectequationsrating: givenRating })
                                                                                                    this.props.handleRatingstate(Feedback_Text.incorrectequations, givenRating)
                                                                                                }}
                                                                                            />
                                                                                            <Rating>
                                                                                                <FaStar
                                                                                                    color={
                                                                                                        givenRating < this.state.incorrectequationsrating || givenRating === this.state.incorrectequationsrating
                                                                                                            ? "#d7ea4e"
                                                                                                            : "rgb(192,192,192)"
                                                                                                    }
                                                                                                />
                                                                                            </Rating>
                                                                                        </label>
                                                                                    );
                                                                                })}
                                                                            </Container>
                                                                        </Grid>

                                                                        <Grid item xs={12} >
                                                                            <FormLabel id="rate5" sx={{ marginBottom: "1px !important", fontSize: "0.85rem" }}>Can immediate evaluation help you learn more from the problem-solving exercise? </FormLabel>
                                                                            <Container>
                                                                                {[...Array(5)].map((item, index) => {
                                                                                    const givenRating = index + 1;
                                                                                    return (
                                                                                        <label>
                                                                                            <RadioRate
                                                                                                type="radio"
                                                                                                value={givenRating}
                                                                                                onClick={() => {
                                                                                                    this.setState({ immediatehelprating: givenRating })
                                                                                                    this.props.handleRatingstate(Feedback_Text.immediatehelp, givenRating)
                                                                                                }}
                                                                                            />
                                                                                            <Rating>
                                                                                                <FaStar
                                                                                                    color={
                                                                                                        givenRating < this.state.immediatehelprating || givenRating === this.state.immediatehelprating
                                                                                                            ? "#d7ea4e"
                                                                                                            : "rgb(192,192,192)"
                                                                                                    }
                                                                                                />
                                                                                            </Rating>
                                                                                        </label>
                                                                                    );
                                                                                })}
                                                                            </Container>
                                                                        </Grid>
                                                                    </>
                                                                ) :
                                                                (
                                                                    <>
                                                                        <Grid item xs={12} >
                                                                            <FormLabel id="rate4" sx={{ marginBottom: "1px !important", fontSize: "0.85rem" }}>Did the evaluation validate validate your thinking, to progress further with confidence?</FormLabel>
                                                                            <Container>
                                                                                {[...Array(5)].map((item, index) => {
                                                                                    const givenRating = index + 1;
                                                                                    return (
                                                                                        <label>
                                                                                            <RadioRate
                                                                                                type="radio"
                                                                                                value={givenRating}
                                                                                                onClick={() => {
                                                                                                    this.setState({ correctequationsrating: givenRating })
                                                                                                    this.props.handleRatingstate(Feedback_Text.correctequations, givenRating)
                                                                                                }}
                                                                                            />
                                                                                            <Rating>
                                                                                                <FaStar
                                                                                                    color={
                                                                                                        givenRating < this.state.correctequationsrating || givenRating === this.state.correctequationsrating
                                                                                                            ? "#d7ea4e"
                                                                                                            : "rgb(192,192,192)"
                                                                                                    }
                                                                                                />
                                                                                            </Rating>
                                                                                        </label>
                                                                                    );
                                                                                })}
                                                                            </Container>
                                                                        </Grid>

                                                                        <Grid item xs={12} >
                                                                            <FormLabel id="rate5" sx={{ marginBottom: "1px !important", fontSize: "0.85rem" }}>Did the immediate evaluation help your understanding ? </FormLabel>
                                                                            <Container>
                                                                                {[...Array(5)].map((item, index) => {
                                                                                    const givenRating = index + 1;
                                                                                    return (
                                                                                        <label>
                                                                                            <RadioRate
                                                                                                type="radio"
                                                                                                value={givenRating}
                                                                                                onClick={() => {
                                                                                                    this.setState({ immediatehelprating: givenRating })
                                                                                                    this.props.handleRatingstate(Feedback_Text.immediatehelp, givenRating)
                                                                                                }}
                                                                                            />
                                                                                            <Rating>
                                                                                                <FaStar
                                                                                                    color={
                                                                                                        givenRating < this.state.immediatehelprating || givenRating === this.state.immediatehelprating
                                                                                                            ? "#d7ea4e"
                                                                                                            : "rgb(192,192,192)"
                                                                                                    }
                                                                                                />
                                                                                            </Rating>
                                                                                        </label>
                                                                                    );
                                                                                })}
                                                                            </Container>
                                                                        </Grid>
                                                                    </>
                                                                )
                                                        }
                                                    </>
                                                ) : null
                                            }
                                        </>)
                            }


                            <Grid item xs={12} >
                                <TextField
                                    name="feedbackcomment"
                                    multiline
                                    fullWidth
                                    rows={3}
                                    id="feedbackcomment"
                                    label="Please provide any additional feedback or suggestions."
                                    value={this.state.feedbackcomment}
                                    onChange={this.handlefeedbackcommment}
                                    sx={{
                                        '& .MuiFormLabel-root': {
                                            fontSize: '0.85rem',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Button onClick={this.handleSubmit} type='submit' variant="contained" sx={{ mt: 1, mb: 1 }}> Submit </Button>
                        {/* <Button onClick={this.props.onClose} variant="outlined" sx={{ mt: 1, mb: 1, ml: 2 }}> Cancel </Button> */}
                    </Box>

                    <ToastContainer />
                </div>

            </>
        )
    }
}

export default CommonFeedback;