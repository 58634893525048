import React, { useContext, useEffect, useRef, useState } from "react";
import { getLatex, getLatexFromImage, getStrokesToken } from "./Api"
import CanvasBackground from "../../assets/images/background.png"
import { MenuItems } from "../../common/Constants";
import { isNil } from "lodash";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';

const CanvasContext = React.createContext();
var currentStroke = null;
var canvasMenuItem = MenuItems.Pen;
var hasImageOnBoard = false;
var count = 0;
var canvasCount = 1;
var hasClearCurrentCanvas = false;
var isStrokesUpdated = false;

export const CanvasProvider = ({ children }) => {
  // console.log(children.props.canvasheight)
  const [mathpixContext, setMathpixContext] = useState(null);
  const [tokenRequestFlag, setTokenRequestFlag] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false)
  const [strokes, setStrokes] = useState([]);
  const [undoHistory, setUndoHistory] = useState([]);
  const [redoHistory, setRedoHistory] = useState([]);
  const [redrawFlag, setRedrawFlag] = useState(false);
  const [redoFlag, setRedoFlag] = useState(false);
  const [undoFlag, setUndoFlag] = useState(false);
  const [checkStrikeThroughFlag, setCheckStrikeThroughFlag] = useState(false);
  const [renderLatexTimeout, setRenderLatexTimeout] = useState(null);
  const [tokenRefreshTimeout, setTokenRefreshTimeout] = useState(null);
  const [renderLatexFlag, setRenderLatexFlag] = useState(false);
  const [latex, setLatex] = useState({ code: "", isPlaceholder: true });
  const [apiResponse, setApiResponse] = useState({});
  const [pattern, setPattern] = useState(null);
  const [canvasPrepared, setCanvasPrepared] = useState(false);
  const [isAway, setIsAway] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [initialTokenReceived, setInitialTokenReceived] = useState(false);
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const lineWidth = useRef(1);
  const [clearCanvasMsg, setclearCanvasMsg] = useState(null);
  const [isNewPage, setIsNewPage] = useState(false);
  const [imageToggle, setImageToggle] = useState(null)
  const [eventType, setEventType] = useState('mouse')
  const [fromImage, setFromImage] = useState(false)
  const [equationMessageCounter, setEquationMessageCounter] = useState(0);

  let hasResizeEvent = useRef(false);
  let hasSavedWork = useRef(false)
  const prepareCanvas = () => {
    const canvas = canvasRef.current;
    var canvasContainer = document.getElementById('canvas_container_parent');
    // var resizeObserver = new ResizeObserver(windowResize);
    // resizeObserver.observe(canvasContainer);
    var parentDivHeight = canvasContainer.clientHeight
    var parentDivWidth = canvasContainer.clientWidth

    var w = parentDivWidth;
    var h = parentDivHeight * 10;
    canvas.width = w;
    canvas.height = h;
    canvas.style.width = `${w}px`;
    canvas.style.height = `${h}px`;


    const context = canvas.getContext("2d");
    //context.scale(2, 2);
    context.lineCap = "round";
    context.strokeStyle = "black";
    context.lineWidth = lineWidth;
    contextRef.current = context;

    const bgImage = new Image();
    bgImage.src = CanvasBackground;
    bgImage.onload = () => {
      const bgpattern = context.createPattern(bgImage, "repeat");
      context.fillStyle = bgpattern;
      context.fillRect(0, 0, canvas.width, canvas.height);
      setPattern(bgpattern);
    };
    setCanvasPrepared(true);
  };

  const setMenuItem = (newValue) => {
    canvasMenuItem = newValue;
    if (canvasMenuItem === MenuItems.Hand) {

      const canvas = canvasRef.current;
      canvas.style.cursor = "pointer"
      //  debugger;
    }
    else {
      const canvas = canvasRef.current;
      canvas.style.cursor = ""
    }
  };
  const startDrawing = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!hasImageOnBoard && (canvasMenuItem === MenuItems.Pen || canvasMenuItem === MenuItems.Erase)) {
      if (e.offsetX !== undefined && e.offsetY !== undefined) {
        var { offsetX, offsetY } = e;
      }
      else {
        var bcr = e.target.getBoundingClientRect();
        var offsetX = e.targetTouches[0].clientX - bcr.x;
        var offsetY = e.targetTouches[0].clientY - bcr.y;
      }
      contextRef.current.lineWidth = lineWidth;
      contextRef.current.lineJoin = "round";
      contextRef.current.lineCap = "round";
      contextRef.current.strokeStyle = canvasMenuItem === MenuItems.Pen ? "black" : "red";
      contextRef.current.beginPath();
      contextRef.current.moveTo(offsetX, offsetY);
      currentStroke = {
        points: [{ x: offsetX, y: offsetY }],
        minX: offsetX,
        minY: offsetY,
        maxX: offsetX,
        maxY: offsetY,
        timestamp: Date.now(),
        canId: canvasCount
      };
      count++;
      setIsDrawing(true);
    } else {
      console.log('Please clear image or select pen tool')
    }

  };

  const draw = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDrawing) {
      return;
    }
    if (e.offsetX !== undefined && e.offsetY !== undefined) {
      var { offsetX, offsetY } = e;
    }
    else {
      var bcr = e.target.getBoundingClientRect();
      var offsetX = e.targetTouches[0].clientX - bcr.x;
      var offsetY = e.targetTouches[0].clientY - bcr.y;
    }

    contextRef.current.lineTo(offsetX, offsetY);
    contextRef.current.stroke();
    const minX = Math.min(offsetX, currentStroke.minX);
    const minY = Math.min(offsetY, currentStroke.minY);
    const maxX = Math.max(offsetX, currentStroke.maxX);
    const maxY = Math.max(offsetY, currentStroke.maxY);

    currentStroke = {
      points: [...currentStroke.points, { x: offsetX, y: offsetY }],
      minX: minX,
      minY: minY,
      maxX: maxX,
      maxY: maxY,
      timestamp: Date.now(),
      canId: canvasCount
    };
  };

  const finishDrawing = (e) => {
    if (canvasMenuItem === MenuItems.Pen || canvasMenuItem === MenuItems.Erase) {
      // Check if it's a touch event
      if (e.touches) {
        // Touch event
        setEventType('touch')
        // Handle touch event logic
      } else {
        // Mouse event
        setEventType('mouse')
        // Handle mouse event logic
      }
      e.preventDefault();
      e.stopPropagation();
      hasResizeEvent.current = false;
      isStrokesUpdated = true;
      setStrokes([...strokes, currentStroke]);
      contextRef.current.closePath();
      setIsDrawing(false);
      setIsActive(true);
      setCheckStrikeThroughFlag(!checkStrikeThroughFlag);

    }

  };

  const drawInitialGraphicsFromServer = (data, editFix = false) => {
    clearCanvas();
    isStrokesUpdated = false;
    setStrokes([...data]);
    data.forEach(stroke => {
      if (stroke !== null) {
        if (!editFix && stroke.canId !== canvasCount) {
          contextRef.current.strokeStyle = '#cccccc';
        } else if (editFix && stroke.canId !== canvasCount) {
          contextRef.current.strokeStyle = '#cccccc';
        } else {
          contextRef.current.strokeStyle = 'black';
        }

        stroke.points.forEach((point, index) => {
          if (index === 0) {
            contextRef.current.beginPath();
            contextRef.current.moveTo(point.x, point.y);
          } else {
            contextRef.current.lineTo(point.x, point.y);
          }
        });
        contextRef.current.stroke();
        contextRef.current.closePath();
      }
    });
  }

  const leaveCanvas = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isDrawing) {
      finishDrawing(e);
    }
  };

  const windowResize = () => {
    if (!hasImageOnBoard && !isNil(document.getElementById('canvas_container_parent'))) {
      hasResizeEvent.current = true;
      const canvas = canvasRef.current;
      var parentDivHeight = document.getElementById('canvas_container_parent').clientHeight
      var parentDivWidth = document.getElementById('canvas_container_parent').clientWidth

      var w = parentDivWidth;
      var h = parentDivHeight * 10;

      canvas.width = w;
      canvas.height = h;
      canvas.style.width = `${w}px`;
      canvas.style.height = `${h}px`;

      const context = canvas.getContext("2d");
      //context.scale(2, 2);
      context.lineCap = "round";
      context.strokeStyle = "black";
      context.lineWidth = lineWidth;
      setRedrawFlag(!redrawFlag);
    }

  };

  useEffect(() => {
    setRenderLatexFlag(!renderLatexFlag);
  }, [mathpixContext]);



  const refreshToken = () => {
    if (!document.hidden && mathpixContext === null && (isAway || isActive)) {
      setTokenRequestFlag(!tokenRequestFlag);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', windowResize);
    document.addEventListener("visibilitychange", refreshToken);
    return () => {
      window.removeEventListener('resize', windowResize);
      document.removeEventListener("visibilitychange", refreshToken);
    }
  }, [redrawFlag, mathpixContext, isAway]);

  useEffect(() => {
    if (initialTokenReceived && mathpixContext === null) {
      refreshToken();
    }
  }, [isActive]);

  useEffect(() => {
    if (canvasRef.current !== null) {
      const canvas = canvasRef.current;

      canvas.addEventListener("mousedown", startDrawing);
      canvas.addEventListener("mousemove", draw,);
      canvas.addEventListener("mouseup", finishDrawing);
      canvas.addEventListener("mouseleave", leaveCanvas);

      canvas.addEventListener("touchstart", startDrawing);
      canvas.addEventListener("touchmove", draw);
      canvas.addEventListener("touchend", finishDrawing);
      canvas.addEventListener("touchleave", leaveCanvas);
    }
    return () => {
      if (canvasRef.current !== null) {
        const canvas = canvasRef.current;
        canvas.removeEventListener("mousedown", startDrawing);
        canvas.removeEventListener("mousemove", draw);
        canvas.removeEventListener("mouseup", finishDrawing);
        canvas.removeEventListener("mouseleave", leaveCanvas);

        canvas.removeEventListener("touchstart", startDrawing);
        canvas.removeEventListener("touchmove", draw);
        canvas.removeEventListener("touchend", finishDrawing);
        canvas.removeEventListener("touchcancel", leaveCanvas);
      }
    }
  }, [isDrawing, strokes, checkStrikeThroughFlag, canvasPrepared]);

  const fetchToken = async () => {
    return await getStrokesToken();
  }

  useEffect(() => {
    const f = async () => {
      if (!document.hidden && isActive) {
        const tokenContext = await fetchToken();
        if (tokenContext !== null) {
          setMathpixContext(tokenContext);
          setInitialTokenReceived(true);
          setIsAway(false);
          setIsActive(false);
          setTokenRefreshTimeout(setTimeout(() => {
            setTokenRequestFlag(!tokenRequestFlag);
          }, tokenContext.app_token_expires_at - Date.now() - 1500));
        }
        else {
          setMathpixContext(null);
          setTimeout(() => {
            setTokenRequestFlag(!tokenRequestFlag);
          }, 2000);
        }
      }
      else {
        setMathpixContext(null);
        if (document.hidden)
          setIsAway(true);
      }
    };
    f();
  }, [tokenRequestFlag]);

  useEffect(() => {
    redraw();
    setRenderLatexFlag(!renderLatexFlag);
  }, [redrawFlag]);

  useEffect(() => {
    prepareCanvas()
    if (hasImageOnBoard) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      var img = new Image();
      img.onload = function () {
        context.drawImage(img, 20, 20, 500, 500);
      };
      img.src = imageToggle
    }
    else {
      redraw()
    }

  }, [children.props.canvasWidth > 0]);

  useEffect(() => {
    hasResizeEvent.current = false
    const redoStrokes = undoHistory[undoHistory.length - 1];
    if (redoStrokes) {
      if (redoStrokes.action === "Add") {
        isStrokesUpdated = true;
        setStrokes([...strokes, ...redoStrokes.strokes]);
      }
      else if (redoStrokes.action === "Remove") {
        isStrokesUpdated = true;
        setStrokes(strokes.filter(stroke => !redoStrokes.strokes.includes(stroke)));
      }
      setRedrawFlag(!redrawFlag);
    }
  }, [redoFlag]);

  useEffect(() => {
    hasResizeEvent.current = false
    const undoStrokes = redoHistory[redoHistory.length - 1];
    if (undoStrokes) {
      if (undoStrokes.action === "Add") {
        isStrokesUpdated = true;
        setStrokes(strokes.filter(stroke => !undoStrokes.strokes.includes(stroke)));
      } else if (undoStrokes.action === "Remove") {
        isStrokesUpdated = true;
        setStrokes([...strokes, ...undoStrokes.strokes]);
      }
      setRedrawFlag(!redrawFlag);

    }
  }, [undoFlag]);

  useEffect(() => {
    var removeStrokes = [];
    strokes.slice(0, -1).forEach(stroke => {
      // debugger;
      if (stroke !== null) {
        if (isAfterStrikeThroughCooldown(currentStroke, stroke) && isOverIntersectingThreshold(currentStroke, stroke) && isStraightLine(currentStroke) && stroke.canId === canvasCount) {
          removeStrokes.push(stroke);
        }
        else if (isAfterStrikeThroughCooldown(currentStroke, stroke) && isOverIntersectingThreshold(currentStroke, stroke) && isSribble(currentStroke) && stroke.canId === canvasCount) {
          removeStrokes.push(stroke);
        }
      }
    });
    if (removeStrokes.length > 0) {
      removeStrokes.push(currentStroke);
      isStrokesUpdated = true;
      setStrokes(strokes.filter(stroke => !removeStrokes.includes(stroke)));
      setUndoHistory([...undoHistory, { action: "Remove", strokes: removeStrokes.slice(0, -1) }]);
      setRedoHistory([]);
      hasResizeEvent.current = false
      setRedrawFlag(!redrawFlag);
    }
    else {
      if (currentStroke !== null) {
        setUndoHistory([...undoHistory, { action: "Add", strokes: [currentStroke] }]);
      }
      setRedoHistory([]);
    }
    setRenderLatexFlag(!renderLatexFlag);
  }, [checkStrikeThroughFlag]);

  const isAfterStrikeThroughCooldown = (newStroke, oldStroke) => {
    if (!isNil(newStroke.timestamp) && !isNil(oldStroke.timestamp)) {
      return (newStroke.timestamp - oldStroke.timestamp) > 2000;
    }
    return false;

  };

  const isOverIntersectingThreshold = (newStroke, oldStroke) => {
    return intersectionOverOld(newStroke, oldStroke) > 0.85 || IOU(newStroke, oldStroke) > 0.3;
  };

  const isStraightLine = (newStroke) => {
    return getDistanceRatio(newStroke) > 0.90;
  };

  const isSribble = (newStroke) => {
    return getDistanceRatio(newStroke) * 0.6 < 0.3;
  };

  const getDistanceRatio = (newStroke) => {
    var totalLength = 0;
    newStroke.points.forEach((point, index) => {
      if (index > 0) {
        const previousPoint = newStroke.points[index - 1];
        totalLength += Math.sqrt(Math.pow(point.x - previousPoint.x, 2) + Math.pow(point.y - previousPoint.y, 2));
      }
    });
    const endpointLength = Math.sqrt(Math.pow(newStroke.points[0].x - newStroke.points[newStroke.points.length - 1].x, 2) + Math.pow(newStroke.points[0].y - newStroke.points[newStroke.points.length - 1].y, 2));
    return endpointLength / totalLength
  }

  const IOU = (newStroke, oldStroke) => {
    const xA = Math.max(newStroke.minX, oldStroke.minX);
    const yA = Math.max(newStroke.minY, oldStroke.minY);
    const xB = Math.min(newStroke.maxX, oldStroke.maxX);
    const yB = Math.min(newStroke.maxY, oldStroke.maxY);

    const intersectionArea = Math.max(0, xB - xA + 1) * Math.max(0, yB - yA + 1);

    const box1area = (newStroke.maxX - newStroke.minX + 1) * (newStroke.maxY - newStroke.minY + 1);
    const box2area = (oldStroke.maxX - oldStroke.minX + 1) * (oldStroke.maxY - oldStroke.minY + 1);

    const iou = intersectionArea / (box1area + box2area - intersectionArea);

    return iou;
  }

  const intersectionOverOld = (newStroke, oldStroke) => {
    const xA = Math.max(newStroke.minX, oldStroke.minX);
    const yA = Math.max(newStroke.minY, oldStroke.minY);
    const xB = Math.min(newStroke.maxX, oldStroke.maxX);
    const yB = Math.min(newStroke.maxY, oldStroke.maxY);

    const intersectionArea = Math.max(0, xB - xA + 1) * Math.max(0, yB - yA + 1);


    const box2area = (oldStroke.maxX - oldStroke.minX + 1) * (oldStroke.maxY - oldStroke.minY + 1);

    const ioo = intersectionArea / (box2area);

    return ioo;
  }


  const undo = () => {
    if (undoHistory.length === 0) {
      return;
    }
    const undoStrokes = undoHistory[undoHistory.length - 1];
    setUndoHistory(undoHistory.slice(0, -1));
    setRedoHistory([...redoHistory, undoStrokes]);
    setUndoFlag(!undoFlag);
  };


  const redo = () => {
    if (redoHistory.length === 0) {
      return;
    }
    const redoStrokes = redoHistory[redoHistory.length - 1];
    setUndoHistory([...undoHistory, redoStrokes]);
    setRedoHistory(redoHistory.slice(0, -1));
    setRedoFlag(!redoFlag);
  };

  const drawSavedWork = (strks) => {
    if (!hasSavedWork.current) {
      hasClearCurrentCanvas = true;
      hasSavedWork.current = true
      setStrokes([...strks]);

    }
  }
  const redraw = (editFix = false, redrawFromWiris = false) => {
    if (!hasImageOnBoard) {
      clearCanvas();
    }
    if (redrawFromWiris) {
      isStrokesUpdated = true;
      setStrokes([...strokes]);
    }
    strokes.forEach(stroke => {
      if (stroke !== null) {
        if (!editFix && stroke.canId !== canvasCount) {
          contextRef.current.strokeStyle = '#cccccc';
        } else if (editFix && stroke.canId !== canvasCount) {
          contextRef.current.strokeStyle = '#cccccc';
        } else {
          contextRef.current.strokeStyle = 'black';
        }

        stroke.points.forEach((point, index) => {
          if (index === 0) {
            contextRef.current.beginPath();
            contextRef.current.moveTo(point.x, point.y);
          } else {
            contextRef.current.lineTo(point.x, point.y);
          }
        });
        contextRef.current.stroke();
        contextRef.current.closePath();
      }
    });
  };

  const clearCurrentDrawing = () => {
    hasClearCurrentCanvas = true;
    isStrokesUpdated = true;
    setStrokes([]);
    //setStrokes([...strokes.filter((stroke) => stroke.canId !== canvasCount)]);
  }

  useEffect(() => {
    //  debugger;
    if (hasClearCurrentCanvas || (strokes.length === 0 && isStrokesUpdated)) {
      hasClearCurrentCanvas = false;
      hasResizeEvent.current = hasSavedWork.current ? true : false
      redraw();
      if (strokes.length > 0) {
        setTimeout(() => { setRenderLatexFlag(!renderLatexFlag) }, 500)
      } else {
        setApiResponse({ mathml: {} })
      }

    }
  }, [strokes]);

  const readFromImage = (image) => {
    setImageToggle(image)
    hasImageOnBoard = true;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    var img = new Image();
    img.onload = function () {
      context.drawImage(img, 20, 20, 500, 500);
    };
    img.src = image

    getLatexFromImage(mathpixContext, image)
      .then(async response => {
        if (!response.data.error) {
          setFromImage(true)
          setApiResponse({ mathml: response });
          setTimeout(() => {
            setFromImage(false)
          }, 500)

          if (response.data.latex_styled) {
            setLatex(
              {
                code: `\\[${response.data.latex_styled}\\]`,
                isPlaceholder: false,
              }
            )
          }
          else {
            setLatex(
              {
                code: response.data.text,
                isPlaceholder: false,
              }
            )
          }
        }
      }).catch(err => {
        console.log("API Error: ", err);
      })
  }

  const updateCanvasCount = (count) => {
    canvasCount = count;
  }
  const clearCanvas = (redraw = true, hasNewPage = false, fromServer = false) => {
    hasImageOnBoard = false;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    context.fillStyle = "white"
    context.fillRect(0, 0, canvas.width, canvas.height)
    context.fillStyle = pattern;
    context.fillRect(0, 0, canvas.width, canvas.height);
    if (!redraw) {
      setStrokes([]);
    }

  }


  useEffect(() => {
    if (mathpixContext !== null) {
      if (strokes.length > 0) {
        getLatexTimedOut(1500);
      } else {
        clearTimeout(renderLatexTimeout);
        setLatex(
          {
            code: '\\[\\text{ Draw your math below }\\]',
            isPlaceholder: true,
          }
        )
      }
    }
    else if (!isActive) {
      setLatex(
        {
          code: '\\[\\text{ Session expired due to inactivity. Draw to start new session. }\\]',
          isPlaceholder: true,
        }
      )
    }
    else {
      setLatex(
        {
          code: '\\[\\text{ Connecting to Mathpix... }\\]',
          isPlaceholder: true,
        }
      )
    }
  }, [renderLatexFlag]);

  const getLatexTimedOut = (time, strks = strokes) => {
    clearTimeout(renderLatexTimeout);
    if (!hasResizeEvent.current) {
      setRenderLatexTimeout(
        setTimeout(async () => {
          if (equationMessageCounter <= 2) {
            toast('Please wait while we update the equation board with your latest changes...!', {
              type: toast.TYPE.INFO,
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: true
            })
          }


          setEquationMessageCounter(equationMessageCounter + 1);

          const response = await getLatex(mathpixContext, strks.filter((stroke) => stroke !== null && stroke.canId === canvasCount));
          if (!response.data.error) {
            setFromImage(false)
            setApiResponse({ mathml: response })
            if (response.data.latex_styled) {
              setLatex(
                {
                  code: `\\[${response.data.latex_styled}\\]`,
                  isPlaceholder: false,
                }
              )
            }
            else {
              setLatex(
                {
                  code: response.data.text,
                  isPlaceholder: false,
                }
              )
            }
          } else {
            setApiResponse({ mathml: [] })
            console.log("API Error: ", response.data.error);
          }
        }, time)
      )
    }

  }

  return (
    <>
      <ToastContainer />
      <CanvasContext.Provider
        value={{
          canvasRef,
          contextRef,
          strokes,
          undoHistory,
          redoHistory,
          mathpixContext,
          latex,
          apiResponse,
          drawInitialGraphicsFromServer,
          prepareCanvas,
          startDrawing,
          finishDrawing,
          leaveCanvas,
          clearCanvas,
          updateCanvasCount,
          clearCanvasMsg,
          isNewPage,
          draw,
          undo,
          redo,
          readFromImage,
          hasImageOnBoard,
          setMenuItem,
          redraw,
          clearCurrentDrawing,
          isStrokesUpdated,
          windowResize,
          eventType,
          fromImage,
          isDrawing,
          drawSavedWork
        }}
      >
        {children}
      </CanvasContext.Provider>

    </>

  );
};

export const useCanvas = () => useContext(CanvasContext);