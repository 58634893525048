import React from 'react';
import "./TextComponent.scss";

class TextComponent extends React.Component {

  getData;
  constructor(props) {
    super(props);
    this.state = { textValue: '' }
    this.handleChange = this.handleChange.bind(this);
    this.removeText = this.removeText.bind(this);
    this.onFocusOut = this.onFocusOut.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render

    if (nextProps.value !== this.state.textValue) {
      debugger;
      this.setState({ textValue: nextProps.value });
    }

  }
  handleChange(event) {
    if (this.getData) {
      clearTimeout(this.getData);
    }
    let text = event.target.value;
    this.setState({ textValue: text })

    this.getData = setTimeout(() => {
      this.props.textChangeHandler(text, this.props.id);
      // this.props.handletextchangedTextProblem(text)
    }, 2000)
  }

  removeText = (e) => {
    if (e.keyCode === 27) {
      this.props.removeText(this.props.id)
    }

  }
  onFocusOut = (e) => {
    //this.props.onFocusOut(e,this.props.id)
  }
  render() {
    return <textarea className='textarea'
      onChange={this.handleChange}
      onKeyDown={this.removeText}
      onBlur={this.onFocusOut}
      value={this.state.textValue}
      placeholder='Enter answer...'></textarea>

  }
}

export default TextComponent